export const Client = {
    Root: "/",
};

export const Server = {
    Api: {
        MapData: {
            GetAllFireStations: "./api/mapdata/getallfirestations",

            GetEvents: "./api/mapdata/getevents",
            GetAttendedIncidents: "./api/mapdata/getattendedincidents",
            GetAverageResponseTime: "./api/mapdata/getaverageresponsetime",
            GetFiresSummaryTime: "./api/mapdata/getfiressummary",
            GetInspectionsAndChecks: "./api/mapdata/getinspectionsandchecks",
            GetRoadTrafficCollisions: "./api/mapdata/getroadtrafficcollisions",
        },
    },
};
