export enum IncidentGroupClassification {
    FalseAlarm = "FALSE_ALARM",
    Fire = "FIRE",
    RoadTrafficCollision = "RTC",
    SpecialServiceCall = "SSC",
}

export enum IncidentClassification {
    FalseAlarmEquipment = "FALSE_ALARM_EQUIPMENT",
    FalseAlarmGoodIntent = "FALSE_ALARM_GOOD_INTENT",
    FalseAlarmMalicious = "FALSE_ALARM_MALICIOUS",

    FireChimney = "FIRE_CHIMNEY",
    FireDerelictBuildingAccidental = "FIRE_DERELICT_BUILDING_ACCIDENTAL",
    FireDerelictBuildingArson = "FIRE_DERELICT_BUILDING_ARSON",
    FireDerelictVehicleAccidental = "FIRE_DERELICT_VEHICLE_ACCIDENTAL",
    FireDerelictVehicleArson = "FIRE_DERELICT_VEHICLE_ARSON",
    FireDwellingAccidental = "FIRE_DWELLING_ACCIDENTAL",
    FireDwellingArson = "FIRE_DWELLING_ARSON",
    FireNonDomesticAccidental = "FIRE_NON_DOMESTIC_ACCIDENTAL",
    FireNonDomesticArson = "FIRE_NON_DOMESTIC_ARSON",
    FireOtherAccidental = "FIRE_OTHER_ACCIDENTAL",
    FireOtherArson = "FIRE_OTHER_ARSON",
    FireRubbishAccidental = "FIRE_RUBBISH_ACCIDENTAL",
    FireRubbishArson = "FIRE_RUBBISH_ARSON",
    FireSec = "FIRE_SEC",
    FireSecondaryAccidental = "FIRE_SEC_ACCIDENTAL",
    FireSecondaryArson = "FIRE_SEC_ARSON",
    FireVehicle = "FIRE_VEHICLE",
    FireVehicleAccidental = "FIRE_VEHICLE_ACCIDENTAL",
    FireVehicleArson = "FIRE_VEHICLE_ARSON",

    RoadTrafficCollision = "RTC",

    SpecialServiceCallAdvice = "SSC_ADVICE",
    SpecialServiceCallAnimal = "SSC_ANIMAL",
    SpecialServiceCallAssist = "SSC_ASSIST",
    SpecialServiceCallEvacuation = "SSC_EVACUATION",
    SpecialServiceCallFirstAid = "SSC_FIRST_AID",
    SpecialServiceCallGasLeak = "SSC_GAS_LEAK",
    SpecialServiceCallHazardousMaterials = "SSC_HAZMAT",
    SpecialServiceCallLift = "SSC_LIFT",
    SpecialServiceCallLock = "SSC_LOCK",
    SpecialServiceCallMakeSafe = "SSC_MAKE_SAFE",
    SpecialServiceCallNotRequired = "SSC_NOT_REQUIRED",
    SpecialServiceCallOtherRescue = "SSC_OTHER_RESCUE",
    SpecialServiceCallOtherStandby = "SSC_OTHER_STANDBY",
    SpecialServiceCallRemovedFromPerson = "SSC_REMOVED_FROM_PERSON",
    SpecialServiceCallSpillage = "SSC_SPILLAGE",
    SpecialServiceCallSuicide = "SSC_SUICIDE",
    SpecialServiceCallTransport = "SSC_TRANSPORT",
    SpecialServiceCallWater = "SSC_WATER",

    Unknown = "UNKKNOWN",
}

export enum VehicleClassification {
    BobCat = "BOBCAT",
    BrigadeResponeVehicle = "BRV",
    BrigadeSupportVehicle = "BSV",
    FireEngine = "PRL",
    HazMatEnvProtectionUnit = "HMEPU",
    HydraulicPlatform = "HP",
    HighVolumePumpingUnit = "HVPU",
    IncidentCommandUnit = "ICU",
    IncidentResponseUnit = "IRU",
    IncidentSupportUnit = "ISU",
    PrimeMover = "PM",
}
