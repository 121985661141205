import { makeStyles } from "@material-ui/core";

// Select Controls
export const WMFSSelectStyle = makeStyles((theme) => ({
    root: {},
    popper: {
        zIndex: 20000,
    },
}));

// WMFS Autocomplete Select
export const WMFSAutocompleteSelectStyle = makeStyles((theme) => ({
    paper: {
        "& > .MuiAutocomplete-noOptions": {
            color: theme.palette.primary.contrastText,
            fontSize: "0.75rem",
            padding: "0.875rem 0.75rem",
        },
    },
    option: {
        padding: "0.375rem 0.75rem",
        "& > div": {
            minHeight: "1.875rem",
            "& > div:first-child": {
                marginRight: "0.75rem",
            },
            "& > div:last-child > p": {
                color: theme.palette.primary.contrastText,
                fontSize: "0.75rem",
            },
        },
    },
    popper: {
        zIndex: 20000,
    },
}));

// WMFS Edit Address
export const WMFSEditAddressStyle = makeStyles((theme) => ({
    root: {
        margin: "0 !important",
        "& > div": {
            backgroundColor: "white",
            margin: "0 !important",
            padding: "0 3.5rem 0 0.5rem !important",
            "& > input": {
                backgroundColor: "transparent !important",
                fontSize: "0.75rem",
                padding: "0.25rem !important",
            },
            "& > div": {
                top: "calc(50% - 0.875rem)",
                "& > button": {},
            },
        },
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginBottom: "1.25rem",
        },
    },
}));

// WMFS Autocomplete Address
export const WMFSAutocompleteAddressStyle = makeStyles((theme) => ({
    paper: {
        "& > .MuiAutocomplete-noOptions": {
            color: theme.palette.primary.contrastText,
            fontSize: "0.75rem",
            padding: "0.875rem 0.75rem",
        },
    },
    option: {
        padding: "0.375rem 0.75rem",
        "& > div": {
            minHeight: "1.875rem",
            "& > div:first-child": {
                marginRight: "0.75rem",
            },
            "& > div:last-child > p": {
                color: theme.palette.primary.contrastText,
                fontSize: "0.75rem",
            },
        },
    },
    popper: {
        zIndex: 20000,
    },
}));
