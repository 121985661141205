import i18next from "i18next";
import moment from "moment";

/**
 * Test if an object is null or undefined.
 *
 * @param {any} input
 *
 * @returns true if null or undefined, otherwise false.
 */
export const isNullOrUndefined = (input: any): boolean => {
    return input === undefined || input === null;
};

/**
 * Formats a time in seconds into a string.
 *
 * @param input
 *
 * @returns A formatted string.
 */
export const formatVerboseSeconds = (input: number): string => {
    const hoursString = i18next.t("map.common.time.hours");
    const minutesString = i18next.t("map.common.time.minutes");
    const secondsString = i18next.t("map.common.time.seconds");

    const hours = Math.floor(input / 3600);
    const totalSeconds = input % 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    switch (true) {
        case hours > 0:
            return `${hours} ${hoursString} ${minutes} ${minutesString} ${seconds} ${secondsString}`;

        case minutes > 0:
            return `${minutes} ${minutesString} ${seconds} ${secondsString}`;

        default:
            return `${seconds} ${secondsString}`;
    }
};

/**
 * Formats a date to "DD/MM/YYYY" or an empty string.
 *
 * @param input
 *
 * @returns A formatted string.
 */
export const formatDate = (input: Date | null): string => {
    return input ? moment(input).format("DD/MM/YYYY") : "";
};

/**
 * Formats a date to "DD MMM YY" or an empty string.
 *
 * @param input
 *
 * @returns A formatted string.
 */
export const formatDateV2 = (input: Date | null): string => {
    return input ? moment(input).format("DD MMM YY") : "";
};

/**
 * Formats a date to "h:mma" or an empty string.
 *
 * @param input
 *
 * @returns A formatted string.
 */
export const formatTime = (input: Date | null): string => {
    return input ? moment(input).format("h.mma") : "";
};

/**
 * Formats a number to include an oridinal.
 *
 * @param input
 *
 * @returns A formatted string.
 */
export const formatOrdinal = (input: number): string => {
    const ordinal = ["st", "nd", "rd"][(((((input < 0 ? -input : input) + 90) % 100) - 10) % 10) - 1] || "th";

    return `${input}${ordinal}`;
};
