import L, { LatLngExpression, Map as LeafletMap } from "leaflet";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { MapContainer, ScaleControl, TileLayer } from "react-leaflet";

// Custom
import { isNullOrUndefined } from "Custom/Utils/Utils";
import { MapViewModel } from "Custom/ViewModels/Map/MapViewModel";
import { RangeCircleControl } from "../CustomControls/RangleCircleControl";
import { WMListedBuildingsVectorGrid } from "../Layers/WMListedBuildingsVectorGrid";
import { WMDemographicsVectorGrid } from "../Layers/WMDemographicsVectorGrid";
import { WMFireStationMarkers } from "../Layers/WMFireStationMarkers";
import { WMAttendedIncidentMarkers } from "../Layers/WMAttendedIncidentMarkers";
import { WMDemographicsLegend } from "../Legend/WMDemographicsLegend";
import { WMFloodRiskTileLayer } from "../Layers/WMFloodRiskTileLayer";
import { WMFireServiceRiskVectorGrid } from "../Layers/WMFireServiceRiskVectorGrid";
import { WMFireServiceRiskLegend } from "../Legend/WMFireServiceRiskLegend";
import { WMFloodRiskLegend } from "../Legend/WMFloodRiskLegend";

interface IProps {
    viewModel: MapViewModel;
}

export const OpenStreetMap: React.FC<IProps> = (props) => {
    const mapBounds = L.latLngBounds(
        L.latLng(props.viewModel.mapBoundsSouthWest.latitude, props.viewModel.mapBoundsSouthWest.longitude),
        L.latLng(props.viewModel.mapBoundsNorthEast.latitude, props.viewModel.mapBoundsNorthEast.longitude),
    );
    const mapPanningBounds = L.latLngBounds(
        L.latLng(props.viewModel.mapBoundsSouthWest.latitude - 0.17, props.viewModel.mapBoundsSouthWest.longitude - 0.54),
        L.latLng(props.viewModel.mapBoundsNorthEast.latitude + 0.17, props.viewModel.mapBoundsNorthEast.longitude + 0.54),
    );

    const [map, setMap] = useState<LeafletMap>();

    const hasCenterChanged = (lat1: number, lng1: number, lat2: number, lng2: number) => {
        const tolerance = Number.EPSILON; // 0.000001;

        return Math.abs(lat1 - lat2) > tolerance || Math.abs(lng1 - lng2) > tolerance;
    };

    /**
     * Callback notifies the caller if the maps center changes.
     */
    const onLocationChange = useCallback(() => {
        if (!isNullOrUndefined(map)) {
            const center = map!.getCenter();
            const centerChanged = hasCenterChanged(props.viewModel.mapLatitude, props.viewModel.mapLongitude, center.lat, center.lng);

            if (centerChanged) {
                props.viewModel.setMapCenter(center.lat, center.lng);
            }
        }
    }, [map]);

    /**
     * Callback notifies the caller if the maps zoom changes.
     */
    const onZoomChange = useCallback(() => {
        if (!isNullOrUndefined(map)) {
            const zoom = map!.getZoom();

            if (props.viewModel.mapZoom !== zoom) {
                props.viewModel.setMapZoom(zoom);
            }
        }
    }, [map]);

    /**
     * Effect to listen for a change to the maps center.
     */
    useEffect(() => {
        if (!isNullOrUndefined(map)) {
            map!.on("moveend", onLocationChange);

            return () => {
                map!.off("moveend", onLocationChange);
            };
        }
    }, [map, onLocationChange]);

    /**
     * Effect to listen for a change to the maps zoom.
     */
    useEffect(() => {
        if (!isNullOrUndefined(map)) {
            map!.on("zoomend", onZoomChange);

            return () => {
                map!.off("zoomend", onZoomChange);
            };
        }
    }, [map, onZoomChange]);

    /**
     * Effect changes the maps center and zoom if the props change.
     */
    useEffect(() => {
        if (!isNullOrUndefined(map)) {
            const currentCenter = map!.getCenter();
            const currentZoom = map!.getZoom();

            const centerChanged = hasCenterChanged(props.viewModel.mapLatitude, props.viewModel.mapLongitude, currentCenter.lat, currentCenter.lng);
            const zoomChanged = props.viewModel.mapZoom !== currentZoom;

            if (centerChanged || zoomChanged) {
                const center: LatLngExpression = centerChanged
                    ? {
                          lat: props.viewModel.mapLatitude,
                          lng: props.viewModel.mapLongitude,
                      }
                    : currentCenter;

                map!.off("moveend", onLocationChange);
                map!.off("zoomend", onZoomChange);
                map!.setView(center, props.viewModel.mapZoom);
                setTimeout(() => {
                    map!.on("moveend", onLocationChange);
                    map!.on("zoomend", onZoomChange);
                }, 750);
            }
        }
    }, [map, props.viewModel.mapLatitude, props.viewModel.mapLongitude, props.viewModel.mapZoom]);

    return useObserver(() => (
        <MapContainer
            attributionControl={true}
            center={[props.viewModel.mapLatitude, props.viewModel.mapLongitude]}
            dragging={props.viewModel.canMapPan}
            maxBounds={mapPanningBounds}
            minZoom={props.viewModel.mapMinZoom}
            maxZoom={props.viewModel.mapMaxZoom}
            doubleClickZoom={props.viewModel.canMapZoom}
            scrollWheelZoom={props.viewModel.canMapZoom}
            touchZoom={props.viewModel.canMapZoom}
            zoom={props.viewModel.mapZoom}
            zoomAnimation={false}
            zoomControl={false}
            whenCreated={setMap}>
            {/* **** Controls **** */}
            <ScaleControl
                aria-hidden="true"
                maxWidth={250}
                position="bottomright"
                updateWhenIdle={true}
            />
            <RangeCircleControl
                center={[props.viewModel.dataViewModel.searchLatitude, props.viewModel.dataViewModel.searchLongitude]}
                snappedDistanceMetres={props.viewModel.mapRangeMetres}
            />
            {/* **** Layers **** */}
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <WMFireStationMarkers
                fireStations={props.viewModel.fireStationsViewModel.fireStations}
                attendedIncidentCountByFireStation={props.viewModel.attendedIncidentCountByFireStation}
                safeAndWellActivityByFireStation={props.viewModel.safeAndWellActivityByFireStation}
            />
            {props.viewModel.canDisplayIncidents &&
                <WMAttendedIncidentMarkers
                    attendedIncidents={props.viewModel.attendedIncidents}
                    zoom={props.viewModel.mapZoom}/>
            }
            {props.viewModel.canDisplayDemographics && (
                <React.Fragment>
                    <WMDemographicsVectorGrid
                        mapBounds={mapBounds}
                        navigationAreaViewModel={props.viewModel.selectedDemographicViewModel}
                        navigationAreaViewModels={props.viewModel.demographicViewModels} />
                    <WMDemographicsLegend
                        navigationAreaViewModel={props.viewModel.selectedDemographicViewModel} />
                </React.Fragment>
            )}
            {props.viewModel.canDisplayWMSFRisks && (
                <React.Fragment>
                    <WMFireServiceRiskVectorGrid
                        mapBounds={mapBounds}
                        navigationAreaViewModel={props.viewModel.selectedWMFSRisksViewModel} />
                    <WMFireServiceRiskLegend
                        navigationAreaViewModel={props.viewModel.selectedWMFSRisksViewModel} />
                </React.Fragment>
            )}
            {props.viewModel.canDisplayFloodRisk && (
                <React.Fragment>
                    <WMFloodRiskTileLayer />
                    <WMFloodRiskLegend
                        navigationAreaViewModel={props.viewModel.selectedWMFSRisksViewModel} />
                </React.Fragment>
            )}
            {props.viewModel.canDisplayListedBuildings &&
                <WMListedBuildingsVectorGrid
                    mapBounds={mapBounds} />
            }
        </MapContainer>
    ));
};
