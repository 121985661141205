// Libraries
import { Close } from "@material-ui/icons";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import L, { LatLngExpression } from "leaflet";
import React from "react";
import { useTranslation } from "react-i18next";
import { Marker, Popup } from "react-leaflet";

import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { FireStationPointModel } from "Custom/Models/Map/FireStations/FireStationPointModel.";
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { isNullOrUndefined } from "Custom/Utils/Utils";
import { FireStationMarkerControlStyles } from "./FireStationMarkerControlStyles";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";

interface IProps {
    model: FireStationPointModel;
    attendedIncidentsCount: number;
    safeAndWellActivityCount: number;
}

interface IInformationDialog {
    icon: string;
    title: string;
    tooltip: string;
}

export const FireStationMarkerControl: React.FC<IProps> = (props: IProps) => {
    // #region Code Behind

    const [openInformationDialog, setOpenInformationDialog] = React.useState<IInformationDialog | null>(null);
    const popupStyles = FireStationMarkerControlStyles();
    const { t } = useTranslation();

    const center: LatLngExpression = {
        lat: props.model.latitiude,
        lng: props.model.longitude,
    };

    const canDisplayTelephone = () => !isEmptyOrWhitespace(props.model.telephone);

    const canDisplayTwitter = () => false;

    const canDisplayFacebook = () => false;

    const canDisplayWebsite = () => !isEmptyOrWhitespace(props.model.website);

    const canDisplayIncidentsCount = () => !isNullOrUndefined(props.attendedIncidentsCount);

    const canDisplaySafeAndWellActivityCount = () => !isNullOrUndefined(props.safeAndWellActivityCount);

    const getFriendlyWebsite = () => (isEmptyOrWhitespace(props.model.website) ? "" : props.model.website.slice(props.model.website.indexOf("//") + 2));

    const icon = () => {
        const ariaLabel = `Fire station. ${props.model.name}.`;

        switch (true) {
            case props.model.isHQ:
                return L.divIcon({
                    className: popupStyles.hqMarker,
                    html: `<div aria-label="${ariaLabel}" style="background-image: url(resources/map/wmfs-crest.svg)"/>`,
                    iconSize: [45, 45],
                    iconAnchor: [23, 23],
                    popupAnchor: [-1, -21],
                });

            default:
                return L.divIcon({
                    className: popupStyles.marker,
                    html: `<div aria-label="${ariaLabel}" style="background-image: url(resources/map/firestation.svg)"/>`,
                    iconSize: [45, 45],
                    iconAnchor: [23, 23],
                    popupAnchor: [-1, -21],
                });
        }
    };

    const handleOpenInformationDialog = (icon: string, title: string, tooltip: string) => {
        setOpenInformationDialog({
            icon: icon,
            title: title,
            tooltip: tooltip,
        });
    };

    const handleCloseInformationDialog = () => {
        setOpenInformationDialog(null);
    };

    // #endregion Code Behind

    const renderIcon = (image: string) => {
        return (
            <div
                style={{
                    backgroundColor: `${customColours.wmfsDarkGrey}`,
                    maskImage: `url(${image})`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                    WebkitMaskImage: `url(${image})`,
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskSize: "contain",
                    height: "1.75rem",
                    width: "1.75rem",
                    marginRight: "0.5rem",
                }}
            />
        );
    };

    const renderPopup = () => {
        return (
            <Popup className={popupStyles.root}>
                <div className={popupStyles.header}>{props.model.name}</div>
                <div className={popupStyles.body}>
                    <div className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/home_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div>{props.model.address1}</div>
                            <div>{props.model.address2}</div>
                            <div>{props.model.address3}</div>
                            <div>{props.model.postcode}</div>
                        </div>
                    </div>
                    {canDisplayTelephone() && (
                        <div className={popupStyles.bodyItemContainer}>
                            <div>
                                <div>{renderIcon("resources/navigation/phone_white_24dp.svg")}</div>
                            </div>
                            <div>
                                <a
                                    href={`tel:${props.model.telephone}`}
                                    rel="noopener"
                                    target="_blank">
                                    {props.model.telephone}
                                </a>
                            </div>
                        </div>
                    )}
                    {canDisplayWebsite() && (
                        <div className={popupStyles.bodyItemContainer}>
                            <div>
                                <div>{renderIcon("resources/navigation/language_white_24dp.svg")}</div>
                            </div>
                            <div className={popupStyles.bodyItemWebsiteItems}>
                                <a
                                    href={props.model.website}
                                    target="_blank">
                                    {props.model.isHQ ? getFriendlyWebsite() : t("map.popup.fireStations.website")}
                                </a>
                                {canDisplayTwitter() && (
                                    <a
                                        href={props.model.twitter}
                                        target="_blank">
                                        <TwitterIcon />
                                    </a>
                                )}
                                {canDisplayFacebook() && (
                                    <a
                                        href={props.model.facebook}
                                        target="_blank">
                                        <FacebookIcon />
                                    </a>
                                )}
                            </div>
                        </div>
                    )}
                    {canDisplayIncidentsCount() && (
                        <div className={popupStyles.bodyItemContainer}>
                            <div>
                                <div>{renderIcon("resources/navigation/fire_truck_white_24dp.svg")}</div>
                            </div>
                            <div>
                                <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.fireStations.incidentsResponsedTo")}</div>
                                <div>{props.attendedIncidentsCount}</div>
                            </div>
                            <button
                                aria-haspopup="dialog"
                                aria-label="Display information."
                                className={popupStyles.infoIcon}
                                onClick={(e) => {
                                    handleOpenInformationDialog(
                                        "resources/navigation/fire_truck_white_24dp.svg",
                                        t("map.popup.fireStations.incidentsResponsedTo"),
                                        t("map.popup.fireStations.tooltipIncidentsResponsedTo"),
                                    );
                                    e.stopPropagation();
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                                tabIndex={0}>
                                {renderIcon("resources/navigation/info_white_24dp.svg")}
                            </button>
                        </div>
                    )}
                    {canDisplaySafeAndWellActivityCount() && (
                        <div className={popupStyles.bodyItemContainer}>
                            <div>
                                <div>{renderIcon("resources/navigation/family_restroom_white_24dp.svg")}</div>
                            </div>
                            <div>
                                <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.fireStations.safeAndWellVisits")}</div>
                                <div>{props.safeAndWellActivityCount}</div>
                            </div>
                            <button
                                aria-haspopup="dialog"
                                aria-label="Display information."
                                className={popupStyles.infoIcon}
                                onClick={(e) => {
                                    handleOpenInformationDialog(
                                        "resources/navigation/family_restroom_white_24dp.svg",
                                        t("map.popup.fireStations.safeAndWellVisits"),
                                        t("map.popup.fireStations.tooltipSafeAndWellVisits"),
                                    );
                                    e.stopPropagation();
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                                tabIndex={0}>
                                {renderIcon("resources/navigation/info_white_24dp.svg")}
                            </button>
                        </div>
                    )}
                </div>
            </Popup>
        );
    };

    const renderInformationDialog = () => {
        return (
            <Dialog
                aria-describedby="id_dialog_content"
                aria-labelledby="id_dialog_title"
                className={popupStyles.informationDialog}
                onClose={handleCloseInformationDialog}
                open={!isNullOrUndefined(openInformationDialog)}>
                {!isNullOrUndefined(openInformationDialog) && (
                    <React.Fragment>
                        <DialogTitle
                            className={popupStyles.informationDialogTitle}
                            disableTypography>
                            {renderIcon(openInformationDialog!.icon)}
                            <Typography
                                aria-hidden="true"
                                id="id_dialog_title"
                                variant="h6">
                                {openInformationDialog!.title}
                            </Typography>
                            <IconButton
                                aria-label="Close dialog."
                                onClick={handleCloseInformationDialog}
                                size="small">
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent
                            aria-hidden="true"
                            className={popupStyles.informationDialogContent}>
                            <Typography id="id_dialog_content">{openInformationDialog!.tooltip}</Typography>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        );
    };

    return (
        <React.Fragment>
            <Marker
                keyboard={false}
                position={center}
                icon={icon()}
                interactive={true}>
                {renderPopup()}
            </Marker>
            {renderInformationDialog()}
        </React.Fragment>
    );
    /* Keeping this temporarily as it makes it easier to see where an icon marker
       is being placed realtive to the lat/long.
    return (
        <React.Fragment>
            <Marker
                position={center}
                icon={icon}
                interactive={true}>
                {renderPopup()}
            </Marker>
            <CircleMarker
                center={center}
                color="white"
                fillColor="white"
                fillOpacity={1}
                interactive={true}
                opacity={1}
                radius={8}
                weight={2}>
                {renderPopup()}
            </CircleMarker>
        </React.Fragment>
    );
    */
};
