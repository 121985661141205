import * as MobX from "mobx";
import { runInAction } from "mobx";

import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

import { InitialState } from "Custom/Models";
import { StoresInstance } from "Custom/Stores";

export class InitViewModel extends ViewModelBase<any> {
    @MobX.observable
    public DataReady: boolean = false;
    public InitData: InitialState = {} as InitialState;

    constructor(history: any) {
        super({});
        ViewModelBase.history = history;
    }

    public getInitialState = async (): Promise<boolean> => {
        let apiResult = await this.Get<InitialState>("/api/initialstate/getinitialstatejson");

        runInAction(() => {
            StoresInstance.init(apiResult.payload, ViewModelBase.history);
            this.InitData = apiResult.payload;
            this.DataReady = true;
        });

        return apiResult.wasSuccessful;
    };

    public isFieldValid(fieldName: "emailAddress" | "password", value: any): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
