import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import "leaflet.vectorgrid";
import { useEffect } from "react";

interface IProps {}

export const WMFloodRiskTileLayer = (props: any) => {
    const { map } = useLeafletContext();
    const url = "http://eafloodalertsblob.blob.core.windows.net/nafra/tiles-jan-2014/{risklevel}/{quadkey}.png";

    /**
     * Extension to the Leaflet tile layer so we can use Bing quad keys.
     */
    const createLayer = (urlParam: string, riskLevelParam: string) => {
        const bingLayer = L.TileLayer.extend({
            getTileUrl: function (coordinates: any) {
                let newUrl = L.Util.template(urlParam, {
                    risklevel: riskLevelParam,
                    quadkey: this.toQuadKey(coordinates.x, coordinates.y, coordinates.z),
                });

                return newUrl;
            },

            toQuadKey: function (x: number, y: number, z: number) {
                let index = "";

                for (var i = z; i > 0; i--) {
                    let b = 0;
                    let mask = 1 << (i - 1);

                    if ((x & mask) !== 0) b++;
                    if ((y & mask) !== 0) b += 2;

                    index += b.toString();
                }

                return index;
            },
        });

        return new bingLayer();
    };

    /**
     * Effect to listen for a change to the layer. It will replace the layer on the map.
     */
    useEffect(() => {
        try {
            const lowRisktileLayer = createLayer(url, "low");
            const mediumRisktileLayer = createLayer(url, "medium");
            const highRisktileLayer = createLayer(url, "high");

            lowRisktileLayer.setZIndex(100000);
            mediumRisktileLayer.setZIndex(100001);
            highRisktileLayer.setZIndex(100002);

            map.addLayer(lowRisktileLayer);
            map.addLayer(mediumRisktileLayer);
            map.addLayer(highRisktileLayer);

            return () => {
                map.removeLayer(lowRisktileLayer);
                map.removeLayer(mediumRisktileLayer);
                map.removeLayer(highRisktileLayer);
            };
        } catch {}
    }, [map]);

    return null;
};
