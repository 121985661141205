import { observable, action, runInAction, computed } from "mobx";
import { createViewModel } from "mobx-utils";
import { ApiResult, ModelBase } from "../Models";
import axios, * as Axios from "axios";
import { CheckHttpStatus, MergeDefaultConfig } from "../Utils/Axios";
import { IModel } from "Core/Models/IModel";
import { IViewModel } from "Core/ViewModels/IViewModel";
import { LocationState, History } from "history";
import { getFromContainer, MetadataStorage, validate, validateOrReject, Validator } from "class-validator";
import { ValidationMetadata } from "class-validator/metadata/ValidationMetadata";
import _ from "lodash";
import dot from "dot-object";
import { createProxy, ObjPathProxy } from "ts-object-path";
import { FieldType, getParentObjectPath } from "../Utils/Utils";
import { StoresInstance } from "../../Custom/Stores";

//Give typing and intellisense to the field names

export type ValidationResponse = {
	isValid: boolean;
	errorMessage: string;
};
export type Create<T> = new (...args: any[]) => T;
export abstract class ViewModelBase<T extends IModel<T>> implements IViewModel<T> {
	public model: T = {} as T;

	@observable public IsLoading: boolean = false;
	@observable public IsErrored = false;
	@observable public Errors: string = "";
	@observable public Valid: boolean = false;

	@action protected setIsLoading = (state: boolean) => (this.IsLoading = state);
	@action protected setIsErrored = (state: boolean) => (this.IsErrored = state);
	@action protected setErrors = (state: string) => (this.Errors = state);

	public static history = {} as History<LocationState>;
	public location = {} as any;

	public validatorStorage: MetadataStorage = getFromContainer(MetadataStorage);
	//public meta2 = this.validatorStorage.getTargetValidationMetadatas(ModelBase, "");
	private meta = {} as ValidationMetadata[];
	private validator = new Validator();
	private proxy: T = {} as T;
	private domainStores = StoresInstance.domain;

	constructor(model: T, undoable: boolean = false) {
		let self: any = this;
		this.proxy = new Proxy(model, {
			get(target: any, value: any, receiver: any) {
				let val = Reflect.get(target, value, receiver); // (1)
				return typeof value == "function" ? val.bind(target) : val;
			},
			set(target: any, prop: any, value: any, receiver: any) {
				let newValue = value;
				if (typeof self["beforeUpdate"] === "function") {
					let tmpValue = self["beforeUpdate"](prop, value);
					if (tmpValue !== null && tmpValue !== undefined) {
						newValue = tmpValue;
					}
				}
				let retval = Reflect.set(target, prop, newValue, receiver); // (1)
				if (typeof self["afterUpdate"] === "function") {
					self["afterUpdate"](prop, newValue);
				}
				return retval;
			},
		});

		this.setModel(this.proxy, undoable);
		//this.setModel(model, undoable);
		(window as any).model = model;
	}

	private getType = <T>(TCtor: new (...args: any[]) => T) => {
		return typeof TCtor;
	};

	//This must be overriden in any class that extends this base class
	abstract isFieldValid(fieldName: keyof FieldType<T>, value: any): boolean;
	abstract beforeUpdate?(fieldName: keyof FieldType<T>, value: any): any;
	abstract afterUpdate?(fieldName: keyof FieldType<T>, value: any): void;

	@computed
	public get getModel(): T {
		return this.model;
	}

	public setModel(model: T, undoable: boolean = false) {
		if (undoable) {
			//This is a helper method to make the model undoable. You must call submit on the model to save changes
			this.model = createViewModel(model);
			return;
		}
		this.model = model;
	}

	public getProxy = (): ObjPathProxy<T, T> => {
		return createProxy<T>();
	};

	public saveModel(): void {
		(this.model as any).submit();
	}

	public resetModel(): void {
		(this.model as any).reset();
	}

	@action
	public setValue<TR>(fieldName: keyof FieldType<T>, value: TR) {
		this.model.setValue<TR>(fieldName, value);
	}

	public getValue<TR>(fieldName: keyof FieldType<T>): TR {
		let value = this.model.getValue<TR>(fieldName);
		if (value === null) {
			if (_.isString(value)) {
				(value as string) = "";
			} else if (_.isBoolean(value)) {
				(value as boolean) = false;
			}
			this.model.setValue(fieldName, value);
		}
		return value;
	}

	@action
	public setError(fieldName: keyof FieldType<T> | string, value: string) {
		this.model.setError(fieldName, value);
	}

	public getError(fieldName: keyof FieldType<T> | string) {
		return this.model.getError(fieldName);
	}

	@action
	public setValid(fieldName: keyof FieldType<T> | string, value: boolean): void {
		this.model.setValid(fieldName, value);
	}

	public getValid(fieldName: keyof FieldType<T> | string): boolean {
		return this.model.getValid(fieldName);
	}

	@action
	public setDirty(fieldName: keyof FieldType<T> | string, value: boolean): void {
		this.model.setTouched(fieldName, value);
	}

	public getDirty(fieldName: keyof FieldType<T> | string): boolean {
		return this.model.getDirty(fieldName);
	}

	@action
	public setTouched(fieldName: keyof FieldType<T> | string, value: boolean): void {
		this.model.setTouched(fieldName, value);
	}

	public getTouched(fieldName: keyof FieldType<T> | string): boolean {
		return this.model.getTouched(fieldName);
	}

	public isModelValid = () => {
		let valid = true;
		//EN: Flatten the object into dot notation so we can iterate over nested objects
		let target = dot.dot(this.model);
		for (let prop in target) {
			if (
				prop.indexOf("Errors.") < 0 &&
				prop.indexOf("Dirty.") < 0 &&
				prop.indexOf("Touched.") < 0 &&
				prop.indexOf("Valid.") < 0
			) {
				if (prop != "getParentObjectPath") {
					this["isFieldValid"](prop as any, _.get(this.model, prop));
				}
			}
		}

		// //Run through again checking properties of model
		for (let prop in target) {
			if (
				prop.indexOf("Errors.") < 0 &&
				prop.indexOf("Dirty.") < 0 &&
				prop.indexOf("Touched.") < 0 &&
				prop.indexOf("Valid.") < 0
			) {
				if (valid) {
					let path = getParentObjectPath(prop, "Valid");
					valid = _.get(this.model, path);
				}
			}
		}
		runInAction(() => {
			this.Valid = valid;
		});
		return valid;
	};

	private parseObjectProperties = (obj: any, parse: any) => {
		for (let k in obj) {
			if (typeof obj[k] === "object" && obj[k] !== null) {
				this.parseObjectProperties(obj[k], parse);
			} else if (obj.hasOwnProperty(k)) {
				parse(obj, k);
			}
		}
	};

	public setDecorators = (model: any) => {
		this.meta = this.validatorStorage.getTargetValidationMetadatas(model, "");
	};

	public validateDecorators = (fieldName: keyof FieldType<T>): ValidationResponse => {
		let target = this.meta.filter(a => a.propertyName === fieldName).reverse();
		let message = "";
		if (target && target.length > 0) {
			let validated = false;
			target.some((t: ValidationMetadata) => {
				validated = this.validator.validateValueByMetadata(this.getValue(fieldName), t!);
				message = t.message.toString();
				return !validated;
			});
			//let vp = this.validator.length("", 1, 10);
			//let ve = new ValidationExecutor(this.validator);
			//let promise = await validate(target!);
			return { isValid: validated, errorMessage: validated ? "" : message.toString() };
		} else {
			//No decorators found so presume no validation required
			return { isValid: true, errorMessage: "" };
		}
	};

	Get = <TPayload = ApiResult<undefined>>(
		url: string,
		//model?: any,
		config?: Axios.AxiosRequestConfig,
	): Promise<ApiResult<TPayload>> => {
		//const requestConfig = this.getConfig(config);
		this.setIsLoading(true);
		const postPromise = axios
			.get<ApiResult<TPayload>>(url, this.getConfig(config))
			.then(async response => {
				if (response.headers["token-expired"]) {
					let newTokenResult = await axios.post<ApiResult<any>>("/api/account/refresh", {
						accessToken: localStorage.getItem(".auth"),
					});
					this.domainStores.AccountStore.getLoginState(newTokenResult.data.payload);
					if (newTokenResult.data.payload.jwt === "") {
						//Go to session expired page
						window.location.href = "/sessionexpired";
						//return false;
					}
					//Make the original call again
					response = await axios.get<ApiResult<TPayload>>(url, this.getConfig(config));
				} else if (response.headers["unauthorized"]) {
					StoresInstance.domain.AccountStore.Logout();
				}
				CheckHttpStatus(response);
				this.setIsLoading(false);
				return response.data;
			})
			.catch(error => {
				this.setIsErrored(true);
				this.setIsLoading(false);
				this.setErrors(error);
				if (error.response.status === 401) {
					StoresInstance.domain.AccountStore.Logout();
				}
				return { wasSuccessful: false };
			});

		return postPromise as Promise<ApiResult<TPayload>>;
	};

	Post = <TPayload = ApiResult<undefined>>(
		url: string,
		model?: any,
		config?: Axios.AxiosRequestConfig,
	): Promise<ApiResult<TPayload>> => {
		const requestConfig = this.getConfig(config);
		this.setIsLoading(true);

		const postPromise = axios
			.post<ApiResult<TPayload>>(url, model, this.getConfig(config))
			.then(async response => {
				if (response.headers["token-expired"]) {
					let newTokenResult = await axios.post<ApiResult<any>>("/api/account/refresh", {
						accessToken: localStorage.getItem(".auth"),
					});
					this.domainStores.AccountStore.getLoginState(newTokenResult.data.payload);
					if (newTokenResult.data.payload.jwt === "") {
						//Go to session expired page
						window.location.href = "/sessionexpired";
						//return false;
					}
					response = await axios.post<ApiResult<TPayload>>(url, model, this.getConfig(config));
				} else if (response.headers["unauthorized"]) {
					StoresInstance.domain.AccountStore.Logout();
				}
				CheckHttpStatus(response);
				this.setIsLoading(false);

				return response.data;
			})
			.catch(error => {
				this.setIsErrored(true);
				this.setIsLoading(false);
				this.setErrors(error);
				if (error.response.status === 401) {
					StoresInstance.domain.AccountStore.Logout();
				}
				return { wasSuccessful: false };
			});

		return postPromise as Promise<ApiResult<TPayload>>;
	};

	getConfig = (config?: Axios.AxiosRequestConfig) => {
		const requestConfig = MergeDefaultConfig(config);
		//Sets the bearer on every header if available
		//Note: You might need to remove this bearer if calling 3rd party api's
		let jwt = this.domainStores.AccountStore.getJwt();
		if (jwt && jwt.length > 0) {
			requestConfig.headers = {
				Authorization: "Bearer " + jwt,
			};
		}
		return requestConfig;
	};
}
