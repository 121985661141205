import { computed } from "mobx";

import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";
import { formatVerboseSeconds } from "Custom/Utils/Utils";
import { DataModel } from "../../Models/Map/Data/DataModel";

export default class DataViewModel extends ViewModelBase<DataModel> {
    constructor() {
        super(new DataModel());

        this.setDecorators(DataModel);
    }

    // #region Properties

    @computed
    public get searchLatitude() {
        return this.model.latitude;
    }

    @computed
    public get searchLongitude() {
        return this.model.longitude;
    }

    @computed
    public get averageResponseTime() {
        return formatVerboseSeconds(this.model.averageResponseTime);
    }

    @computed
    public get attendendedIncidentsCount() {
        return this.model.attendedIncidents.length;
    }

    @computed
    public get falseAlarmIncidentGroup() {
        return this.model.attendedIncidents.filter((incident) => incident.isFalseAlarmIncidentGroup).length;
    }

    @computed
    public get fireIncidentGroupCount() {
        return this.model.attendedIncidents.filter((incident) => incident.isFireIncidentGroup).length;
    }

    @computed
    public get houseFiresCount() {
        return this.model.attendedIncidents.filter((incident) => incident.isHouseFire).length;
    }

    @computed
    public get otherPropertyFiresCount() {
        return this.model.attendedIncidents.filter((incident) => incident.isOtherPropertyFire).length;
    }

    @computed
    public get vehicleFiresCount() {
        return this.model.attendedIncidents.filter((incident) => incident.isVehicleFire).length;
    }

    @computed
    public get outdoorFiresCount() {
        return this.model.attendedIncidents.filter((incident) => incident.isOutdoorFire).length;
    }

    @computed
    public get otherFiresCount() {
        return this.model.attendedIncidents.filter((incident) => incident.isOutlierFire).length;
    }

    @computed
    public get roadTrafficCollisionGroupCount() {
        return this.model.attendedIncidents.filter((incident) => incident.isRoadTrafficCollisionIncidentGroup).length;
    }

    @computed
    public get specialServiceCallIncidentGroupCount() {
        return this.model.attendedIncidents.filter((incident) => incident.isSpecialServiceCallIncidentGroup).length;
    }

    // #endregion Properties

    // #region Actions
    // #endregion Actions

    // #region Computeds
    // #region Computeds

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
