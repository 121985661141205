import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import "leaflet.vectorgrid";
import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";

// Custom
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { getIncidentTypesPaletteColour } from "Custom/Views/Map/Utils/Palettes";
import { NavigationAreaViewModel } from "Custom/ViewModels/Map/Navigation/NavigationViewModel";
import { PopupStyles } from "./WMFireServiceRiskVectorGridStyles";

interface IProps {
    mapBounds: any;
    navigationAreaViewModel?: NavigationAreaViewModel;
}

export const WMFireServiceRiskVectorGrid = (props: IProps) => {
    const popupStyles = PopupStyles();
    const { map } = useLeafletContext();
    const { t } = useTranslation();

    const mapStyles = { stroke: true, color: "#000000", opacity: 0.66, weight: 1, fill: true, fillOpacity: 0.6, fillColor: "#000000" };
    const url = "https://shoothillukwestgeneral.blob.core.windows.net/westmidsfs/tiles/wmfsrisks/alpha1/{z}/{x}/{y}.pbf";

    const options = {
        bounds: props.mapBounds,
        interactive: true,
        maxZoom: 18,
        minZoom: 8,
        rendererFactory: (L.svg as any).tile,
        getFeatureId: (f: any) => {
            return f.properties.id;
        },
    };

    const roundValue = (value: number) => Math.round((value + Number.EPSILON) * 10) / 10;

    const renderIcon = (image: string) => {
        return (
            <div
                style={{
                    backgroundColor: `${customColours.wmfsDarkGrey}`,
                    maskImage: `url(${image})`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                    WebkitMaskImage: `url(${image})`,
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskSize: "contain",
                    height: "1.75rem",
                    width: "1.75rem",
                    marginRight: "0.5rem",
                }}
            />
        );
    };

    const renderPopup = (event: any) => {
        const risks = new Map<number, string>([
            [-1, t("map.popup.riskTypes.noData")],
            [1, t("map.popup.riskTypes.veryLowRisk")],
            [2, t("map.popup.riskTypes.lowRisk")],
            [3, t("map.popup.riskTypes.moderateRisk")],
            [4, t("map.popup.riskTypes.highRisk")],
            [5, t("map.popup.riskTypes.veryHighRisk")],
        ]);

        return (
            <div 
                className={popupStyles.root}>
                <div
                    className={popupStyles.header}>
                    <div
                        className={popupStyles.headerImage}
                        style={{ backgroundImage: `url("resources/navigation/query_stats_white_24dp.svg")` }}
                    />
                    {event.layer.properties.LSOA11NM}
                </div>
                <div
                    className={popupStyles.body}>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/home_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.risks.houseFires")}</div>
                            <div>{risks.get(event.layer.properties.df)}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/factory_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.risks.otherPropertyFires")}</div>
                            <div>{risks.get(event.layer.properties.nrf)}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/park_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.risks.outdoorFires")}</div>
                            <div>{risks.get(event.layer.properties.of)}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/directions_car_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.risks.vehicleFires")}</div>
                            <div>{risks.get(event.layer.properties.vf)}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/car_crash_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.risks.roadTrafficCollisions")}</div>
                            <div>{risks.get(event.layer.properties.rtc)}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/fire_truck_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.risks.specialservicedomestic")}</div>
                            <div>{risks.get(event.layer.properties.ssd)}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const vectorGrid = (L as any).vectorGrid.protobuf(url, options);

    /**
     * Effect to listen for a change to the layer. It will update the layer style.
     */
    useEffect(() => {
        vectorGrid.options.vectorTileLayerStyles.WMFSRisks = (properties: any) => {
            const styles = { ...mapStyles };
            const vm = props.navigationAreaViewModel;

            switch (vm?.model.id) {
                case "id_housefires":
                    styles.fillColor = getIncidentTypesPaletteColour(properties.df, vm!.minViewModel.model.count, vm!.maxViewModel.model.count);
                    break;

                case "id_otherpropertyfires":
                    styles.fillColor = getIncidentTypesPaletteColour(properties.nrf, vm!.minViewModel.model.count, vm!.maxViewModel.model.count);
                    break;

                case "id_outdoorfires":
                    styles.fillColor = getIncidentTypesPaletteColour(properties.of, vm!.minViewModel.model.count, vm!.maxViewModel.model.count);
                    break;

                case "id_vehiclefires":
                    styles.fillColor = getIncidentTypesPaletteColour(properties.vf, vm!.minViewModel.model.count, vm!.maxViewModel.model.count);
                    break;

                case "id_roadtrafficcollisions":
                    styles.fillColor = getIncidentTypesPaletteColour(properties.rtc, vm!.minViewModel.model.count, vm!.maxViewModel.model.count);
                    break;

                case "id_specialservicedomestic":
                    styles.fillColor = getIncidentTypesPaletteColour(properties.ssd, vm!.minViewModel.model.count, vm!.maxViewModel.model.count);
                    break;
            }

            return styles;
        };
    }, [map, props.navigationAreaViewModel?.model.id]);

    /**
     * Effect to listen for a change to the layer. It will close an existing popup and
     * listen out for click events to open a new one.
     */
    useEffect(() => {
        map.closePopup();

        const popup = (event: any) => {
            L.popup()
                .setContent(ReactDOMServer.renderToString(renderPopup(event)))
                .setLatLng(event.latlng)
                .openOn(map);
        };

        vectorGrid.on("click", popup);
    }, [map, props.navigationAreaViewModel?.model.id]);

    /**
     * Effect to listen for a change to the layer. It will replace the layer on the map.
     */
    useEffect(() => {
        try {
            vectorGrid.setZIndex(80000);
            map.addLayer(vectorGrid);

            return () => {
                map.removeLayer(vectorGrid);
            };
        } catch {
            // Nothing to do here.
        }
    }, [map, props.navigationAreaViewModel?.model.id]);

    /**
     * Effect to close a map popup, when the component unmounts.
     */
    useEffect(() => {
        return () => {
            map.closePopup();
        };
    }, []);

    return null;
};
