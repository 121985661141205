// Libraries
import { Grid, TextField, Theme, Typography, useMediaQuery } from "@material-ui/core";
import LocationOnTwoToneIcon from "@material-ui/icons/LocationOnTwoTone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import classNames from "classnames";
import { LatLngBounds } from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Core
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { WMFSEditAddressStyle, WMFSAutocompleteAddressStyle } from "./../WMFSPrimitiveStyles";

interface IProps {
    ariaLabel?: string;
    className?: string;
    disabled?: boolean;
    searchAddress: string;
    setAddress: (address: string, isValid: boolean, latitude?: number, longitude?: number, postcode?: string) => void;
    bounds?: LatLngBounds;
}

interface PlaceType {
    location: string;
    longitude: number;
    latitude: number;
    postcode: string;
}

export const WMFSEditAddress: React.FC<IProps> = (props) => {
    const editClasses = WMFSEditAddressStyle();
    const autoCompleteClasses = WMFSAutocompleteAddressStyle();

    const getClassName = (): string => {
        return classNames({
            WMFSSelect: true,
            [`${props.className}`]: !isEmptyOrWhitespace(props.className),
        });
    };

    // #region OpenStreetMap Code Behind

    const [placeSelection, setPlaceSelection] = React.useState<PlaceType | null>(null);
    const [places, setPlaces] = React.useState<PlaceType[]>([]);
    const [mapProvider] = useState(
        () =>
            new OpenStreetMapProvider({
                params: {
                    countrycodes: "gb",
                    addressdetails: 1,
                    dedupe: 1,
                    bounded: props.bounds ? 1 : 0,
                    viewbox: props.bounds ? `${props.bounds.getNorthEast().lng},${props.bounds.getNorthEast().lat},${props.bounds.getSouthWest().lng},${props.bounds.getSouthWest().lat}` : "",
                },
            }),
    );

    const isValidPostcodeFormat = (query: string): boolean => /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/gi.test(query);

    const createPlaces = (results: SearchResult[]) => {
        const places: PlaceType[] = [];

        for (const result of results) {
            let location = "";
            let postcode = "";

            if (result.raw.hasOwnProperty("address")) {
                const address = (result.raw as any).address;
                const parts = [];

                parts.push(address?.village);
                parts.push(address?.town);
                parts.push(address?.city);
                parts.push(address?.county);
                parts.push(address?.postcode);

                location = parts.filter((p) => p !== undefined).join(", ");
                postcode = address?.postcode;
            } else {
                location = result.label;
                postcode = "";
            }

            if (!isEmptyOrWhitespace(location)) {
                places.push({
                    location: location,
                    longitude: result.x,
                    latitude: result.y,
                    postcode: postcode,
                });
            }
        }

        return places;
    };

    useEffect(() => {
        let active = true;

        if (isEmptyOrWhitespace(props.searchAddress) || !isValidPostcodeFormat(props.searchAddress)) {
            if (isEmptyOrWhitespace(props.searchAddress)) {
                setPlaceSelection(null);
                setPlaces([]);
            } else {
                setPlaces(placeSelection ? [placeSelection] : []);
            }

            return undefined;
        }

        const search = async () => {
            const results = await mapProvider.search({
                query: props.searchAddress,
            });

            if (active) {
                const createdPlaces = createPlaces(results);

                setPlaces(createdPlaces);
            }
        };

        search();

        return () => {
            active = false;
        };
    }, [props.searchAddress]);

    const onInputChange = (event: any, newValue: string): void => {
        props.setAddress(newValue, false);
    };

    const onChange = (event: any, newValue: PlaceType | null): void => {
        setPlaces(newValue ? [newValue, ...places] : places);
        setPlaceSelection(newValue);

        if (newValue) {
            props.setAddress(newValue.location, true, newValue!.latitude, newValue!.longitude, newValue.postcode);
        }
    };

    // #endregion OpenStreetMap Code Behind

    return useObserver(() => (
        <Autocomplete
            aria-label={props.ariaLabel}
            autoHighlight={true}
            classes={{
                paper: autoCompleteClasses.paper,
                option: autoCompleteClasses.option,
                popper: autoCompleteClasses.popper,
            }}
            className={getClassName()}
            disabled={props.disabled}
            getOptionLabel={(option) => option.location}
            onChange={onChange}
            onInputChange={onInputChange}
            options={places}
            renderInput={(params) => (
                <TextField
                    {...params}
                    classes={editClasses}
                    placeholder="Your postcode"
                    InputLabelProps={{
                        shrink: true,
                        variant: "standard",
                    }}
                    variant="outlined"
                />
            )}
            renderOption={(option) => (
                <Grid
                    alignItems="center"
                    container>
                    <Grid
                        item>
                        <LocationOnTwoToneIcon />
                    </Grid>
                    <Grid
                        item xs>
                        <Typography
                            variant="body1">
                            {option.location}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            value={placeSelection}
        />
    ));
};
