import { Box, Drawer, Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography, useMediaQuery, Button, ButtonBase } from "@material-ui/core";
import { Close, ArrowLeft, ArrowRight } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import classNames from "classnames";
import L from "leaflet";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Custom
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { isNullOrUndefined } from "Custom/Utils/Utils";
import { MapViewModel } from "Custom/ViewModels/Map/MapViewModel";
import { MapStyles } from "./MapStyles";
import { OpenStreetMap } from "./OpenStreetMap/OpenStreetMap";
import { Navigation } from "./Navigation/Navigation";
import { WMFSEditAddress } from "./../Primitives/Edits/WMFSEditAddress";
import { WMFSSelect } from "./../Primitives/Selects/WMFSSelect";
import { ZoomControl } from "./CustomControls/ZoomControl";

interface IInformationDialog {
    icon: string;
    title: string;
    tooltip: string;
}

export const Map: React.FC = () => {
    // #region Code Behind

    const [viewModel] = useState(() => new MapViewModel());
    const classes = MapStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(theme.breakpoints.values.md));
    const [canDisplayDrawer, setCanDisplayDrawer] = useState(false);
    const [openInformationDialog, setOpenInformationDialog] = React.useState<IInformationDialog | null>(null);
    const { t } = useTranslation();

    const toggleDrawer = () => {
        setCanDisplayDrawer(!canDisplayDrawer);
    };

    const closeDrawer = (action: () => void) => {
        setCanDisplayDrawer(false);
        action();
    };

    const handleOpenInformationDialog = (icon: string, title: string, tooltip: string) => {
        setOpenInformationDialog({
            icon: icon,
            title: title,
            tooltip: tooltip,
        });
    };

    const handleCloseInformationDialog = () => {
        setOpenInformationDialog(null);
    };

    // #endregion Code Behind

    const renderDrawer = () => {
        return (
            <Drawer
                anchor={"top"}
                className={classes.drawer}
                open={canDisplayDrawer}
                onClose={toggleDrawer}>
                <Navigation
                    viewModel={viewModel.navigationViewModel} />
            </Drawer>
        );
    };

    const renderIcon = (image: string) => {
        return (
            <div
                style={{
                    backgroundColor: `${customColours.wmfsDarkGrey}`,
                    maskImage: `url(${image})`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                    WebkitMaskImage: `url(${image})`,
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskSize: "contain",
                    height: "1.75rem",
                    width: "1.75rem",
                }}
            />
        );

    };

    const renderInformationDialog = () => {
        return (
            <Dialog
                aria-describedby="id_dialog_content"
                aria-labelledby="id_dialog_title"
                className={classes.informationDialog}
                onClose={handleCloseInformationDialog}
                open={!isNullOrUndefined(openInformationDialog)}>
                {!isNullOrUndefined(openInformationDialog) && (
                    <React.Fragment>
                        <DialogTitle
                            className={classes.informationDialogTitle}
                            disableTypography>
                            {renderIcon(openInformationDialog!.icon)}
                            <Typography
                                aria-hidden="true"
                                id="id_dialog_title"
                                variant="h6">
                                {openInformationDialog!.title}
                            </Typography>
                            <IconButton
                                aria-label="Close dialog."
                                onClick={handleCloseInformationDialog}
                                size="small">
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent
                            aria-hidden="true"
                            className={classes.informationDialogContent}>
                            <Typography id="id_dialog_content">{openInformationDialog!.tooltip}</Typography>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        );
    };

    const renderTopBar = () => {
        const mapBounds = L.latLngBounds(
            L.latLng(viewModel.mapBoundsSouthWest.latitude, viewModel.mapBoundsSouthWest.longitude),
            L.latLng(viewModel.mapBoundsNorthEast.latitude, viewModel.mapBoundsNorthEast.longitude),
        );

        return isMobile ? (
            <Box
                className={classes.topBar}>
                <Box>
                    <WMFSEditAddress
                        ariaLabel={t("accessibility.searchAddress")}
                        bounds={mapBounds}
                        disabled={canDisplayDrawer}
                        searchAddress={viewModel.searchAddress}
                        setAddress={viewModel.setSearchAddress}
                    />
                    <WMFSSelect
                        ariaLabel={t("accessibility.searchNumberOfDays")}
                        canExecute={!canDisplayDrawer && viewModel.isValidAddress}
                        className={classes.topBarSelectPeriod}
                        disableClearable={true}
                        execute={viewModel.setSearchPeriod}
                        getOptionLabel={(option) => option.displayName}
                        options={viewModel.searchPeriods}
                        value={viewModel.searchPeriod}
                    />
                    <ZoomControl
                        ariaLabel={t("accessibility.searchRange")}
                        disabled={canDisplayDrawer || !viewModel.isValidAddress}
                        mapRange={viewModel.model.searchRangeMiles}
                        mapRanges={viewModel.mapRanges}
                        setMapRange={viewModel.setMapRangeMiles}
                    />
                </Box>
                <IconButton
                    color="inherit"
                    onClick={toggleDrawer}
                    style={{ padding: "0" }}>
                    {canDisplayDrawer ? (
                        <MenuOpenIcon />
                    ) : (
                        <MenuIcon />
                    )}
                </IconButton>
            </Box>
        ) : (
            <React.Fragment />
        );
    };

    const renderSideBar = () => {
        const getSideBarClassName = (): string => {
            return classNames({
                [`${classes.sideBar}`]: true,
                [`${classes.sidebarCollapse}`]: !viewModel.canDisplaySidebar,
            });
        };

        const getToggleClassName = (): string => {
            return classNames({
                [`${classes.sidebarToggle}`]: true,
                [`${classes.sidebarToggleCollapse}`]: !viewModel.canDisplaySidebar,
            });
        };

        const mapBounds = L.latLngBounds(
            L.latLng(viewModel.mapBoundsSouthWest.latitude, viewModel.mapBoundsSouthWest.longitude),
            L.latLng(viewModel.mapBoundsNorthEast.latitude, viewModel.mapBoundsNorthEast.longitude),
        );

        const renderIcon = (image: string) => {
            return (
                <div
                    style={{
                        backgroundColor: `${customColours.wmfsDarkGrey}`,
                        maskImage: `url(${image})`,
                        maskPosition: "center",
                        maskRepeat: "no-repeat",
                        maskSize: "contain",
                        WebkitMaskImage: `url(${image})`,
                        WebkitMaskPosition: "center",
                        WebkitMaskRepeat: "no-repeat",
                        WebkitMaskSize: "contain",
                        height: "1.75rem",
                        width: "1.75rem",
                    }}
                />
            );
        };

        return isMobile ? (
            <React.Fragment />
        ) : (
            <Box
                className={classes.sidebarDrawer}>
                <Box
                    className={getSideBarClassName()}>
                    <Box
                        className={classes.sideBarFilter}>
                        <Box 
                            className={classes.sideBarTitle}>
                            <Typography
                                variant="caption">{t("map.navigation.common.introduction.displayName")}
                            </Typography>
                            <button
                                aria-haspopup="dialog"
                                aria-label="Display information."
                                className={classes.infoIcon}
                                onClick={(e) => {
                                    handleOpenInformationDialog(
                                        "resources/navigation/info_white_24dp.svg",
                                        t("map.navigation.common.introduction.tooltipDisplayName"),
                                        t("map.navigation.common.introduction.tooltip"),
                                    );
                                    e.stopPropagation();
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                                tabIndex={0}>
                                {renderIcon("resources/navigation/info_white_24dp.svg")}
                            </button>
                        </Box>
                        <Box
                            className={classes.sideBarForm}>
                            <WMFSEditAddress
                                ariaLabel={t("accessibility.searchAddress")}
                                bounds={mapBounds}
                                className={classes.sideBarEditAddress}
                                searchAddress={viewModel.searchAddress}
                                setAddress={viewModel.setSearchAddress}
                            />
                            <WMFSSelect
                                ariaLabel={t("accessibility.searchNumberOfDays")}
                                canExecute={viewModel.isValidAddress}
                                className={classes.sideBarSelectPeriod}
                                disableClearable={true}
                                execute={viewModel.setSearchPeriod}
                                getOptionLabel={(option) => option.displayName}
                                options={viewModel.searchPeriods}
                                value={viewModel.searchPeriod}
                            />
                            <ZoomControl
                                ariaLabel={t("accessibility.searchRange")}
                                disabled={!viewModel.isValidAddress}
                                mapRange={viewModel.model.searchRangeMiles}
                                mapRanges={viewModel.mapRanges}
                                setMapRange={viewModel.setMapRangeMiles}
                            />
                        </Box>
                    </Box>
                    <Box
                        className="vertical-flex-stack">
                        <Navigation
                            viewModel={viewModel.navigationViewModel} />
                    </Box>
                </Box>
                <Box
                    role="none"
                    className={getToggleClassName()}>
                    <IconButton
                        aria-label="Toggle sidebar."
                        aria-expanded={viewModel.canDisplaySidebar}
                        color="inherit"
                        onClick={viewModel.setCanDisplaySidebar}
                        style={{ padding: "0" }}>
                        {viewModel.canDisplaySidebar ? <ArrowRight /> : <ArrowLeft />}
                    </IconButton>
                </Box>
            </Box>
        );
    };

    const renderMap = () => {
        return (
            <Box
                className={classes.map}>
                <OpenStreetMap
                    viewModel={viewModel} />
            </Box>
        );
    };

    return useObserver(() => (
        <React.Fragment>
            <Box
                className={classes.mapWorkspace}>
                {renderTopBar()}
                <Box
                    className={classes.mapWorkspaceInner}>
                    <h1 className="screenreader-only">{t("map.title")}</h1>
                    {renderSideBar()}
                    {renderMap()}
                    {isMobile && renderDrawer()}
                </Box>
            </Box>
            {renderInformationDialog()}
        </React.Fragment>
    ));
};
