import { createContext } from "react";
import * as Utils from "Core/Utils/Utils";
import * as Models from "Custom/Models";
// Libs
// App
import { BaseStore } from "./BaseStore";
import { action, computed, observable } from "mobx";
import { Stores } from "../Stores";
import { InitialState } from "Custom/Models";
import { AccountStatus } from "Core/Models";

export class AccountStore extends BaseStore {
    @observable public IsLoggedIn: boolean = false;
    @observable public UserName: string = "";
    @observable public DisplayName: string = "";
    @observable public UserRoles: string[] = [];
    @observable public UseAdminGrids: boolean = false;
    @observable public UseAdminControlsLight: boolean = false;

    private jwt: string | null = null;
    private refreshToken: string | null = null;
    private refreshTokenExpiryDate: Date | null = null;
    private tokenViewModel = {};

    public constructor() {
        super();
    }

    public init(stores: Stores, initialState: InitialState) {
        this.UseAdminGrids = initialState.appSettings.useAdminGrids === "true";
        this.UseAdminControlsLight = initialState.appSettings.useAdminControlsLight === "true";
        this.getLoginState(initialState.accountStatus);
        //this.tokenViewModel = new TokenViewModel();
    }

    @action
    public setIsLoggedIn(state: boolean) {
        this.IsLoggedIn = state;
    }

    public isInRole = (role: string): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes(role);
        }

        return false;
    };

    @action
    public Logout = (redirect: boolean = false): void => {
        localStorage.removeItem(".auth");
        document.cookie = ".refreshtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        this.IsLoggedIn = false;
        this.UserName = "";
        this.DisplayName = "";
        this.UserRoles = [];
        //(window as any).jwt = null;
        if (redirect) {
            window.location.href = "/";
        }
    };

    public getJwt() {
        return this.jwt;
    }
    @action
    public getLoginState = (apiResult: AccountStatus) => {
        let jwt = apiResult?.jwt ?? "";
        if (!jwt || jwt.length === 0) {
            this.jwt = localStorage.getItem(".auth") as string;
            //this.refreshToken = localStorage.getItem(".refreshToken");
            //this.refreshTokenExpiryDate = new Date(localStorage.getItem(".refreshTokenExpiryDate") as string);
        } else {
            this.jwt = jwt;
        }

        if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
            const data = Utils.parseJwt(this.jwt);
            localStorage.setItem(".auth", this.jwt);
            //localStorage.setItem(".refreshToken", apiResult?.refreshToken ?? "");
            //localStorage.setItem(".refreshTokenExpiryDate", apiResult?.refreshTokenExpiryDate ?? new Date());
            this.IsLoggedIn = true;
            this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
            this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
            this.UserRoles = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as string[];
            // EN: For Debugging
            //(window as any).jwt = this.jwt;
        }
    };

    @computed get GetUseAdminGrids(): boolean {
        return this.UseAdminGrids;
    }

    @computed get GetUseAdminControlsLight(): boolean {
        return this.UseAdminControlsLight;
    }
}
