import React from "react";
import { Switch, Route } from "react-router";

import { Home } from "./Custom/Views/Home/Home";
import { Map } from "./Custom/Views/Map/Map";
import { Redirect } from "react-router-dom";

export const AppRoutes: React.FC = () => {
    return (
        <Switch>
            <Route
                component={Home}
                exact
                path="/"
            />
            <Route
                component={Map}
                exact
                path="/map"
            />
            {/* Fall back to the home page for any other route */}
            <Redirect to="/" />
        </Switch>
    );
};
