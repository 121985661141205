import { observable, action } from "mobx";
import { IModel } from "./IModel";
import { InputValuesDirty, InputValuesTouched, InputValuesErrors, InputValuesValidity } from "./IModel";
import _ from "lodash";
import { FieldType, getParentObjectPath } from "../Utils/Utils";

export abstract class ModelBase<T, TDTO> implements IModel<T> {
	@observable public Errors = {} as InputValuesErrors<T>;
	@observable public Valid = {} as InputValuesValidity<T>;
	@observable public Dirty = {} as InputValuesDirty<T>;
	@observable public Touched = {} as InputValuesTouched<T>;

	abstract fromDto(model: TDTO): void;
	abstract toDto(model: T): void;

	constructor() {
		//Loop through added properties setting their default values
		for (let prop in this) {
			if (prop != "Errors" && prop != "Valid" && prop != "Dirty" && prop != "Touched") {
				// @ts-ignore
				this.Errors[prop] = "";
				// @ts-ignore
				this.Valid[prop] = true;
				// @ts-ignore
				this.Dirty[prop] = false;
				// @ts-ignore
				this.Touched[prop] = false;
			}
		}
	}

	@action
	public setValue<TR>(fieldName: keyof FieldType<T>, value: TR): void {
		_.set(this, fieldName as any, value);
	}

	public getValue<TR>(fieldName: keyof FieldType<T>): TR {
		return _.get(this, fieldName as any);
	}

	@action
	public setError(fieldName: keyof FieldType<T>, value: string): void {
		let path = getParentObjectPath(fieldName as any, "Errors");
		_.set(this, path, value);
	}

	public getError(fieldName: keyof FieldType<T>): string {
		let path = getParentObjectPath(fieldName as any, "Errors");
		return _.get(this, path);
	}

	@action
	public setValid(fieldName: keyof FieldType<T>, value: boolean): void {
		let path = getParentObjectPath(fieldName as any, "Valid");
		_.set(this, path, value);
	}

	public getValid(fieldName: keyof FieldType<T>): boolean {
		let path = getParentObjectPath(fieldName as any, "Valid");
		return _.get(this, path);
	}

	@action
	public setDirty(fieldName: keyof FieldType<T>, value: boolean): void {
		let path = getParentObjectPath(fieldName as any, "Dirty");
		_.set(this, path, value);
	}

	public getDirty(fieldName: keyof FieldType<T>): boolean {
		let path = getParentObjectPath(fieldName as any, "Dirty");
		return _.get(this, path);
	}

	@action
	public setTouched(fieldName: keyof FieldType<T>, value: boolean): void {
		let path = getParentObjectPath(fieldName as any, "Touched");
		_.set(this, path, value);
	}

	public getTouched(fieldName: keyof FieldType<T>): boolean {
		let path = getParentObjectPath(fieldName as any, "Touched");
		return _.get(this, path);
	}
}
