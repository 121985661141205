import { action, computed, observable } from "mobx";

import { BlankModel } from "Core/Models/BlankModel";
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

export default class LayoutViewModel extends ViewModelBase<BlankModel> {
    constructor() {
        super(new BlankModel());

        this.setDecorators(BlankModel);

        ViewModelBase.history.listen((location) => {
            this.setCanDisplayNavigateToHome(location.pathname !== "/");
            this.setCanDisplayNavigateToMap(location.pathname !== "/map");
        });

        this.setCanDisplayNavigateToHome(location.pathname !== "/");
        this.setCanDisplayNavigateToMap(location.pathname !== "/map");
    }

    // #region Properties

    @observable
    public canDisplayHeader = true;

    @observable
    public canDisplayNavigateToHome = false;

    @observable
    public canDisplayNavigateToMap = false;

    // #endregion Properties

    // #region Actions

    @action
    private setCanDisplayNavigateToHome = (value: boolean) => {
        this.canDisplayNavigateToHome = value;
    };

    @action
    private setCanDisplayNavigateToMap = (value: boolean) => {
        this.canDisplayNavigateToMap = value;
    };

    @action
    public navigateToHome = () => {
        ViewModelBase.history.push("/");
    };

    @action
    public navigateToMap = () => {
        ViewModelBase.history.push("/map");
    };

    // #endregion Actions

    // #region Computeds

    @computed
    public get CanDisplayNavigateToHome(): boolean {
        return this.canDisplayNavigateToHome;
    }

    @computed
    public get CanDisplayNavigateToMap(): boolean {
        return this.canDisplayNavigateToMap;
    }

    @computed
    public get HaveOptions(): boolean {
        return this.CanDisplayNavigateToHome || this.CanDisplayNavigateToMap;
    }

    // #region Computeds

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
