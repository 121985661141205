import { nanoid } from "nanoid";

// Core
import { ModelBase } from "Core/Models/ModelBase";

export class FireStationPointModel extends ModelBase<FireStationPointModel, FireStationPointRequestDto> {
    constructor() {
        super();
    }

    public KEY = nanoid();

    public address1 = "";

    public address2 = "";

    public address3 = "";

    public commandAreaName = "";

    public facebook = "";

    public name = "";

    public postcode = "";

    public telephone = "";

    public twitter = "";

    public website = "";

    public isHQ = false;

    public latitiude = 0;

    public longitude = 0;

    public fromDto(dto: FireStationPointRequestDto): void {
        this.address1 = dto.address1;
        this.address2 = dto.address2;
        this.address3 = dto.address3;
        this.commandAreaName = dto.commandAreaName;
        this.facebook = dto.facebook;
        this.name = dto.name;
        this.postcode = dto.postcode;
        this.telephone = dto.telephone;
        this.twitter = dto.twitter;
        this.website = dto.website;
        this.isHQ = dto.isHQ;
        this.latitiude = dto.latitude;
        this.longitude = dto.longitude;
    }

    public toDto(): FireStationPointRequestDto {
        throw new Error("Method not implemented.");
    }
}

export interface FireStationPointRequestDto {
    address1: string;
    address2: string;
    address3: string;
    commandAreaName: string;
    facebook: string;
    name: string;
    postcode: string;
    telephone: string;
    twitter: string;
    website: string;
    isHQ: boolean;
    latitude: number;
    longitude: number;
}
