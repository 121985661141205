import { action, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";
import { FireStationPointModel } from "./FireStationPointModel.";

export class FireStationsModel extends ModelBase<FireStationsModel, FireStationsModelRequestDto> {
    constructor() {
        super();
    }

    @observable
    public fireStations = observable<FireStationPointModel>([]);

    @action
    public fromDto(dto: any): void {
        const fireStations: FireStationPointModel[] = [];

        for (const item of dto) {
            const fireStation = new FireStationPointModel();

            fireStation.fromDto(item);

            fireStations.push(fireStation);
        }

        this.fireStations.replace(fireStations);
    }

    public toDto(): FireStationsModelRequestDto {
        throw new Error("Method not implemented.");
    }
}

export interface FireStationsModelRequestDto {}
