import { IconButton } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Custom
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { isNullOrUndefined } from "Custom/Utils/Utils";
import { NavigationAreaViewModel } from "Custom/ViewModels/Map/Navigation/NavigationViewModel";
import { WMMSOAsLegendStyles } from "./WMLegendStyles";

interface IProps {
    navigationAreaViewModel?: NavigationAreaViewModel;
}

export const WMFloodRiskLegend: React.FunctionComponent<IProps> = (props) => {
    const [isLegendExpanded, setLegendExpanded] = useState(true);
    const legendStyles = WMMSOAsLegendStyles();
    const { t } = useTranslation();

    const vm = props.navigationAreaViewModel;
    const canDisplay = () => !isNullOrUndefined(vm);

    const risks = new Map<string, string>([
        ["#a7c8c8", t("map.legend.riskTypes.lowRisk")],
        ["#2b92fb", t("map.legend.riskTypes.moderateRisk")],
        ["#120efc", t("map.legend.riskTypes.highRisk")],
    ]);

    const getValues = () => {
        const items: any[] = [];

        for (const risk of risks) {
            items.push(
                <div
                    className={legendStyles.item}
                    key={risk[0]}
                    role="listitem">
                    <div
                        className={legendStyles.colour}
                        style={{ backgroundColor: risk[0] }}
                    />
                    <div>{risk[1]}</div>
                </div>,
            );
        }

        return items;
    };

    const renderIcon = (image: string) => {
        return (
            <div
                style={{
                    backgroundColor: `${customColours.wmfsDarkGrey}`,
                    maskImage: `url(${image})`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                    WebkitMaskImage: `url(${image})`,
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskSize: "contain",
                    height: "1.75rem",
                    width: "1.75rem",
                    marginRight: "0.5rem",
                }}
            />
        );
    };

    const renderTitle = () => {
        return (
            <div
                className={legendStyles.title}>
                <div aria-hidden="true">{renderIcon(vm!.model.navigationImage)}</div>
                <div aria-hidden="true">{vm!.model.displayName}</div>
                <div
                    role="none"
                    className={legendStyles.expandButtonContainer}>
                    <IconButton
                        aria-label="Toggle legend."
                        aria-expanded={isLegendExpanded}
                        aria-controls="id_legend_content"
                        onClick={() => setLegendExpanded(!isLegendExpanded)}
                        size="small"
                        tabIndex={0}>
                        {isLegendExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </div>
            </div>
        );
    };

    const renderItems = () => {
        return (
            <div
                role="list"
                className={legendStyles.items}>
                {getValues()}
            </div>
        );
    };

    return canDisplay() ? (
        <div
            className={`${legendStyles.container} leaflet-bottom leaflet-left leaflet-right`}
            role="none">
            <div
                className="leaflet-control leaflet-bar"
                role="none">
                <div
                    aria-label={`${vm!.model.displayName}.`}
                    className={legendStyles.root}
                    role="region"
                    tabIndex={0}>
                    {renderTitle()}
                    {isLegendExpanded && (
                        <div
                            id="id_legend_content"
                            className={legendStyles.itemsContainer}>
                            {renderItems()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <React.Fragment />
    );
};
