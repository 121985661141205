import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const WMMSOAsLegendStyles = makeStyles((theme: Theme) => ({
    container: {
        color: theme.palette.mapLegend.contrastText,
        right: "auto !important",
        "& > div": {
            backgroundColor: theme.palette.mapLegend.main,
            border: `2px solid ${theme.palette.mapLegend.dark} !important`,
        },
    },
    root: {
        display: "flex",
        flexDirection: "column",
        fontFamily: `"ITC Avant Garde", "Helvetica", "Arial", sans-serif`,
        padding: "0.25rem",
    },
    title: {
        alignItems: "center",
        display: "flex",
        margin: "0 0 0.125rem 0.25rem",
    },
    expandButtonContainer: {
        marginLeft: "auto",
        "& > button": {
            color: "black",
            marginLeft: "0.25rem",
        },
    },
    itemsContainer: {
        display: "flex",
        fontFamily: "Aptos",
    },
    items: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-between",
        lineHeight: "1.25",
        margin: "0 0.4rem",
    },
    item: {
        alignItems: "center",
        display: "flex",
        margin: "0.125rem 0.25rem",
    },
    itemMinMax: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        maxWidth: "9rem",
        textAlign: "center",
        "&:first-child": {
            flexDirection: "column",
        },
        "&:last-child": {
            flexDirection: "column-reverse",
        },
        "& > svg": {
            height: "1.25rem",
            width: "1.25rem",
        },
    },
    colour: {
        height: "1rem",
        marginRight: "0.5rem",
        width: "1rem",
    },
    divider: {
        borderBottom: "1px solid #ccc",
        margin: "0 0.5rem",
    },
}));
