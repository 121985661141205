import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import "leaflet.vectorgrid";
import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";

// Custom
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { PopupStyles } from "./WMListedBuildingsVectorGridStyles";

interface IProps {
    mapBounds: any;
}

export const WMListedBuildingsVectorGrid = (props: any) => {
    /**
     * Patches an error when clicking on point data.
     */
    const patchVectorGridLayer = (obj: any) => {
        obj._createLayer_orig = obj._createLayer;
        obj._createLayer = function (feat: any, pxPerExtent: any, layerStyle: any) {
            const layer = this._createLayer_orig(feat, pxPerExtent, layerStyle);

            if (feat.type === 1) {
                layer.getLatLng = null;
            }

            return layer;
        };

        return obj;
    };

    const popupStyles = PopupStyles();
    const { map } = useLeafletContext();
    const { t } = useTranslation();

    const mapStyles = { stroke: true, color: "#FFFFFF", opacity: 0.85, weight: 1, fill: true, fillOpacity: 0.85, fillColor: "#000000" };
    const url = "https://shoothillukwestgeneral.blob.core.windows.net/westmidsfs/tiles/listedbuildings/alpha1/{z}/{x}/{y}.pbf";

    const options = {
        bounds: props.mapBounds,
        interactive: true,
        maxZoom: 18,
        minZoom: 8,
        rendererFactory: (L.svg as any).tile,
        getFeatureId: (f: any) => {
            return f.properties.id;
        },
    };

    const renderIcon = (image: string) => {
        return (
            <div
                style={{
                    backgroundColor: `${customColours.wmfsDarkGrey}`,
                    maskImage: `url(${image})`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                    WebkitMaskImage: `url(${image})`,
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskSize: "contain",
                    height: "1.75rem",
                    width: "1.75rem",
                    marginRight: "0.5rem",
                }}
            />
        );
    };

    const renderPopup = (event: any) => {
        return (
            <div
                className={popupStyles.root}>
                <div
                    className={popupStyles.header}>
                    <div
                        className={popupStyles.headerImage}
                        style={{ backgroundImage: `url("resources/navigation/castle_white_24dp.svg")` }}
                    />
                    {t("map.popup.listedBuildings.listedBuilding")}
                </div>
                <div className={popupStyles.body}>
                    <div className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/home_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div>{event.layer.properties.name}</div>
                            <div>{event.layer.properties.location}</div>
                        </div>
                    </div>
                    <div className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/info_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div>{`${t("map.popup.listedBuildings.grade")} ${event.layer.properties.grade}`}</div>
                            <div>{`${t("map.popup.listedBuildings.listDate")} ${event.layer.properties.listDate}`}</div>
                            {event.layer.properties.amendDate !== "NULL" &&
                                <div>{`${t("map.popup.listedBuildings.amendedDate")} ${event.layer.properties.amendDate}`}</div>
                            }
                        </div>
                    </div>
                    <div className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/language_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <a
                                href={event.layer.properties.hyperlink}
                                target="_blank">
                                {t("map.popup.listedBuildings.moreInformation")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const vectorGrid = (L as any).vectorGrid.protobuf(url, options);

    /**
     * Effect to listen for a change to the layer. It will close an existing popup and
     * listen out for click events to open a new one.
     */
    useEffect(() => {
        map.closePopup();

        const popup = (event: any) => {
            L.popup()
                .setContent(ReactDOMServer.renderToString(renderPopup(event)))
                .setLatLng(event.latlng)
                .openOn(map);
        };

        patchVectorGridLayer(vectorGrid).on("click", popup);
    }, [map]);

    /**
     * Effect to listen for a change to the layer. It will update the layer style.
     */
    useEffect(() => {
        vectorGrid.options.vectorTileLayerStyles.ListedBuildings = (properties: any) => {
            return { ...mapStyles };
        };
    }, [map]);

    /**
     * Effect to listen for a change to the layer. It will replace the layer on the map.
     */
    useEffect(() => {
        try {
            vectorGrid.setZIndex(100000);
            map.addLayer(vectorGrid);

            return () => {
                map.removeLayer(vectorGrid);
            };
        } catch {
            // Nothing to do here.
        }
    }, [map]);

    /**
     * Effect to close a map popup, when the component unmounts.
     */
    useEffect(() => {
        return () => {
            map.closePopup();
        };
    }, []);

    return null;
};
