import { useObserver } from "mobx-react-lite";
import React from "react";

// Custom
import { AttendedIncidentDataPointModel } from "Custom/Models/Map/Data/AttendedIncidentDataPointModel";
import { AttendedIncidentMarkerControl } from "../CustomControls/AttendedIncidentMarkerControl";

interface IProps {
    attendedIncidents: AttendedIncidentDataPointModel[];
    zoom: number;
}

export const WMAttendedIncidentMarkers: React.FC<IProps> = (props: IProps) => {
    return useObserver(() => (
        <React.Fragment>
            {props.attendedIncidents.map((attendedIncident: AttendedIncidentDataPointModel) => {
                return (
                    <AttendedIncidentMarkerControl
                        key={attendedIncident.KEY}
                        model={attendedIncident}
                        zoom={props.zoom}
                    />
                );
            })}
        </React.Fragment>
    ));
};
