// Libraries
import { Grid, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import classNames from "classnames";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Core
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { isNullOrUndefined } from "Custom/Utils/Utils";
import { WMFSAutocompleteSelectStyle, WMFSSelectStyle } from "./../WMFSPrimitiveStyles";

interface IProps {
    ariaLabel?: string;
    canExecute?: boolean;
    className?: string;
    disableClearable?: boolean;
    displayName?: string;
    execute: (value: any) => void;
    getOptionLabel: (option: any) => string;
    groupBy?: (option: any) => string;
    options: any[];
    placeholder?: string;
    validationMessage?: string;
    value: any;
}

export const WMFSSelect: React.FC<IProps> = (props: IProps) => {
    // #region Code Behind

    const selectClasses = WMFSSelectStyle();
    const autoCompleteClasses = WMFSAutocompleteSelectStyle();

    const getClassName = (): string => {
        return classNames({
            WMFSSelect: true,
            [`${props.className}`]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getDisplayName = (): string => {
        return !isEmptyOrWhitespace(props.displayName) ? props.displayName! : "";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return !isEmptyOrWhitespace(props.validationMessage) ? props.validationMessage! : "";
    };

    const isClearableDisabled = (): boolean => {
        return !isNullOrUndefined(props.disableClearable) ? props.disableClearable! : false;
    };

    const isDisabled = (): boolean => {
        return !isNullOrUndefined(props.canExecute) ? !props.canExecute : false;
    };

    const isValidationMessageNotEmpty = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage);
    };

    const onChange = (e: any, value: any): void => {
        props.execute(value);
    };

    // #endregion Code Behind

    // #region Public Interface

    return useObserver(() => (
        <Autocomplete
            aria-label={props.ariaLabel}
            classes={{
                paper: autoCompleteClasses.paper,
                option: autoCompleteClasses.option,
                popper: autoCompleteClasses.popper,
            }}
            className={getClassName()}
            disableClearable={isClearableDisabled()}
            disabled={isDisabled()}
            getOptionLabel={props.getOptionLabel}
            groupBy={props.groupBy}
            onChange={onChange}
            options={props.options}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    classes={selectClasses}
                    error={isValidationMessageNotEmpty()}
                    helperText={getValidationMessage()}
                    InputLabelProps={{
                        shrink: true,
                        variant: "standard",
                    }}
                    InputProps={{
                        ...params.InputProps,
                    }}
                    label={getDisplayName()}
                    placeholder={getPlaceholder()}
                    variant="outlined"
                />
            )}
            renderOption={(option) => (
                <Grid
                    alignItems="center"
                    container>
                    <Grid
                        item xs>
                        <Typography
                            variant="body1">
                            {props.getOptionLabel(option)}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            value={props.value} />
    ));

    // #endregion Public Interface
};
