import { IconButton } from "@material-ui/core";
import { ExpandLess, ExpandMore, VerticalAlignTop, VerticalAlignBottom } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Core
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { isNullOrUndefined } from "Custom/Utils/Utils";
import { getIncidentTypesPaletteColour } from "Custom/Views/Map/Utils/Palettes";
import { NavigationAreaViewModel } from "Custom/ViewModels/Map/Navigation/NavigationViewModel";
import { WMMSOAsLegendStyles } from "./WMLegendStyles";

interface IProps {
    navigationAreaViewModel?: NavigationAreaViewModel;
}

export const WMFireServiceRiskLegend: React.FunctionComponent<IProps> = (props) => {
    const [isLegendExpanded, setLegendExpanded] = useState(true);
    const legendStyles = WMMSOAsLegendStyles();
    const { t } = useTranslation();

    const vm = props.navigationAreaViewModel;
    const canDisplay = () => !isNullOrUndefined(vm);

    const risks = new Map<number, string>([
        [-1, t("map.legend.riskTypes.noData")],
        [1, t("map.legend.riskTypes.veryLowRisk")],
        [2, t("map.legend.riskTypes.lowRisk")],
        [3, t("map.legend.riskTypes.moderateRisk")],
        [4, t("map.legend.riskTypes.highRisk")],
        [5, t("map.legend.riskTypes.veryHighRisk")],
    ]);

    const getValues = (min: number, max: number, layerId: string) => {
        const increments = 5;
        const delta = (max - min) / (increments - 1);
        const items: any[] = [];

        let invert = false;

        for (let i = 0; i < increments; i++) {
            const value = min + delta * i;
            const colour = getIncidentTypesPaletteColour(value, min, max, Number.MAX_VALUE, invert);
            const valueString = risks.get(Math.round((value + Number.EPSILON) * 1) / 1);

            items.push(
                <div
                    className={legendStyles.item}
                    key={i}
                    role="listitem">
                    <div
                        className={legendStyles.colour}
                        style={{ backgroundColor: colour }}
                    />
                    <div>{valueString}</div>
                </div>,
            );
        }

        // This is the "no information" key.
        items.push(
            <div
                className={legendStyles.item}
                key={-1}
                role="listitem">
                <div
                    className={legendStyles.colour}
                    style={{ backgroundColor: "#ccc" }}
                />
                <div>{risks.get(-1)}</div>
            </div>,
        );

        return items;
    };

    const renderIcon = (image: string) => {
        return (
            <div
                style={{
                    backgroundColor: `${customColours.wmfsDarkGrey}`,
                    maskImage: `url(${image})`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                    WebkitMaskImage: `url(${image})`,
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskSize: "contain",
                    height: "1.75rem",
                    width: "1.75rem",
                    marginRight: "0.5rem",
                }}
            />
        );
    };

    const renderTitle = () => {
        return (
            <div
                className={legendStyles.title}>
                <div aria-hidden="true">{renderIcon(vm!.model.navigationImage)}</div>
                <div aria-hidden="true">{vm!.model.displayName}</div>
                <div
                    role="none"
                    className={legendStyles.expandButtonContainer}>
                    <IconButton
                        aria-label="Toggle legend."
                        aria-expanded={isLegendExpanded}
                        aria-controls="id_legend_content"
                        onClick={() => setLegendExpanded(!isLegendExpanded)}
                        size="small"
                        tabIndex={0}>
                        {isLegendExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </div>
            </div>
        );
    };

    const renderItems = () => {
        return (
            <div
                role="list"
                className={legendStyles.items}>
                {getValues(vm!.minViewModel.model.count, vm!.maxViewModel.model.count, vm!.model.id)}
            </div>
        );
    };

    const renderMinMaxItems = () => {
        return (
            <div
                role="list"
                className={legendStyles.items}>
                <div
                    role="listitem"
                    className={legendStyles.itemMinMax}>
                    <VerticalAlignTop />
                    {vm!.minViewModel.model.displayName}
                </div>
                <div
                    role="listitem"
                    className={legendStyles.itemMinMax}>
                    <VerticalAlignBottom />
                    {vm!.maxViewModel.model.displayName}
                </div>
            </div>
        );
    };

    return canDisplay() ? (
        <div
            className={`${legendStyles.container} leaflet-bottom leaflet-left leaflet-right`}
            role="none">
            <div
                className="leaflet-control leaflet-bar"
                role="none">
                <div
                    aria-label={`${vm!.model.displayName}.`}
                    className={legendStyles.root}
                    role="region"
                    tabIndex={0}>
                    {renderTitle()}
                    {isLegendExpanded && (
                        <div
                            id="id_legend_content"
                            className={legendStyles.itemsContainer}>
                            {renderItems()}
                            {!isEmptyOrWhitespace(vm!.minViewModel.model.displayName) && !isEmptyOrWhitespace(vm!.maxViewModel.model.displayName) && renderMinMaxItems()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <React.Fragment />
    );
};
