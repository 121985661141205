// Libraries
import { LatLngExpression } from "leaflet";
import React from "react";
import { Circle, CircleMarker } from "react-leaflet";

interface IProps {
    center: LatLngExpression;
    snappedDistanceMetres: number;
}

export const RangeCircleControl: React.FC<IProps> = (props: IProps) => {
    return (
        <React.Fragment>
            <Circle
                center={props.center}
                color="white"
                fillColor="#6A1216"
                interactive={false}
                radius={props.snappedDistanceMetres}
                weight={2}
            />
            <CircleMarker
                center={props.center}
                color="white"
                fillColor="#666"
                fillOpacity={1}
                interactive={false}
                opacity={1}
                radius={8}
                weight={2}
            />
        </React.Fragment>
    );
};
