import { makeStyles } from "@material-ui/core";

// Custom
import { customColours, zIndexHierarchies } from "Custom/StylesAppSpecific/AppStyling";

export const MapStyles = makeStyles((theme) => ({
    "@keyframes infoIconHoverEffect": {
        "0%": {
            transform: "scale(1)",
        },
        "50%": {
            transform: "scale(1.2)",
        },
        "100%": {
            transform: "scale(1)",
        },
    },
    infoIcon: {
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        marginLeft: "auto",
        padding: "0",

        "&:focus": {
            animation: "$infoIconHoverEffect 0.75s ease-in-out infinite",
            outline: "none",
        },
        "&:hover": {
            animation: "$infoIconHoverEffect 0.75s ease-in-out infinite",
        },
    },
    mapWorkspace: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
        minHeight: "100%",
        paddingTop: "4.25rem",
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            paddingTop: "5rem",
        },
    },
    mapWorkspaceInner: {
        display: "flex",
        flex: "1",
        minHeight: "0",
    },
    map: {
        flex: "1",
    },
    sidebarDrawer: {
        display: "flex",
        minHeight: 0,
    },
    sidebarCollapse: {
        maxWidth: 0,
    },
    sidebarToggle: {
        alignItems: "center",
        backgroundColor: "#ffffff",
        display: "flex",
        height: "5rem",
        left: "22rem",
        position: "absolute",
        top: "calc((100% - 2.5rem) / 2)",
        zIndex: 1000,
        "& button": {
            borderRadius: "0",
            height: "100%",
        },
    },
    sidebarToggleCollapse: {
        left: 0,
    },
    sideBar: {
        display: "flex",
        flexDirection: "column",
        fontFamily: "Aptos",
        overflowY: "auto",
        width: "22rem",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            display: "none",
        },
    },
    sideBarFilter: {
        padding: "1rem 0.75rem 0.8rem 1.875rem",
        backgroundColor: customColours.wmfsLightGrey,
        borderBottom: `2px solid ${customColours.wmfsGrey}`,
    },
    sideBarTitle: {
        alignItems: "center",
        display: "flex",
        "&&": {
            lineHeight: "1rem",
            marginBottom: "0.4rem",
        },
    },
    sideBarForm: {
        marginRight: "1.125rem",
    },
    sideBarEditAddress: {
        padding: "0.5rem 0",
    },
    sideBarSelectPeriod: {
        minWidth: "10em",
        padding: "0.5rem 0",
        "& > div > div.MuiInputBase-root": {
            backgroundColor: "white !important",
            margin: "0 !important",
            padding: "0 3.5rem 0 0.5rem !important",
            "& > input": {
                backgroundColor: "transparent !important",
                fontSize: "0.75rem",
                padding: "0.25rem !important",
            },
            "& > div": {
                top: "calc(50% - 0.875rem)",
                "& > button": {},
            },
        },
    },
    topBar: {
        alignItems: "center",
        backgroundColor: customColours.wmfsLightGrey,
        display: "flex",
        flexWrap: "wrap",
        fontFamily: "Aptos",
        justifyContent: "space-between",
        padding: "0 2rem",
        zIndex: zIndexHierarchies.mapHeader,
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            height: "9.625rem",
            padding: "0 1rem",
            "& > div": {
                display: "flex",
                flex: "1",
                flexDirection: "column",
                marginRight: "1rem",
                "& > div:first-child": {
                    padding: "0.5rem 0 0.25rem 0",
                },
            },
        },
    },
    topBarEditAddress: {
        minWidth: "18rem",
        padding: "0.5rem 0",
    },
    topBarSelectPeriod: {
        minWidth: "10em",
        padding: "0.5rem 0",
        "& > div > div.MuiInputBase-root": {
            backgroundColor: "white !important",
            margin: "0 !important",
            padding: "0 3.5rem 0 0.5rem !important",
            "& > input": {
                backgroundColor: "transparent !important",
                fontSize: "0.75rem",
                padding: "0.25rem !important",
            },
            "& > div": {
                top: "calc(50% - 0.875rem)",
                "& > button": {},
            },
        },
    },
    drawer: {
        fontFamily: "Aptos",
        "& > .MuiDrawer-paper": {
            display: "flex",
            overflowY: "unset",
            paddingTop: "13.875rem",
            /* Mobile copy of the Navigation component */
            "& > div": {
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
            },
        },
    },
    informationDialog: {
        "& .MuiPaper-root": {
            backgroundColor: "white",
            border: "1px solid #005215",
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "30rem",
        },
    },
    informationDialogTitle: {
        alignItems: "center",
        display: "flex",
        fontSize: "0.75rem",
        lineHeight: "1.4",
        padding: "0.75rem 1rem",
        "& > div": {
            marginRight: "0.75rem",
        },
        "& button": {
            marginLeft: "auto",
        },
    },
    informationDialogContent: {
        padding: "0rem 1rem 0.75rem 1rem",
        "& .MuiTypography-root": {
            fontSize: "0.75rem",
            lineHeight: "1.4",
            whiteSpace: "pre-line",
        },
    },
}));
