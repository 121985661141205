// Core
import { ModelBase } from "Core/Models/ModelBase";

export class SafeAndWellActivityModel extends ModelBase<SafeAndWellActivityModel, SafeAndWellActivityRequestDto> {
    public station = "";

    constructor() {
        super();
    }

    public fromDto(dto: SafeAndWellActivityRequestDto): void {
        this.station = dto.station;
    }

    public toDto(): SafeAndWellActivityRequestDto {
        throw new Error("Method not implemented.");
    }
}

export interface SafeAndWellActivityRequestDto {
    station: string;
}
