import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import "leaflet.vectorgrid";
import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";

// Custom
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { formatOrdinal, isNullOrUndefined } from "Custom/Utils/Utils";
import { getDemographicsPaletteColour, getEthnicityV2PaletteColour } from "Custom/Views/Map/Utils/Palettes";
import { NavigationAreaViewModel } from "Custom/ViewModels/Map/Navigation/NavigationViewModel";
import { PopupStyles } from "./WMDemographicsVectorGridStyles";

interface IProps {
    mapBounds: any;
    navigationAreaViewModel?: NavigationAreaViewModel;
    navigationAreaViewModels?: NavigationAreaViewModel[];
}

export const WMDemographicsVectorGrid = (props: IProps) => {
    const popupStyles = PopupStyles();
    const { map } = useLeafletContext();
    const { t } = useTranslation();

    const mapStyles = { stroke: true, color: "#000000", opacity: 0.66, weight: 1, fill: true, fillOpacity: 0.6, fillColor: "#000000" };
    const url = "https://shoothillukwestgeneral.blob.core.windows.net/westmidsfs/tiles/other/alpha1/{z}/{x}/{y}.pbf";

    const options = {
        bounds: props.mapBounds,
        interactive: true,
        maxZoom: 18,
        minZoom: 8,
        rendererFactory: (L.svg as any).tile,
        getFeatureId: (f: any) => {
            return f.properties.id;
        },
    };

    // #region Formatting

    // Round to one decimal place.
    const roundValue = (value: number) => Math.round((value + Number.EPSILON) * 10) / 10;

    // Round to integer.
    const roundIntegerValue = (value: number) => Math.round(value + Number.EPSILON);

    // Format number as ordinal (st, nd, th).
    const ordinalValue = (value: number) => formatOrdinal(roundIntegerValue(value));

    // #endregion Formatting

    const renderIcon = (image: string) => {
        return (
            <div
                style={{
                    backgroundColor: `${customColours.wmfsDarkGrey}`,
                    maskImage: `url(${image})`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                    WebkitMaskImage: `url(${image})`,
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskSize: "contain",
                    height: "1.75rem",
                    width: "1.75rem",
                    marginRight: "0.5rem",
                }}
            />
        );
    };

    const renderPopup = (event: any) => {
        // #region Ethnicity

        const ethnicities = Array.from(
            new Map<string, number>([
                ["", 0],
                [t("map.common.ethnicity.asian"), event.layer.properties.eth_asian],
                [t("map.common.ethnicity.black"), event.layer.properties.eth_black],
                [t("map.common.ethnicity.mixed"), event.layer.properties.eth_mixed],
                [t("map.common.ethnicity.other"), event.layer.properties.eth_other],
                [t("map.common.ethnicity.white"), event.layer.properties.eth_white],
            ]),
        );

        let largestEthnicty = ethnicities[0];

        for (let ethnicity of ethnicities) {
            if (ethnicity[1] > largestEthnicty[1]) {
                largestEthnicty = ethnicity;
            }
        }

        const ethnicityPercentageOfPopulation = roundIntegerValue((100 * largestEthnicty[1]) / event.layer.properties.age_popCnt);

        // #endregion Ethnicity

        // #region Rankings

        const maxDeprivation = () => props.navigationAreaViewModels?.find((vm) => vm.model.id === "id_imdranking")?.maxViewModel.model.count;
        const maxCrime = () => props.navigationAreaViewModels?.find((vm) => vm.model.id === "id_crimeranking")?.maxViewModel.model.count;
        const maxAntisocialBehaviour = () => props.navigationAreaViewModels?.find((vm) => vm.model.id === "id_antisocialbehaviour")?.maxViewModel.model.count;
        const maxUnemployment = () => props.navigationAreaViewModels?.find((vm) => vm.model.id === "id_unemploymentranking")?.maxViewModel.model.count;

        // #endregion Rankings

        // #region Formatting

        const popuationValue = () => roundValue(event.layer.properties.age_popCnt);
        const householdsValue = () => roundValue(event.layer.properties.hhc_cnt);

        const age65OverValue = () => (!isNullOrUndefined(event.layer.properties.age_65Over) ? roundValue(event.layer.properties.age_65Over) : t("map.popup.common.notAvailable"));
        const householdsWithOnePersonValue = () => (!isNullOrUndefined(event.layer.properties.hhc_single) ? roundValue(event.layer.properties.hhc_single) : t("map.popup.common.notAvailable"));
        const householdsSociallyRentingValue = () => (!isNullOrUndefined(event.layer.properties.rnt_social) ? roundValue(event.layer.properties.rnt_social) : t("map.popup.common.notAvailable"));
        const ethnicityValue1 = () => largestEthnicty[0];
        const ethnicityValue2 = () =>
            `${largestEthnicty[1]} ${t("map.popup.demographics.common.persons")} (${ethnicityPercentageOfPopulation}% ${t("map.popup.demographics.common.ofPopulationAbbr")})`;
        const imdRankingValue = () =>
            !isNullOrUndefined(event.layer.properties.imd_rk)
                ? `${ordinalValue(event.layer.properties.imd_rk)} (${t("map.popup.demographics.common.outOf")} ${maxDeprivation()})`
                : t("map.popup.common.notAvailable");
        const imdCrimeRankingValue = () =>
            !isNullOrUndefined(event.layer.properties.imd_rkCrim)
                ? `${ordinalValue(event.layer.properties.imd_rkCrim)} (${t("map.popup.demographics.common.outOf")} ${maxCrime()})`
                : t("map.popup.common.notAvailable");
        const antiSocialBehaviourRankingValue = () =>
            !isNullOrUndefined(event.layer.properties.asb_rk)
                ? `${ordinalValue(event.layer.properties.asb_rk)} (${t("map.popup.demographics.common.outOf")} ${maxAntisocialBehaviour()})`
                : t("map.popup.common.notAvailable");
        const imdUnemploymentRankingValue = () =>
            !isNullOrUndefined(event.layer.properties.imd_rkUem)
                ? `${ordinalValue(event.layer.properties.imd_rkUem)} (${t("map.popup.demographics.common.outOf")} ${maxUnemployment()})`
                : t("map.popup.common.notAvailable");

        // #endregion Formatting

        return (
            <div 
                className={popupStyles.root}>
                <div
                    className={popupStyles.header}>
                    <div
                        className={popupStyles.headerImage}
                        style={{ backgroundImage: `url("resources/navigation/face_white_24dp.svg")` }}
                    />
                    {event.layer.properties.LSOA11NM}
                </div>
                <div
                    className={popupStyles.body}>
                    <div
                        className={popupStyles.bodyTopContainer}>
                        <div>
                            <div className={popupStyles.bodyTopContainerTitle}>{t("map.popup.demographics.population")}</div>
                            <div
                                className={popupStyles.bodyTopContainerValues}>
                                {popuationValue()}
                            </div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyTopContainerTitle}>{t("map.popup.demographics.households")}</div>
                            <div
                                className={popupStyles.bodyTopContainerValues}>
                                {householdsValue()}
                            </div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/elderly_woman_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.demographics.populationAged65AndOver")}</div>
                            <div>{age65OverValue()}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/person_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.demographics.householdsWithOnePerson")}</div>
                            <div>{householdsWithOnePersonValue()}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/other_houses_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.demographics.householdsSociallyRenting")}</div>
                            <div>{householdsSociallyRentingValue()}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/diversity_3_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.demographics.ethnicity")}</div>
                            <div>{ethnicityValue1()}</div>
                            <div>{ethnicityValue2()}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/bar_chart_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.demographics.imdRanking")}</div>
                            <div>{imdRankingValue()}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/bar_chart_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.demographics.imdCrimeRanking")}</div>
                            <div>{imdCrimeRankingValue()}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/bar_chart_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.demographics.antiSocialBehaviourRanking")}</div>
                            <div>{antiSocialBehaviourRankingValue()}</div>
                        </div>
                    </div>
                    <div 
                        className={popupStyles.bodyItemContainer}>
                        <div>
                            <div>{renderIcon("resources/navigation/bar_chart_white_24dp.svg")}</div>
                        </div>
                        <div>
                            <div className={popupStyles.bodyItemContainerTitle}>{t("map.popup.demographics.imdUnemploymentRanking")}</div>
                            <div>{imdUnemploymentRankingValue()}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const vectorGrid = (L as any).vectorGrid.protobuf(url, options);

    /**
     * Effect to listen for a change to the layer. It will update the layer style.
     */
    useEffect(() => {
        vectorGrid.options.vectorTileLayerStyles.Other = (properties: any) => {
            const styles = { ...mapStyles };
            const vm = props.navigationAreaViewModel;

            switch (vm?.model.id) {
                case "id_antisocialbehaviour":
                    styles.fillColor = getDemographicsPaletteColour(properties.asb_rk, vm!.minViewModel.model.count, vm!.maxViewModel.model.count, Number.MAX_VALUE, true);
                    break;

                case "id_largestethnicgroup":
                    styles.fillColor = getEthnicityV2PaletteColour(properties.eth_asian, properties.eth_black, properties.eth_mixed, properties.eth_other, properties.eth_white, properties.age_popCnt);
                    break;

                case "id_householdsinsocialhousing":
                    styles.fillColor = getDemographicsPaletteColour(properties.rnt_social, vm!.minViewModel.model.count, vm!.maxViewModel.model.count);
                    break;

                case "id_households1person":
                    styles.fillColor = getDemographicsPaletteColour(properties.hhc_single, vm!.minViewModel.model.count, vm!.maxViewModel.model.count);
                    break;

                case "id_crimeranking":
                    styles.fillColor = getDemographicsPaletteColour(properties.imd_rkCrim, vm!.minViewModel.model.count, vm!.maxViewModel.model.count, Number.MAX_VALUE, true);
                    break;

                case "id_imdranking":
                    styles.fillColor = getDemographicsPaletteColour(properties.imd_rk, vm!.minViewModel.model.count, vm!.maxViewModel.model.count, Number.MAX_VALUE, true);
                    break;

                case "id_unemploymentranking":
                    styles.fillColor = getDemographicsPaletteColour(properties.imd_rkUem, vm!.minViewModel.model.count, vm!.maxViewModel.model.count, Number.MAX_VALUE, true);
                    break;

                case "id_population65andover":
                    styles.fillColor = getDemographicsPaletteColour(properties.age_65Over, vm!.minViewModel.model.count, vm!.maxViewModel.model.count);
                    break;
            }

            return styles;
        };
    }, [map, props.navigationAreaViewModel?.model.id]);

    /**
     * Effect to listen for a change to the layer. It will close an existing popup and
     * listen out for click events to open a new one.
     */
    useEffect(() => {
        map.closePopup();

        const popup = (event: any) => {
            L.popup()
                .setContent(ReactDOMServer.renderToString(renderPopup(event)))
                .setLatLng(event.latlng)
                .openOn(map);
        };

        vectorGrid.on("click", popup);
    }, [map, props.navigationAreaViewModel?.model.id]);

    /**
     * Effect to listen for a change to the layer. It will replace the layer on the map.
     */
    useEffect(() => {
        try {
            vectorGrid.setZIndex(80000);
            map.addLayer(vectorGrid);

            return () => {
                map.removeLayer(vectorGrid);
            };
        } catch {
            // Nothing to do here.
        }
    }, [map, props.navigationAreaViewModel?.model.id]);

    /**
     * Effect to close a map popup, when the component unmounts.
     */
    useEffect(() => {
        return () => {
            map.closePopup();
        };
    }, []);

    return null;
};
