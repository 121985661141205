import { IconButton } from "@material-ui/core";
import { ExpandLess, ExpandMore, VerticalAlignTop, VerticalAlignBottom } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Core
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

// Custom
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { isNullOrUndefined } from "Custom/Utils/Utils";
import { getDemographicsPaletteColour } from "Custom/Views/Map/Utils/Palettes";
import { NavigationAreaViewModel } from "Custom/ViewModels/Map/Navigation/NavigationViewModel";
import { WMMSOAsLegendStyles } from "./WMLegendStyles";

interface IProps {
    selectedViewModelId?: string;
    navigationAreaViewModel?: NavigationAreaViewModel;
}

export const WMDemographicsLegend: React.FunctionComponent<IProps> = (props) => {
    const [isLegendExpanded, setLegendExpanded] = useState(true);
    const legendStyles = WMMSOAsLegendStyles();
    const { t } = useTranslation();

    const vm = props.navigationAreaViewModel;
    const canDisplay = () => !isNullOrUndefined(vm);

    const getValues = (min: number, max: number, layerId: string) => {
        const increments = 6;
        const delta = (max - min) / (increments - 1);
        const items: any[] = [];

        let invert = false;

        switch (layerId) {
            case "id_antisocialbehaviour":
            case "id_imdranking":
            case "id_crimeranking":
            case "id_unemploymentranking":
                invert = true;
                break;
        }

        if (layerId === "id_largestethnicgroup") {
            const ethnicities = Array.from(
                new Map<string, string>([
                    [t("map.common.ethnicity.white"), `hsl(${327}, ${45}%, ${64}%)`],
                    [t("map.common.ethnicity.asian"), `hsl(${164}, ${100}%, ${31}%)`],
                    [t("map.common.ethnicity.black"), `hsl(${56}, ${85}%, ${60}%)`],
                    [t("map.common.ethnicity.mixed"), `hsl(${202}, ${100}%, ${35}%)`],
                    [t("map.common.ethnicity.other"), `hsl(${26}, ${100}%, ${42}%)`],
                ]),
            );

            for (let ethnicity of ethnicities) {
                items.push(
                    <div
                        className={legendStyles.item}
                        key={ethnicity[0]}
                        role="listitem">
                        <div
                            className={legendStyles.colour}
                            style={{ backgroundColor: ethnicity[1] }}
                        />
                        {ethnicity[0]}
                    </div>,
                );
            }
        } else {
            for (let i = 0; i < increments; i++) {
                const value = min + delta * i;
                const colour = getDemographicsPaletteColour(value, min, max, Number.MAX_VALUE, invert);
                const valueString = Math.round((value + Number.EPSILON) * 1) / 1;

                items.push(
                    <div
                        className={legendStyles.item}
                        key={i}
                        role="listitem">
                        <div
                            className={legendStyles.colour}
                            style={{ backgroundColor: colour }}
                        />
                        {valueString}
                    </div>,
                );
            }
        }

        return items;
    };

    const renderIcon = (image: string) => {
        return (
            <div
                style={{
                    backgroundColor: `${customColours.wmfsDarkGrey}`,
                    maskImage: `url(${image})`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                    WebkitMaskImage: `url(${image})`,
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskSize: "contain",
                    height: "1.75rem",
                    width: "1.75rem",
                    marginRight: "0.5rem",
                }}
            />
        );
    };

    const renderTitle = () => {
        return (
            <div
                className={legendStyles.title}>
                <div aria-hidden="true">{renderIcon(vm!.model.navigationImage)}</div>
                <div aria-hidden="true">{vm!.model.displayName}</div>
                <div
                    role="none"
                    className={legendStyles.expandButtonContainer}>
                    <IconButton
                        aria-label="Toggle legend."
                        aria-expanded={isLegendExpanded}
                        aria-controls="id_legend_content"
                        onClick={() => setLegendExpanded(!isLegendExpanded)}
                        size="small"
                        tabIndex={0}>
                        {isLegendExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </div>
            </div>
        );
    };

    const renderItems = () => {
        return (
            <div
                role="list"
                className={legendStyles.items}>
                {getValues(vm!.minViewModel.model.count, vm!.maxViewModel.model.count, vm!.model.id)}
            </div>
        );
    };

    const renderMinMaxItems = () => {
        return (
            <div
                role="list"
                className={legendStyles.items}>
                <div
                    role="listitem"
                    className={legendStyles.itemMinMax}>
                    <VerticalAlignTop />
                    {vm!.minViewModel.model.displayName}
                </div>
                <div
                    role="listitem"
                    className={legendStyles.itemMinMax}>
                    <VerticalAlignBottom />
                    {vm!.maxViewModel.model.displayName}
                </div>
            </div>
        );
    };

    return canDisplay() ? (
        <div
            className={`${legendStyles.container} leaflet-bottom leaflet-left leaflet-right`}
            role="none">
            <div
                className="leaflet-control leaflet-bar"
                role="none">
                <div 
                    aria-label={`${vm!.model.displayName}.`}
                    className={legendStyles.root}
                    role="region"
                    tabIndex={0}>
                    {renderTitle()}
                    {isLegendExpanded && (
                        <div
                            id="id_legend_content"
                            className={legendStyles.itemsContainer}>
                            {renderItems()}
                            {!isEmptyOrWhitespace(vm!.minViewModel.model.displayName) && !isEmptyOrWhitespace(vm!.maxViewModel.model.displayName) && renderMinMaxItems()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <React.Fragment />
    );
};
