import { countBy } from "lodash";
import { action, computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";
import { AttendedIncidentDataPointModel, IncidentDataPointRequestDto } from "./AttendedIncidentDataPointModel";
import { SafeAndWellActivityModel } from "./SafeAndWellActivityModel";

export class DataModel extends ModelBase<DataModel, DataModelRequestDto> {
    constructor() {
        super();
    }

    @observable
    public latitude = 0;

    @observable
    public longitude = 0;

    @observable
    public averageResponseTime = 0;

    @observable
    public incidentsCount = 0;

    @observable
    public roadTrafficCollisions = 0;

    @observable
    public houseFires = 0;

    @observable
    public otherPropertyFires = 0;

    @observable
    public vehicleFires = 0;

    @observable
    public outdoorFires = 0;

    @observable
    public preventionSafeAndWell = 0;

    @observable
    public protectionSafeAndStrong = 0;

    @observable
    public fireSafetyAudits = 0;

    @observable
    public attendedIncidents = observable<AttendedIncidentDataPointModel>([]);

    @observable
    public safeAndWellActivities = observable<SafeAndWellActivityModel>([]);

    // #region Properties

    @computed
    public get preventionCount() {
        return this.preventionSafeAndWell;
    }

    @computed
    public get protectionCount() {
        return this.protectionSafeAndStrong + this.fireSafetyAudits;
    }

    @computed
    public get attendedIncidentsCount() {
        return this.attendedIncidents.length;
    }

    @computed
    public get falseAlarmIncidentGroupCount() {
        return this.attendedIncidents.filter((incident) => incident.isFalseAlarmIncidentGroup).length;
    }

    @computed
    public get fireIncidentGroupCount() {
        return this.attendedIncidents.filter((incident) => incident.isFireIncidentGroup).length;
    }

    @computed
    public get houseFiresCount() {
        return this.attendedIncidents.filter((incident) => incident.isHouseFire).length;
    }

    @computed
    public get otherPropertyFiresCount() {
        return this.attendedIncidents.filter((incident) => incident.isOtherPropertyFire).length;
    }

    @computed
    public get vehicleFiresCount() {
        return this.attendedIncidents.filter((incident) => incident.isVehicleFire).length;
    }

    @computed
    public get outdoorFiresCount() {
        return this.attendedIncidents.filter((incident) => incident.isOutdoorFire).length;
    }

    @computed
    public get otherFiresCount() {
        return this.attendedIncidents.filter((incident) => incident.isOutlierFire).length;
    }

    @computed
    public get roadTrafficCollisionGroupCount() {
        return this.attendedIncidents.filter((incident) => incident.isRoadTrafficCollisionIncidentGroup).length;
    }

    @computed
    public get specialServiceCallIncidentGroupCount() {
        return this.attendedIncidents.filter((incident) => incident.isSpecialServiceCallIncidentGroup).length;
    }

    @computed
    public get attendedIncidentCountByFireStation() {
        return countBy(this.attendedIncidents, (incident) => incident.firstOnSceneBrigadeLocation);
    }

    @computed
    public get safeAndWellActivityByFireStation() {
        return countBy(this.safeAndWellActivities, (activity) => activity.station);
    }

    // #endregion Properties

    // #region Dto/Model Management

    @action
    public fromDto(dto: any): void {
        // The Postcode Outcode coordinates.
        if (dto.hasOwnProperty("latitude")) {
            this.latitude = dto.latitude;
        }

        if (dto.hasOwnProperty("longitude")) {
            this.longitude = dto.longitude;
        }

        // The WMFS data.
        const response: DataModelRequestDto = JSON.parse(dto.data);

        if (response.hasOwnProperty("AverageResponseTime")) {
            this.averageResponseTime = response.AverageResponseTime.seconds;
        }

        if (response.hasOwnProperty("AttendedIncsCount")) {
            this.incidentsCount = response.AttendedIncsCount.count;
        }

        if (response.hasOwnProperty("AttendedIncsSummary")) {
            const dataPoints: AttendedIncidentDataPointModel[] = [];

            for (const item of response.AttendedIncsSummary) {
                const dataPoint = new AttendedIncidentDataPointModel();

                dataPoint.fromDto(item);

                dataPoints.push(dataPoint);
            }

            this.attendedIncidents.replace(dataPoints);
        }

        if (response.hasOwnProperty("RoadTrafficCollisions")) {
            this.roadTrafficCollisions = response.RoadTrafficCollisions.count;
        }

        if (response.hasOwnProperty("FiresSummary")) {
            this.houseFires = response.FiresSummary.houseFires.count;
            this.otherPropertyFires = response.FiresSummary.otherPropertyFires.count;
            this.vehicleFires = response.FiresSummary.vehicleFires.count;
            this.outdoorFires = response.FiresSummary.outdoorFires.count;
        }

        // APM - This is disabled for now as the WMFS API does not support this.
        // The feature has also been disabled in the NavigationModel file.
        //if (response.hasOwnProperty("InspectionsAndChecks")) {
        //    this.fireSafetyAudits = 0;
        //}

        if (response.hasOwnProperty("SafeAndStrongActivity")) {
            this.protectionSafeAndStrong = response.SafeAndStrongActivity.length;
        }

        if (response.hasOwnProperty("SafeAndWellActivity")) {
            this.preventionSafeAndWell = response.SafeAndWellActivity.length;

            const activities: SafeAndWellActivityModel[] = [];

            for (const item of response.SafeAndWellActivity) {
                const activity = new SafeAndWellActivityModel();

                activity.fromDto(item);

                activities.push(activity);
            }

            this.safeAndWellActivities.replace(activities);
        }
    }

    public toDto(): DataModelRequestDto {
        throw new Error("Method not implemented.");
    }

    // #endregion Dto/Model Management
}

export interface DataModelRequestDto {
    AverageResponseTime: any;
    AttendedIncsCount: any;
    AttendedIncsSummary: IncidentDataPointRequestDto[];
    RoadTrafficCollisions: any;
    FiresSummary: any;
    InspectionsAndChecks: any;
    SafeAndWellActivity: any;
    SafeAndStrongActivity: any;
}
