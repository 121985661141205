import { Button, ButtonBase, Grid, IconButton, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Home.css";

import { BackgroundStyles } from "Custom/StylesAppSpecific/BackgroundStyling";
import { HomeStyles } from "Custom/StylesAppSpecific/HomeStyling";
import HomeViewModel from "Custom/ViewModels/Home/HomeViewModel";

interface IProps {}

export const Home: React.FC<IProps> = (props) => {
    // #region Code Behind

    const backgroundClasses = BackgroundStyles();
    const classes = HomeStyles();
    const theme: any = useTheme();
    const [viewModel] = useState(() => new HomeViewModel());
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // #endregion Code Behind

    return useObserver(() => (
        <div className={classes.homeWorkspace}>
            <div className={classes.homeWorkspaceScrollableSection}>
                <div className={`${classes.homeBackgroundMask} ${backgroundClasses.homeBackgroundMask}`} />
                <article className={classes.homeArticle}>
                    <Typography
                        className={classes.homeTitle}
                        variant="h1"
                        gutterBottom>
                        {t("home.title")}
                    </Typography>
                    <Typography
                        className={classes.homeTextParagraph1}
                        variant="body1"
                        gutterBottom>
                        {t("home.paragraph1")}
                    </Typography>
                    <Typography
                        className={classes.homeTextParagraph}
                        variant="body1"
                        gutterBottom>
                        {t("home.paragraph2")}
                    </Typography>
                    <Typography
                        className={classes.homeTextParagraph}
                        variant="body1"
                        gutterBottom>
                        {t("home.paragraph3")}
                    </Typography>
                    <Typography
                        className={classes.homeTextParagraph}
                        variant="body1"
                        gutterBottom>
                        {t("home.paragraph4")}
                    </Typography>
                    <Typography
                        className={classes.homeTextParagraph}
                        variant="body1"
                        gutterBottom>
                        {t("home.paragraph5")}
                    </Typography>
                    <Typography
                        className={classes.homeTextParagraph}
                        variant="body1"
                        gutterBottom>
                        {t("home.paragraph6")}
                    </Typography>
                    <Grid
                        className={classes.homeNavigationRow}
                        container
                        role="navigation"
                        spacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={3}>
                            <Button
                                color="primary"
                                fullWidth
                                onClick={viewModel.navigateToMap}
                                style={{ color: theme.palette.primaryButton.contrastText }}
                                variant="contained">
                                {t("home.continueToMap")}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}>
                            <Button
                                color="primary"
                                fullWidth
                                href="https://www.wmfs.net/our-strategy-2024-2027/?utm_source=riskexplorer&utm_medium=website&utm_campaign=our_strategy_2024_2027&utm_content=ourstrategy"
                                style={{ color: theme.palette.primaryButton.contrastText }}
                                target="_blank"
                                variant="contained">
                                {t("home.gotoOurStrategy")}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}>
                            <Button
                                color="primary"
                                fullWidth
                                href="https://www.wmfs.net/community-risk-management-plan/"
                                style={{ color: theme.palette.primaryButton.contrastText }}
                                target="_blank"
                                variant="contained">
                                {t("home.gotoCommunityRiskManagementPlan")}
                            </Button>
                        </Grid>
                    </Grid>
                </article>
                <div className={classes.homeFooterRow}>
                    <ButtonBase
                        aria-label="Powered by Shoothill"
                        className={classes.homeShoothillButton}
                        focusRipple={true}
                        href="https://shoothill.com/"
                        target="_blank"
                        title="Powered by Shoothill">
                        <span className={classes.homeShoothillLogo} />
                    </ButtonBase>
                </div>
            </div>
        </div>
    ));
};
