import { Box, Button, Drawer, IconButton, Link, Theme, useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import LayoutViewModel from "Custom/ViewModels/LayoutViewModel";
import { LayoutStyles } from "./LayoutStyles";

export const Layout: React.FC<{}> = (props) => {
    // #region Code Behind

    const [viewModel] = useState(() => new LayoutViewModel());
    const { t } = useTranslation();
    const classes = LayoutStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(theme.breakpoints.values.md));
    const [canDisplayDrawer, setCanDisplayDrawer] = React.useState(false);

    const toggleDrawer = () => {
        setCanDisplayDrawer(!canDisplayDrawer);
    };

    const closeDrawer = (action: () => void) => {
        setCanDisplayDrawer(false);
        action();
    };

    // #endregion Code Behind

    const renderDrawer = () => (
        <Drawer
            className={classes.mobileDrawer}
            anchor={"top"}
            open={canDisplayDrawer}
            onClose={toggleDrawer}>
            <nav
                aria-label={t("accessibility.navigation")}
                className={classes.mobileDrawerOptions}>
                <React.Fragment>
                    {viewModel.CanDisplayNavigateToHome && (
                        <Button
                            aria-label={t("accessibility.navigationLinkHomePage")}
                            onClick={() => closeDrawer(viewModel.navigateToHome)}>
                            Home
                        </Button>
                    )}
                    {viewModel.CanDisplayNavigateToMap && (
                        <Button
                            aria-label={t("accessibility.navigationLinkMapPage")}
                            onClick={() => closeDrawer(viewModel.navigateToMap)}>
                            Map
                        </Button>
                    )}
                </React.Fragment>
            </nav>
        </Drawer>
    );

    const renderHeader = () => (
        <nav
            aria-label={t("accessibility.navigation")}
            className={classes.layoutContainer}>
            <div
                className={classes.headerContainer}>
                <Link
                    aria-label={t("accessibility.navigationLinkHomePage")}
                    component={RouterLink}
                    variant="body2"
                    to={"/"}>
                    <Box
                        className={classes.header} />
                </Link>
                <div className={classes.headerTitle}>Risk Explorer</div>
            </div>
            <Box
                className={classes.layoutCommands}>
                {isMobile && viewModel.HaveOptions ? (
                    <React.Fragment>
                        <IconButton
                            aria-label={t("accessibility.navigationMore")}
                            className={classes.mobileMenuButton}
                            color="inherit"
                            onClick={toggleDrawer}>
                            {canDisplayDrawer ? (
                                <MenuOpenIcon />
                            ) : (
                                <MenuIcon />
                            )}
                        </IconButton>
                    </React.Fragment>
                ) : (
                    <React.Fragment />
                )}
            </Box>
        </nav>
    );

    return useObserver(() => (
        <div>
            {viewModel.canDisplayHeader && renderHeader()}
            {isMobile && renderDrawer()}
            {props.children}
        </div>
    ));
};
