import { Dictionary } from "lodash";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Custom
import { FireStationMarkerControl } from "../CustomControls/FireStationMarkerControl";
import { FireStationPointModel } from "Custom/Models/Map/FireStations/FireStationPointModel.";

interface IProps {
    fireStations: FireStationPointModel[];
    attendedIncidentCountByFireStation: Dictionary<number>;
    safeAndWellActivityByFireStation: Dictionary<number>;
}

export const WMFireStationMarkers: React.FC<IProps> = (props: IProps) => {
    return useObserver(() => (
        <React.Fragment>
            {props.fireStations.map((fireStation: FireStationPointModel) => {
                const attendedIncidentsCount = props.attendedIncidentCountByFireStation[fireStation.name];
                const safeAndWellActivityCount = props.safeAndWellActivityByFireStation[fireStation.name];

                return (
                    <FireStationMarkerControl
                        key={fireStation.KEY}
                        model={fireStation}
                        attendedIncidentsCount={attendedIncidentsCount}
                        safeAndWellActivityCount={safeAndWellActivityCount}
                    />
                );
            })}
        </React.Fragment>
    ));
};
