import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    fallbackLng: "en_GB",
    lng: "en_GB",
    debug: false,
    react: {
        wait: true,
    },
    resources: {
        en_GB: {
            translation: {
                accessibility: {
                    navigation: "West Midlands Fire Service",
                    navigationMore: "More menu",
                    navigationLinkHomePage: "West Midlands Fire Service Risk Explorer home page",
                    navigationLinkMapPage: "West Midlands Fire Service Risk Explorer map page",
                    searchAddress: "Enter your postcode to search for incidents in your area.",
                    searchNumberOfDays: "Select how many days worth of incidents to search for.",
                    searchRange: "Select the range of the search area.",
                },
                home: {
                    title: "Risk Explorer",
                    continueToMap: "Explore",
                    gotoOurStrategy: "Our Strategy",
                    gotoCommunityRiskManagementPlan: "Community Risk Management Plan",
                    paragraph1: "Got a West Midlands postcode? Then our risk explorer has got your area covered!",
                    paragraph2: "Data drives more and more of our work to make the West Midlands safer, stronger and healthier.",
                    paragraph3: "It helps us to understand risks across the areas we serve. We use it to create our Community Risk Management Plan and three-year rolling strategy.",
                    paragraph4: "Enter your postcode, then explore and interact with the same data we use.",
                    paragraph5:
                        "With the map menu, you can find incidents to which we’ve responded near you, our response times, and even the different levels of risk for various emergency types. There’s also lots of handy demographic info, and you can turn different data on and off.",
                    paragraph6: "And don’t forget – on our website you can find out much more about our work and how to keep yourself safe.",
                },
                map: {
                    common: {
                        ethnicity: {
                            asian: "Asian/Asian British",
                            black: "Black/African/Caribbean/Black British",
                            mixed: "Mixed/Multiple",
                            other: "Other",
                            white: "White",
                        },
                        time: {
                            seconds: "seconds",
                            minutes: "minutes",
                            hours: "hours",
                        },
                    },
                    popup: {
                        common: {
                            notAvailable: "Information not available",
                        },
                        demographics: {
                            common: {
                                ofPopulationAbbr: "of pop.",
                                outOf: "out of",
                                persons: "persons",
                            },
                            antiSocialBehaviourRanking: "Antisocial behaviour ranking",
                            ethnicity: "Largest ethnic group",
                            households: "Households",
                            householdsSociallyRenting: "Households in social housing",
                            householdsWithOnePerson: "Households with one person",
                            imdCrimeRanking: "Crime ranking",
                            imdRanking: "Deprivation ranking",
                            imdUnemploymentRanking: "Unemployment ranking",
                            population: "Population",
                            populationAged65AndOver: "Persons aged 65 and over",
                        },
                        fireStations: {
                            website: "Station page",
                            incidentsResponsedTo: "Incidents responded to",
                            tooltipIncidentsResponsedTo: "This is a count of incidents that this station has responded to within the area highlighted on the map",
                            safeAndWellVisits: "Safe and well visits",
                            tooltipSafeAndWellVisits: "This is a count of Safe and Well visits that this station has carried out within the area highlighted on the map",
                        },
                        incidents: {
                            falseAlarm: "False alarm",
                            fire: "Fire",
                            houseFire: "House fire",
                            otherFire: "Other fire",
                            otherPropertyFire: "Other property fire",
                            outdoorFire: "Outdoor fire",
                            vehicleFire: "Vehicle fire",
                            roadTrafficCollision: "Road traffic collision",
                            specialServiceCall: "Special service call",
                        },
                        listedBuildings: {
                            amendedDate: "Amended date:",
                            grade: "Grade:",
                            listedBuilding: "Listed building",
                            listDate: "List date:",
                            moreInformation: "More information",
                        },
                        risks: {
                            houseFires: "House fires",
                            otherPropertyFires: "Other property fires",
                            outdoorFires: "Outdoor fires",
                            vehicleFires: "Vehicle fires",
                            roadTrafficCollisions: "Road traffic collisions",
                            specialservicedomestic: "Special service domestic",
                        },
                        riskTypes: {
                            noData: "No risk information",
                            veryLowRisk: "Very low risk",
                            lowRisk: "Low risk",
                            moderateRisk: "Moderate risk",
                            highRisk: "High risk",
                            veryHighRisk: "Very high risk",
                        },
                    },
                    legend: {
                        demographics: {
                            antiSocialBehaviourRanking: {
                                less: "Lower rates of antisocial behaviour",
                                more: "Higher rates of antisocial behaviour",
                            },
                            populationAged65AndOver: {
                                less: "Lower proportion 65 and over",
                                more: "Higher proportion 65 and over",
                            },
                            householdsWithOnePerson: {
                                less: "Lower proportion of single person households",
                                more: "Higher proportion of single person households",
                            },
                            householdsSociallyRenting: {
                                less: "Lower proportion in social housing",
                                more: "Higher proportion in social housing",
                            },
                            imdRanking: {
                                less: "Less deprived",
                                more: "More deprived",
                            },
                            imdCrimeRanking: {
                                less: "Lower rates of crime",
                                more: "Higher rates of crime",
                            },
                            imdUnemploymentRanking: {
                                less: "Lower rates of unemployment",
                                more: "Higher rates of unemployment",
                            },
                        },
                        riskTypes: {
                            noData: "No risk information",
                            veryLowRisk: "Very low risk",
                            lowRisk: "Low risk",
                            moderateRisk: "Moderate risk",
                            highRisk: "High risk",
                            veryHighRisk: "Very high risk",
                        },
                    },
                    navigation: {
                        common: {
                            introduction: {
                                displayName: "Please enter your full postcode to see information for your postcode area",
                                tooltipDisplayName: "Finding information in your area",
                                tooltip:
                                    "The postcode search will only work for addresses in the West Midlands region.\n\nThe postcode is a combination of between five and seven letters/numbers. It consists of two parts separated by a single space (for example WV1 1AA).",
                            },
                            less: "Less",
                            more: "More",
                            visitSingle: "Visit",
                            visitPlural: "Visits",
                            incidentSingle: "Incident",
                            incidentPlural: "Incidents",
                        },
                        apiData: {
                            averageResponseTime: "Average response time",
                            prevention: {
                                displayName: "Prevention",
                                tooltip: "Click to expand this option to see where we are targeting our prevention activities",
                                safeAndWell: {
                                    displayName: "Safe and well",
                                },
                            },
                            protection: {
                                displayName: "Protection",
                                tooltip: "Click to expand this option to see where we are targeting our protection activities",
                                safeAndStrong: {
                                    displayName: "Safe and strong",
                                },
                                fireSafetyAudits: {
                                    displayName: "Fire safety audits",
                                },
                            },
                            incidents: {
                                displayName: "Response",
                                tooltip: "Toggle one of more of the incident types below to see where incidents have occurred on the map",
                                falseAlarms: {
                                    displayName: "False alarms",
                                },
                                fires: {
                                    displayName: "Fires",
                                },
                                houseFires: {
                                    displayName: "House fires",
                                },
                                otherFires: {
                                    displayName: "Other fires",
                                },
                                otherPropertyFires: {
                                    displayName: "Other property fires",
                                },
                                outdoorFires: {
                                    displayName: "Outdoor fires",
                                },
                                vehicleFires: {
                                    displayName: "Vehicle fires",
                                },
                                roadTrafficCollisions: {
                                    displayName: "Road traffic collisions",
                                },
                                specialServiceCalls: {
                                    displayName: "Special service calls",
                                    tooltip:
                                        "A Special Service Call (SSC) is any incident where we are required to attend for purposes other than fire fighting. These can be emergency or non emergency incidents (RTC are technically Special Service Calls but we separate them out as they make up a significant proportion of the incidents we attend).\n\nExamples of SSCs include:\n• Providing emergency medical assistance.\n• Rescuing individuals from serious harm.\n• Incidents resulting from severe weather.\n• Emergencies resulting from events of widespread significance.\n• Requests for assistance for a non-emergency nature (these are chargeable).",
                                },
                            },
                        },
                        staticData: {
                            demographics: {
                                displayName: "Demographics",
                                tooltip: "Select from one of the demographics options below to see how these interact with the incidents we attend and activities we undertake.",
                                antiSocialBehaviourRanking: {
                                    displayName: "Antisocial behaviour ranking",
                                    tooltip:
                                        "This displays the antisocial behaviour ranking of each area compared to other areas in the West Midlands. The smaller the number, the higher the levels of antisocial behaviour.",
                                },
                                ethnicity: {
                                    displayName: "Largest ethnic group",
                                    tooltip: "This displays the largest ethnic group in each area.",
                                },
                                householdsSociallyRenting: {
                                    displayName: "Households in social housing",
                                    tooltip: "This displays the number of houses that are socially rented in each area.",
                                },
                                householdsWithOnePerson: {
                                    displayName: "Households with one person",
                                    tooltip: "This displays the number of houses with a single occupant in each area.",
                                },
                                imdCrimeRanking: {
                                    displayName: "Crime ranking",
                                    tooltip: "This displays the crime ranking of each area compared to other areas in the West Midlands. The smaller the number, the higher the crime rate.",
                                },
                                imdRanking: {
                                    displayName: "Deprivation ranking",
                                    tooltip:
                                        "This displays the deprivation ranking of each area compared to other areas in the West Midlands. The smaller the number, the higher the levels of deprivation.",
                                },
                                imdUnemploymentRanking: {
                                    displayName: "Unemployment ranking",
                                    tooltip:
                                        "This displays the unemployment ranking of each area compared to other areas in the West Midlands. The smaller the number, the higher the unemployment rate.",
                                },
                                populationAged65AndOver: {
                                    displayName: "Persons aged 65 and over",
                                    tooltip: "This displays the number of persons who are over 65 in each area.",
                                },
                            },
                            risks: {
                                displayName: "Risk modelling",
                                tooltip:
                                    "We undertake risk modelling to understand and predict where incidents are likely to occur. This helps us to better target our prevention, protection and response activities.\n\nSelect from one of the risk modelling options below to see how they compare to where incidents are occuring.",
                                floodRisk: {
                                    displayName: "Flood risk",
                                    tooltip: "This displays the risk of flooding from rivers in the West Midlands and surrounding regions.",
                                },
                                houseFires: {
                                    displayName: "House fires",
                                    tooltip: "This displays the risk of a house fire in each area.",
                                },
                                otherPropertyFires: {
                                    displayName: "Other property fires",
                                    tooltip: "This displays the risk of a non-dwelling fire in each area",
                                },
                                outdoorFires: {
                                    displayName: "Outdoor fires",
                                    tooltip: "This displays the risk of an outdoor fire in each area.",
                                },
                                vehicleFires: {
                                    displayName: "Vehicle fires",
                                    tooltip: "This displays the risk of a vehicle fire in each area.",
                                },
                                roadTrafficCollisions: {
                                    displayName: "Road traffic collisions",
                                    tooltip: "This displays the risk of a road traffic collision in each area.",
                                },
                                specialservicedomestic: {
                                    displayName: "Special service domestic",
                                    tooltip: "This displays the risk of a non-fire related event in each area.",
                                },
                            },
                            other: {
                                displayName: "Other",
                                tooltip: "This contains miscellaneous options that might be of interest.",
                                listedBuildings: {
                                    displayName: "Listed buildings",
                                    tooltip: "This displays the location of all listed buildings in the West Midlands.",
                                },
                            },
                        },
                    },
                    searchPeriod: {
                        last7Days: "Last 7 days",
                        last30Days: "Last 30 days",
                        last90Days: "Last 90 days",
                        last180Days: "Last 180 days",
                    },
                    title: "Risk Explorer",
                    postcodePlaceholder: "Postcode",
                },
            },
        },
    },
});

export default i18n;
