import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

export const HomeStyles = makeStyles((theme: Theme) => ({
    homeWorkspace: {
        // This class is the immediate parent of a scrollable section.
        // It has no height and min height of 0 for the scrollbar to be visible.
        display: "flex",
        flex: "1",
        flexDirection: "column",
        minHeight: "0",
    },
    homeWorkspaceScrollableSection: {
        // A scrollable section. Hereafter a height is not required. Plain flex
        // will do.
        display: "flex",
        flex: "1",
        flexDirection: "column",
        overflowY: "auto",
        marginTop: "4.25rem",
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            marginTop: "5rem",
        },
    },
    homeBackgroundMask: {
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "5.75rem",
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            minHeight: "11.75rem",
        },
    },
    homeArticle: {
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        padding: "1rem 1.25rem",
        width: "100%",
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            padding: "2.25rem 1.875rem 1.875rem 1.875rem",
            maxWidth: "60.125rem",
        },
    },
    homeTitle: {
        fontSize: "1.5rem",
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            fontSize: "2.625rem",
            marginBottom: "2rem",
        },
    },
    homeTextParagraph: {
        fontSize: "0.875rem",
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            fontSize: "1rem",
            marginBottom: "1.4rem",
        },
    },
    homeTextParagraph1: {
        fontSize: "1rem",
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            fontSize: "1.125rem",
            marginBottom: "1.4rem",
        },
    },
    homeNavigationRow: {
        marginBottom: "0.5rem",
        marginTop: "0.5rem",
        "& button": {
            margin: "0",
        },
        "& a": {
            margin: "0",
        },
    },
    homeFooterRow: {
        alignSelf: "end",
        display: "flex",
        flex: "1",
    },
    homeShoothillButton: {
        alignSelf: "end",
        margin: "0",
        padding: "0",
    },
    homeShoothillLogo: {
        background: "url(resources/ShoothillLogo.png) no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "contain",
        height: "72px",
        width: "151px",
    },
}));
