import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const FireStationMarkerControlStyles = makeStyles((theme: Theme) => ({
    "@keyframes infoIconHoverEffect": {
        "0%": {
            transform: "scale(1)",
        },
        "50%": {
            transform: "scale(1.2)",
        },
        "100%": {
            transform: "scale(1)",
        },
    },
    root: {
        fontFamily: `"ITC Avant Garde", "Helvetica", "Arial", sans-serif`,
        fontSize: "0.75rem",
        minWidth: "14rem",
    },
    header: {
        alignItems: "center",
        backgroundColor: theme.palette.mapPopup.dark,
        color: theme.palette.mapPopup.main,
        fontFamily: "Aptos",
        fontSize: "0.875rem",
        padding: "0.5rem 1.75rem 0.5rem 0.5rem",
    },
    body: {
        backgroundColor: theme.palette.mapPopup.main,
        color: theme.palette.mapPopup.contrastText,
        padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        "& li": {
            marginLeft: "1rem",
        },
    },
    bodyItemContainer: {
        alignItems: "center",
        display: "flex",
        fontFamily: "Aptos",
        "&:not(:last-child)": {
            paddingBottom: "0.25rem",
        },
    },
    bodyItemContainerTitle: {
        fontFamily: "Aptos Bold",
    },
    bodyItemWebsiteItems: {
        alignItems: "center",
        display: "flex",
        "& a": {
            marginRight: "0.5rem",
        },
    },
    marker: {
        // Specifies the marker container
        border: "2px solid #181816",
        borderRadius: "50%",
        backgroundColor: "white",
        paddingBottom: "2px",
        "& > div": {
            // Specifies the marker icon inside the container
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: "100%",
            width: "100%",
        },
    },
    hqMarker: {
        // Specifies the marker container
        border: "2px solid #181816",
        borderRadius: "50%",
        backgroundColor: "white",
        paddingBottom: "1px",
        "& > div": {
            // Specifies the marker icon inside the container
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "90%",
            height: "100%",
            width: "100%",
        },
    },
    infoIcon: {
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        marginLeft: "auto",
        padding: "0",

        "& > div": {
            marginRight: "0 !important",
        },
        "&:focus": {
            animation: "$infoIconHoverEffect 0.75s ease-in-out infinite",
            outline: "none",
        },
        "&:hover": {
            animation: "$infoIconHoverEffect 0.75s ease-in-out infinite",
        },
    },
    informationDialog: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.mapInformationDialog.main,
            border: `2px solid ${theme.palette.mapInformationDialog.dark}`,
            color: theme.palette.mapInformationDialog.contrastText,
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "30rem",
        },
    },
    informationDialogTitle: {
        alignItems: "center",
        display: "flex",
        fontSize: "0.75rem",
        lineHeight: "1.4",
        padding: "0.75rem 1rem",
        "& > div": {
            marginRight: "0.75rem",
        },
        "& button": {
            marginLeft: "auto",
        },
    },
    informationDialogContent: {
        padding: "0rem 1rem 0.75rem 1rem",
        "& .MuiTypography-root": {
            fontSize: "0.75rem",
            lineHeight: "1.4",
            whiteSpace: "pre-line",
        },
    },
}));
