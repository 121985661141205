import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { zIndexHierarchies } from "Custom/StylesAppSpecific/AppStyling";

export const LayoutStyles = makeStyles((theme: Theme) => ({
    layoutContainer: {
        backgroundColor: "white",
        boxShadow: "0 0 16px -8px rgba(0, 0, 0, 1)",
        display: "flex",
        justifyContent: "space-between",
        minHeight: "5rem",
        padding: "0 2rem",
        position: "fixed",
        width: "100%",
        zIndex: zIndexHierarchies.layoutContainer,
        "& > div > a": {
            height: "100%",
            padding: "0.75rem 0",
            width: "16.0625rem",
        },
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            minHeight: "4.25rem",
            padding: "0 1rem",
        },
    },
    layoutCommands: {
        display: "flex",
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        flex: "1",
    },
    header: {
        background: "url(resources/WMFSLogo.svg) no-repeat",
        backgroundPosition: "left center",
        backgroundSize: "contain",
        height: "100%",
        margin: "0 0.625rem 0 0",
        width: "auto",
    },
    headerTitle: {
        fontFamily: "ITC Avant Garde Bold",
        fontSize: "24px",
        flex: "1",
        textAlign: "right",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            display: "none",
        },
    },
    mobileMenuButton: {
        padding: "0",
    },
    mobileDrawer: {
        zIndex: `${zIndexHierarchies.layoutMobileDrawer} !important` as any,
    },
    mobileDrawerOptions: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "4.25rem",
        "& > button": {
            borderBottom: "1px solid black",
            borderRadius: "0",
            padding: "1.25rem 0",
            margin: "0",
        },
    },
}));
