import { AccountStore } from "./Domain/AccountStores";
import { UserStore } from "./Domain/Admin/UserStore";
import { Stores } from "Custom/Stores/index";
// App
import * as Models from "../Models";
import { FormLockConcurrencyHubStore } from "./Domain/FormLockConcurrencyHubStore";
import { observable, action, computed } from "mobx";
import * as History from "history";

export class DomainStores {
    @observable public openSnackBar = false;
    @observable public openErrorDialog = false;
    @observable public errorDialogContent: string = "";
    @observable public snackBarContent: string = "";
    public AccountStore: AccountStore = new AccountStore();
    public UserStore = new UserStore();
    public FormLockConcurrencyHubStore = new FormLockConcurrencyHubStore();
    public History: History.History = {} as History.History;

    public init(stores: Stores, initialState: Models.InitialState) {
        this.AccountStore.init(stores, initialState);
        this.FormLockConcurrencyHubStore.init(stores, initialState);
    }

    @action
    public ShowSnackBar(content: string) {
        this.snackBarContent = content;
        this.openSnackBar = true;
    }

    @action
    public CloseSnackBar() {
        this.openSnackBar = false;
    }

    @action
    public ShowErrorDialog(content: string) {
        this.errorDialogContent = content;
        this.openErrorDialog = true;
    }
}
