import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { defaultTheme } from "Custom/StylesAppSpecific/AppStyling";
import React from "react";
import { hot, setConfig } from "react-hot-loader";
import { Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AppRoutes } from "./AppRoutes";
import { ViewModelBase } from "./Core/ViewModels";
import { Layout } from "./Custom/Views/Layout";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

setConfig({
    showReactDomPatchNotification: false,
});

const App: React.FC = () => {
    //SW google analytics
    //ReactGA.initialize("your code here");

    return (
        <I18nextProvider i18n={i18n}>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={defaultTheme}>
                    <ThemeProvider theme={defaultTheme}>
                        <CssBaseline>
                            <Router history={ViewModelBase.history}>
                                <Layout>
                                    <AppRoutes />
                                </Layout>
                            </Router>
                        </CssBaseline>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </I18nextProvider>
    );
};

export default hot(module)(App);
