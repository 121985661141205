import { action, computed, observable } from "mobx";

import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";
import { FireStationsModel } from "../../../Models/Map/FireStations/FireStationsModel";

export default class FireStationsViewModel extends ViewModelBase<FireStationsModel> {
    constructor() {
        super(new FireStationsModel());

        this.setDecorators(FireStationsModel);
    }

    // #region Properties

    @computed
    public get fireStations() {
        return this.model.fireStations;
    }

    // #endregion Properties

    // #region Actions
    // #endregion Actions

    // #region Computeds
    // #region Computeds

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
