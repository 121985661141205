import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { customColours } from "Custom/StylesAppSpecific/AppStyling";

export const AttendedIncidentMarkerControlStyles = makeStyles((theme: Theme) => ({
    root: {
        fontFamily: `"ITC Avant Garde", "Helvetica", "Arial", sans-serif`,
        fontSize: "0.75rem",
    },
    header: {
        alignItems: "center",
        backgroundColor: theme.palette.mapPopup.dark,
        color: theme.palette.mapPopup.main,
        display: "flex",
        fontFamily: "Aptos",
        fontSize: "0.875rem",
        padding: "0.5rem 1.75rem 0.5rem 0.5rem",
    },
    headerImage: {
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "1rem",
        height: "1rem",
        marginRight: "0.5rem",
        width: "1rem",
    },
    body: {
        backgroundColor: theme.palette.mapPopup.main,
        color: theme.palette.mapPopup.contrastText,
        padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        "& li": {
            marginLeft: "1rem",
        },
    },
    bodyItemContainer: {
        alignItems: "center",
        display: "flex",
        fontFamily: "Aptos",
        "&:not(:last-child)": {
            paddingBottom: "0.25rem",
        },
    },
    bodyItemContainerTitle: {
        fontFamily: "Aptos Bold",
    },
    marker: {
        // Specifies the marker container
        border: `2px solid ${customColours.wmfsDarkGrey}`,
        borderRadius: "50%",
        backgroundColor: "white",
        paddingBottom: "2px",
        "& > div": {
            // Specifies the marker icon inside the container
            backgroundColor: "#b80000",
            maskPosition: "center",
            maskRepeat: "no-repeat",
            maskSize: "80%",
            WebkitMaskPosition: "center",
            WebkitMaskRepeat: "no-repeat",
            WebkitMaskSize: "80%",
            height: "100%",
            width: "100%",
        },
    },
}));
