import { isNullOrUndefined } from "Custom/Utils/Utils";

/**
 * Given a value and a min/max range, this function creates an
 * RGB triplet.
 */
export const getIncidentTypesPaletteColour = (value: number, min: number, max: number, maxCutoff = Number.MAX_VALUE, invert: boolean = false) => {
    if (isNullOrUndefined(value) || value < min || value > max) {
        return "transparent";
    }

    const colourLookUpTable = [rgb(33, 102, 172), rgb(103, 169, 207), rgb(209, 229, 240), rgb(253, 219, 199), rgb(239, 138, 98), rgb(178, 24, 43)];

    const highestIndex = colourLookUpTable.length - 1;
    const localMax = max > maxCutoff ? maxCutoff : max;
    const localValue = value > localMax ? localMax : value;

    let colour = null;

    if (invert) {
        colour = colourLookUpTable[Math.round(((localMax - localValue) / (localMax - min)) * highestIndex)];
    } else {
        colour = colourLookUpTable[Math.round(((localValue - min) / (localMax - min)) * highestIndex)];
    }

    return rgbToString(colour.red, colour.green, colour.blue);
};

/**
 * Given a value and a min/max range, this function creates an
 * RGB triplet.
 */
export const getDemographicsPaletteColour = (value: number, min: number, max: number, maxCutoff = Number.MAX_VALUE, invert: boolean = false) => {
    if (isNullOrUndefined(value) || value < min || value > max) {
        return "transparent";
    }

    const colourLookUpTable = [rgb(197, 27, 125), rgb(233, 163, 201), rgb(253, 224, 239), rgb(230, 245, 208), rgb(161, 215, 106), rgb(77, 146, 33)];

    const highestIndex = colourLookUpTable.length - 1;
    const localMax = max > maxCutoff ? maxCutoff : max;
    const localValue = value > localMax ? localMax : value;

    let colour = null;

    if (invert) {
        colour = colourLookUpTable[Math.round(((localMax - localValue) / (localMax - min)) * highestIndex)];
    } else {
        colour = colourLookUpTable[Math.round(((localValue - min) / (localMax - min)) * highestIndex)];
    }

    return rgbToString(colour.red, colour.green, colour.blue);
};

/**
 * Given a value and a min/max range, this function creates an
 * RGB triplet, based on a rainbow palette.
 */
export const getRainbowPaletteColour = (value: number, min: number, max: number, maxCutoff = Number.MAX_VALUE, invert: boolean = false) => {
    if (isNullOrUndefined(value) || value < min || value > max) {
        return "transparent";
    }

    const blueHue = 240;
    const localMax = max > maxCutoff ? maxCutoff : max;
    const localValue = value > localMax ? localMax : value;

    let hue = 0;

    if (invert) {
        hue = ((localValue - min) / (localMax - min)) * blueHue;
    } else {
        hue = ((localMax - localValue) / (localMax - min)) * blueHue;
    }

    return hslToString(hue, 100, 50);
};

/**
 * Given a value and a min/max range, this function creates an
 * RGB triplet.
 */
export const getRainbowV2PaletteColour = (value: number, min: number, max: number, maxCutoff = Number.MAX_VALUE, invert: boolean = false) => {
    if (isNullOrUndefined(value) || value < min || value > max) {
        return "transparent";
    }

    const colourLookUpTable = [
        rgb(0, 104, 55),
        rgb(26, 152, 80),
        rgb(102, 189, 99),
        rgb(166, 217, 106),
        rgb(217, 239, 139),
        rgb(255, 255, 191),
        rgb(254, 224, 139),
        rgb(253, 174, 97),
        rgb(244, 109, 67),
        rgb(215, 48, 39),
        rgb(165, 0, 38),
    ];

    const highestIndex = colourLookUpTable.length - 1;
    const localMax = max > maxCutoff ? maxCutoff : max;
    const localValue = value > localMax ? localMax : value;

    let colour = null;

    if (invert) {
        colour = colourLookUpTable[Math.round(((localMax - localValue) / (localMax - min)) * highestIndex)];
    } else {
        colour = colourLookUpTable[Math.round(((localValue - min) / (localMax - min)) * highestIndex)];
    }

    return rgbToString(colour.red, colour.green, colour.blue);
};

export const getEthnicityPaletteColour = (asianPopulation: number, blackPopulation: number, mixedPopulation: number, otherPopulation: number, whitePopulation: number, totalPopulation: number) => {
    const ethnicities = Array.from(
        new Map<string, number>([
            ["", 0],
            ["asian", asianPopulation],
            ["black", blackPopulation],
            ["mixed", mixedPopulation],
            ["other", otherPopulation],
            ["white", whitePopulation],
        ]),
    );

    let largestEthnicty = ethnicities[0];

    for (let ethnicity of ethnicities) {
        if (ethnicity[1] > largestEthnicty[1]) {
            largestEthnicty = ethnicity;
        }
    }

    const saturation = largestEthnicty[0] !== ethnicities[0][0] ? (largestEthnicty[1] / totalPopulation) * 100 : 0;

    switch (largestEthnicty[0]) {
        case "white":
            return `hsl(${0}, ${saturation}%, ${50}%)`;

        case "asian":
            return `hsl(${60}, ${saturation}%, ${50}%)`;

        case "black":
            return `hsl(${120}, ${saturation}%, ${50}%)`;

        case "mixed":
            return `hsl(${180}, ${saturation}%, ${50}%)`;

        case "other":
            return `hsl(${240}, ${saturation}%, ${50}%)`;

        default:
            return `hsl(${0}, ${saturation}%, ${0}%)`;
    }
};

export const getEthnicityV2PaletteColour = (asianPopulation: number, blackPopulation: number, mixedPopulation: number, otherPopulation: number, whitePopulation: number, totalPopulation: number) => {
    const ethnicities = Array.from(
        new Map<string, { population: number; hue: number; saturation: number; lightness: number }>([
            ["", { population: 0, hue: 0, saturation: 0, lightness: 0 }],
            ["asian", { population: asianPopulation, hue: 164, saturation: 100, lightness: 31 }],
            ["black", { population: blackPopulation, hue: 56, saturation: 85, lightness: 60 }],
            ["mixed", { population: mixedPopulation, hue: 202, saturation: 100, lightness: 35 }],
            ["other", { population: otherPopulation, hue: 26, saturation: 100, lightness: 42 }],
            ["white", { population: whitePopulation, hue: 327, saturation: 45, lightness: 64 }],
        ]),
    );

    let largestEthnicty = ethnicities[0];

    for (let ethnicity of ethnicities) {
        if (ethnicity[1].population > largestEthnicty[1].population) {
            largestEthnicty = ethnicity;
        }
    }

    const saturation = largestEthnicty[0] !== ethnicities[0][0] ? (largestEthnicty[1].population / totalPopulation) * largestEthnicty[1].saturation : 0;

    return `hsl(${largestEthnicty[1].hue}, ${saturation}%, ${largestEthnicty[1].lightness}%)`;
};

/**
 * Given a value and a min/max range, this function creates
 * an opacity value betweeen 0 and 1.
 */
export const getOpacity = (value: number, min: number, max: number, invert: boolean = false) => {
    let opacity = 1;

    if (invert) {
        opacity = (max - value) / (max - min);
    } else {
        opacity = (value - min) / (max - min);
    }

    return opacity;
};

/**
 * An RGB colour triplet with each part in a range 0 to 255.
 */
export const rgb = (r: number, g: number, b: number) => {
    return {
        red: r,
        green: g,
        blue: b,
    };
};

/**
 * A string representation of an RGB colour triplet.
 */
export const rgbToString = (r: number, g: number, b: number) => {
    return `rgb(${r},${g},${b})`;
};

/**
 * A string representation of an HSL colour triplet.
 */
export const hslToString = (h: number, s: number, l: number) => {
    return `hsl(${h}, ${s}%, ${l}%)`;
};
