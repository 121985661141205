import { Box, ButtonBase, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

// Custom
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";
import { isNullOrUndefined } from "Custom/Utils/Utils";
import NavigationViewModel from "Custom/ViewModels/Map/Navigation/NavigationViewModel";
import { NavigationStyles } from "./NavigationStyles";

interface INavigationProps {
    viewModel: NavigationViewModel;
}

interface IInformationDialog {
    icon: string;
    title: string;
    tooltip: string;
}

export const Navigation: React.FunctionComponent<INavigationProps> = (props) => {
    const [openInformationDialog, setOpenInformationDialog] = React.useState<IInformationDialog | null>(null);
    const styles = NavigationStyles();
    const { t } = useTranslation();

    const getNavigationClassName = (): string => {
        return clsx({
            "vertical-flex-stack": true,
            [styles.root]: true,
        });
    };

    const handleOpenInformationDialog = (icon: string, title: string, tooltip: string) => {
        setOpenInformationDialog({
            icon: icon,
            title: title,
            tooltip: tooltip,
        });
    };

    const handleCloseInformationDialog = () => {
        setOpenInformationDialog(null);
    };

    return useObserver(() => {
        const pluralise = (count: number, single: string, plural: string): string => (count === 1 ? single : plural);

        const onEnterOrSpaceKeyDown = (e: any, onCommand: () => void) => {
            const KEY_ENTER = 13;
            const KEY_SPACE = 32;

            switch (e.which) {
                case KEY_ENTER:
                case KEY_SPACE:
                    onCommand();
                    break;
            }
        };

        const renderIcon = (image: string, invert: boolean) => {
            if (invert) {
                return (
                    <div
                        style={{
                            backgroundColor: `${customColours.wmfsDarkGrey}`,
                            maskImage: `url(${image})`,
                            maskPosition: "center",
                            maskRepeat: "no-repeat",
                            maskSize: "contain",
                            WebkitMaskImage: `url(${image})`,
                            WebkitMaskPosition: "center",
                            WebkitMaskRepeat: "no-repeat",
                            WebkitMaskSize: "contain",
                            height: "1.75rem",
                            width: "1.75rem",
                        }}
                    />
                );
            }

            return (
                <Box
                    style={{
                        backgroundImage: `url(${image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        width: "1.75rem",
                        height: "1.75rem",
                    }}
                />
            );
        };

        const renderAverageResponseTime = () => {
            return (
                <Box
                    role="status"
                    aria-live="polite"
                    aria-atomic="true"
                    className={styles.averageReponseTimeContainer}>
                    <Box
                        aria-hidden="true"
                        className={styles.averageReponseIcon}>
                        <Box
                            className={styles.iconContainer}>
                            {renderIcon(props.viewModel.apiDataViewModel.averageResponseTimeViewModel.model.navigationImage, true)}
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            className={styles.averageReponseTimeDisplayName}>
                            {props.viewModel.apiDataViewModel.averageResponseTimeViewModel.model.displayName}
                        </Box>
                        <Box
                            className={styles.averageReponseTime}>
                            {props.viewModel.apiDataViewModel.averageResponseTimeViewModel.averageResponseTime}
                        </Box>
                    </Box>
                </Box>
            );
        };

        const renderPrevention = () => {
            const onCommand = () => {
                props.viewModel.apiDataViewModel.preventionViewModel.toggleIsExpanded();
            };

            return (
                <Box
                    role="none">
                    <Box
                        aria-expanded={props.viewModel.apiDataViewModel.preventionViewModel.isExpanded}
                        aria-label={`${props.viewModel.apiDataViewModel.preventionViewModel.model.displayName}. ${props.viewModel.apiDataViewModel.preventionViewModel.model.count} ${pluralise(
                            props.viewModel.apiDataViewModel.preventionViewModel.model.count,
                            t("map.navigation.common.visitSingle"),
                            t("map.navigation.common.visitPlural"),
                        )}.`}
                        role="menuitem"
                        className={clsx({
                            [styles.navigationItemSelectable]: true,
                            [styles.navigationItemFirst]: true,
                        })}
                        tabIndex={0}
                        onClick={onCommand}
                        onKeyDown={(e) => onEnterOrSpaceKeyDown(e, onCommand)}>
                        <Box
                            className={styles.countContainerApiTitle}>
                            <Box
                                aria-hidden="true"
                                alignItems="center"
                                display="flex">
                                <Box
                                    className={styles.iconContainer}>
                                    {renderIcon(props.viewModel.apiDataViewModel.preventionViewModel.model.navigationImage, true)}
                                </Box>
                                {props.viewModel.apiDataViewModel.preventionViewModel.model.displayName}
                            </Box>
                            <button
                                aria-haspopup="dialog"
                                aria-label="Display information."
                                className={styles.infoIcon}
                                onClick={(e) => {
                                    handleOpenInformationDialog(
                                        props.viewModel.apiDataViewModel.preventionViewModel.model.navigationImage,
                                        props.viewModel.apiDataViewModel.preventionViewModel.model.displayName,
                                        t("map.navigation.apiData.prevention.tooltip"),
                                    );
                                    e.stopPropagation();
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                                tabIndex={0}>
                                {renderIcon("resources/navigation/info_white_24dp.svg", true)}
                            </button>
                        </Box>
                        <Box
                            aria-hidden="true"
                            className={styles.countContainerApi}>
                            <Box
                                className={styles.count}>
                                {props.viewModel.apiDataViewModel.preventionViewModel.model.count}
                            </Box>
                            <Box
                                className={styles.countDisplayName}>
                                {pluralise(props.viewModel.apiDataViewModel.preventionViewModel.model.count, t("map.navigation.common.visitSingle"), t("map.navigation.common.visitPlural"))}
                            </Box>
                        </Box>
                    </Box>
                    {props.viewModel.apiDataViewModel.preventionViewModel.isExpanded && (
                        <Box
                            aria-label={`${props.viewModel.apiDataViewModel.preventionViewModel.model.displayName}.`}
                            role="menu">
                            {props.viewModel.apiDataViewModel.preventionViewModel.typeViewModels.map((vm) => {
                                return (
                                    <Box
                                        aria-label={`${vm.model.count} ${vm.model.displayName}.`}
                                        role="menuitem"
                                        className={clsx({
                                            [styles.countContainer]: true,
                                            [styles.navigationItemSecond]: true,
                                        })}
                                        key={vm.model.id}
                                        tabIndex={0}>
                                        <Box
                                            aria-hidden="true"
                                            className={styles.iconContainer}>
                                            {renderIcon(vm.model.navigationImage, true)}
                                        </Box>
                                        <Box
                                            aria-hidden="true"
                                            className={styles.count}>
                                            {vm.model.count}
                                        </Box>
                                        <Box
                                            aria-hidden="true"
                                            className={styles.countDisplayName}>
                                            {vm.model.displayName}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            );
        };

        const renderProtection = () => {
            const onCommand = () => {
                props.viewModel.apiDataViewModel.protectionViewModel.toggleIsExpanded();
            };

            return (
                <Box
                    role="none">
                    <Box
                        aria-expanded={props.viewModel.apiDataViewModel.protectionViewModel.isExpanded}
                        aria-label={`${props.viewModel.apiDataViewModel.protectionViewModel.model.displayName}. ${props.viewModel.apiDataViewModel.protectionViewModel.model.count} ${pluralise(
                            props.viewModel.apiDataViewModel.protectionViewModel.model.count,
                            t("map.navigation.common.visitSingle"),
                            t("map.navigation.common.visitPlural"),
                        )}.`}
                        role="menuitem"
                        className={clsx({
                            [styles.navigationItemSelectable]: true,
                            [styles.navigationItemFirst]: true,
                        })}
                        tabIndex={0}
                        onClick={onCommand}
                        onKeyDown={(e) => onEnterOrSpaceKeyDown(e, onCommand)}>
                        <Box
                            className={styles.countContainerApiTitle}>
                            <Box
                                aria-hidden="true"
                                alignItems="center"
                                display="flex">
                                <Box
                                    className={styles.iconContainer}>
                                    {renderIcon(props.viewModel.apiDataViewModel.protectionViewModel.model.navigationImage, true)}
                                </Box>
                                {props.viewModel.apiDataViewModel.protectionViewModel.model.displayName}
                            </Box>
                            <button
                                aria-haspopup="dialog"
                                aria-label="Display information."
                                className={styles.infoIcon}
                                onClick={(e) => {
                                    handleOpenInformationDialog(
                                        props.viewModel.apiDataViewModel.protectionViewModel.model.navigationImage,
                                        props.viewModel.apiDataViewModel.protectionViewModel.model.displayName,
                                        t("map.navigation.apiData.protection.tooltip"),
                                    );
                                    e.stopPropagation();
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                                tabIndex={0}>
                                {renderIcon("resources/navigation/info_white_24dp.svg", true)}
                            </button>
                        </Box>
                        <Box
                            aria-hidden="true"
                            className={styles.countContainerApi}>
                            <Box
                                className={styles.count}>
                                {props.viewModel.apiDataViewModel.protectionViewModel.model.count}
                            </Box>
                            <Box
                                className={styles.countDisplayName}>
                                {pluralise(props.viewModel.apiDataViewModel.protectionViewModel.model.count, t("map.navigation.common.visitSingle"), t("map.navigation.common.visitPlural"))}
                            </Box>
                        </Box>
                    </Box>
                    {props.viewModel.apiDataViewModel.protectionViewModel.isExpanded && (
                        <Box
                            aria-label={`${props.viewModel.apiDataViewModel.protectionViewModel.model.displayName}.`}
                            role="menu">
                            {props.viewModel.apiDataViewModel.protectionViewModel.typeViewModels.map((vm) => {
                                return (
                                    <Box
                                        aria-label={`${vm.model.count} ${vm.model.displayName}.`}
                                        role="menuitem"
                                        className={clsx({
                                            [styles.countContainer]: true,
                                            [styles.navigationItemSecond]: true,
                                        })}
                                        key={vm.model.id}
                                        tabIndex={0}>
                                        <Box
                                            aria-hidden="true"
                                            className={styles.iconContainer}>
                                            {renderIcon(vm.model.navigationImage, true)}
                                        </Box>
                                        <Box
                                            aria-hidden="true"
                                            className={styles.count}>
                                            {vm.model.count}
                                        </Box>
                                        <Box
                                            aria-hidden="true"
                                            className={styles.countDisplayName}>
                                            {vm.model.displayName}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            );
        };

        const renderIncidents = () => {
            const onCommand = () => {
                props.viewModel.selectIncidentDataViewModelId(props.viewModel.apiDataViewModel.incidentsViewModel.model.id);
            };

            return (
                <Box
                    role="none">
                    <Box
                        aria-expanded={props.viewModel.apiDataViewModel.incidentsViewModel.isExpanded}
                        aria-label={`${props.viewModel.apiDataViewModel.incidentsViewModel.model.displayName}. ${props.viewModel.apiDataViewModel.incidentsViewModel.model.count} ${pluralise(
                            props.viewModel.apiDataViewModel.incidentsViewModel.model.count,
                            t("map.navigation.common.incidentSingle"),
                            t("map.navigation.common.incidentPlural"),
                        )}.`}
                        role="menuitem"
                        className={clsx({
                            [styles.navigationItemSelectable]: true,
                            [styles.navigationItemFirst]: true,
                            [styles.navigationItemFirstLevelSelected]: props.viewModel.apiDataViewModel.incidentsViewModel.isSelected, 
                        })}
                        tabIndex={0}
                        onClick={onCommand}
                        onKeyDown={(e) => onEnterOrSpaceKeyDown(e, onCommand)}>
                        <Box
                            className={styles.countContainerApiTitle}>
                            <Box
                                aria-hidden="true"
                                alignItems="center"
                                display="flex">
                                <Box
                                    className={styles.iconContainer}>
                                    {renderIcon(props.viewModel.apiDataViewModel.incidentsViewModel.model.navigationImage, !props.viewModel.apiDataViewModel.incidentsViewModel.isSelected)}
                                </Box>
                                {props.viewModel.apiDataViewModel.incidentsViewModel.model.displayName}
                            </Box>
                            <button
                                aria-haspopup="dialog"
                                aria-label="Display information."
                                className={styles.infoIcon}
                                onClick={(e) => {
                                    handleOpenInformationDialog(
                                        props.viewModel.apiDataViewModel.incidentsViewModel.model.navigationImage,
                                        props.viewModel.apiDataViewModel.incidentsViewModel.model.displayName,
                                        t("map.navigation.apiData.incidents.tooltip"),
                                    );
                                    e.stopPropagation();
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                                tabIndex={0}>
                                {renderIcon("resources/navigation/info_white_24dp.svg", !props.viewModel.apiDataViewModel.incidentsViewModel.isSelected)}
                            </button>
                        </Box>
                        <Box
                            aria-hidden="true"
                            className={styles.countContainerApi}>
                            <Box
                                className={styles.count}>
                                {props.viewModel.apiDataViewModel.incidentsViewModel.model.count}
                            </Box>
                            <Box
                                className={styles.countDisplayName}>
                                {pluralise(props.viewModel.apiDataViewModel.incidentsViewModel.model.count, t("map.navigation.common.incidentSingle"), t("map.navigation.common.incidentPlural"))}
                            </Box>
                        </Box>
                    </Box>
                    {props.viewModel.apiDataViewModel.incidentsViewModel.isExpanded &&
                        <Box
                            aria-label={`${props.viewModel.apiDataViewModel.incidentsViewModel.model.displayName}.`}
                            role="menu">
                            {props.viewModel.apiDataViewModel.incidentsViewModel.typeViewModels.map((vm) => {
                                const onVMCommand = () => {
                                    vm.toggleIsSelected();
                                };

                                return (
                                    <Box>
                                        <Box
                                            aria-checked={vm.isSelected}
                                            role="menuitemcheckbox"
                                            className={clsx({
                                                [styles.countContainer]: true,
                                                [styles.navigationItemSelectable]: true,
                                                [styles.navigationItemSecond]: true,
                                                [styles.navigationItemSecondSelected]: vm.isSelected,
                                            })}
                                            key={vm.model.id}
                                            onClick={onVMCommand}
                                            onKeyDown={(e) => onEnterOrSpaceKeyDown(e, onVMCommand)}
                                            tabIndex={0}>
                                            <Box
                                                aria-hidden="true"
                                                className={styles.iconContainer}>
                                                {renderIcon(vm.model.navigationImage, true)}
                                            </Box>
                                            <Box
                                                className={styles.count}>
                                                {vm.model.count}
                                            </Box>
                                            <Box
                                                className={styles.countDisplayName}>
                                                {vm.model.displayName}
                                                {!isEmptyOrWhitespace(vm.model.tooltip) && (
                                                <button
                                                    aria-haspopup="dialog"
                                                    aria-label="Display information."
                                                    className={styles.infoIcon}
                                                    onClick={(e) => {
                                                        handleOpenInformationDialog(
                                                            vm.model.navigationImage,
                                                            vm.model.displayName,
                                                            vm.model.tooltip);
                                                        e.stopPropagation();
                                                    }}
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                    tabIndex={0}>
                                                    {renderIcon("resources/navigation/info_white_24dp.svg", true)}
                                                </button>
                                            )}

                                            </Box>
                                        </Box>
                                        {vm.isExpanded && (
                                            <Box
                                                aria-label={`${vm.model.displayName}.`}
                                                role="menu">
                                                {vm.typeViewModels.map((innervm) => {
                                                    const onInnerVMCommand = () => {
                                                        innervm.toggleIsSelected();
                                                    };

                                                    return (
                                                        <Box
                                                            aria-checked={innervm.isSelected}
                                                            role="menuitemcheckbox"
                                                            className={clsx({
                                                                [styles.countContainer]: true,
                                                                [styles.navigationItemSelectable]: true,
                                                                [styles.navigationItemThird]: true,
                                                                [styles.navigationItemThirdSelected]: innervm.isSelected,
                                                            })}
                                                            key={innervm.model.id}
                                                            onClick={onInnerVMCommand}
                                                            onKeyDown={(e) => onEnterOrSpaceKeyDown(e, onInnerVMCommand)}
                                                            tabIndex={0}>
                                                            <Box
                                                                aria-hidden="true"
                                                                className={styles.iconContainer}>
                                                                {renderIcon(innervm.model.navigationImage, true)}
                                                            </Box>
                                                            <Box
                                                                className={styles.count}>
                                                                {innervm.model.count}
                                                            </Box>
                                                            <Box
                                                                className={styles.countDisplayName}>
                                                                {innervm.model.displayName}
                                                                {!isEmptyOrWhitespace(innervm.model.tooltip) && (
                                                                <button
                                                                    aria-haspopup="dialog"
                                                                    aria-label="Display information."
                                                                    className={styles.infoIcon}
                                                                    onClick={(e) => {
                                                                        handleOpenInformationDialog(
                                                                            innervm.model.navigationImage,
                                                                            innervm.model.displayName,
                                                                            innervm.model.tooltip);
                                                                        e.stopPropagation();
                                                                    }}
                                                                    onKeyDown={(e) => e.stopPropagation()}
                                                                    tabIndex={0}>
                                                                    {renderIcon("resources/navigation/info_white_24dp.svg", true)}
                                                                </button>
                                                            )}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    }
                </Box>
            );
        };

        const renderStaticData = () => {
            return props.viewModel.staticDataViewModels.map((vm, index) => {
                const onCommand = () => {
                    props.viewModel.selectStaticDataViewModelId(vm.model.id);
                };

                return (
                    <Box
                        role="none">
                        <Box
                            aria-expanded={vm.isExpanded}
                            aria-label={`${vm.model.displayName}.`}
                            role="menuitem"
                            className={clsx({
                                [styles.staticDataContainer]: true,
                                [styles.navigationItemSelectable]: true,
                                [styles.navigationItemFirst]: true,
                                [styles.navigationItemFirstLevelSelected]: vm.isSelected,
                            })}
                            key={vm.model.id}
                            onClick={onCommand}
                            onKeyDown={(e) => onEnterOrSpaceKeyDown(e, onCommand)}
                            tabIndex={0}>
                            <Box
                                aria-hidden="true"
                                className={styles.staticDisplayName}>
                                <Box
                                    className={styles.iconContainer}>
                                    {renderIcon(vm.model.navigationImage, !vm.isSelected)}
                                </Box>
                                {vm.model.displayName}
                            </Box>
                            {!isEmptyOrWhitespace(vm.model.tooltip) && (
                                <button
                                    aria-haspopup="dialog"
                                    aria-label="Display information."
                                    className={styles.infoIcon}
                                    onClick={(e) => {
                                        handleOpenInformationDialog(
                                            vm.model.navigationImage,
                                            vm.model.displayName,
                                            vm.model.tooltip);
                                        e.stopPropagation();
                                    }}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    tabIndex={0}>
                                    {renderIcon("resources/navigation/info_white_24dp.svg", !vm.isSelected)}
                                </button>
                            )}
                        </Box>
                        {vm.isExpanded && (
                            <Box
                                aria-label={`${vm.model.displayName}.`}
                                role="menu">
                                {vm.typeViewModels.map((innervm) => {
                                    const onInnerCommand = () => {
                                        const id = innervm.model.id;
                                        vm.selectTypeViewModelId(id);
                                    };

                                    return (
                                        <Box
                                            aria-checked={innervm.isSelected}
                                            aria-label={`${innervm.model.displayName}.`}
                                            role="menuitemradio"
                                            className={clsx({
                                                [styles.staticDataContainer]: true,
                                                [styles.navigationItemSelectable]: true,
                                                [styles.navigationItemSecond]: true,
                                                [styles.navigationItemSecondSelected]: innervm.isSelected,
                                            })}
                                            key={innervm.model.id}
                                            onClick={onInnerCommand}
                                            onKeyDown={(e) => onEnterOrSpaceKeyDown(e, onInnerCommand)}
                                            tabIndex={0}>
                                            <Box
                                                aria-hidden="true"
                                                className={styles.staticDisplayName}>
                                                <Box
                                                    className={styles.iconContainer}>
                                                    {renderIcon(innervm.model.navigationImage, true)}
                                                </Box>
                                                {innervm.model.displayName}
                                            </Box>
                                            {!isEmptyOrWhitespace(innervm.model.tooltip) && (
                                                <button
                                                    aria-haspopup="dialog"
                                                    aria-label="Display information."
                                                    className={styles.infoIcon}
                                                    onClick={(e) => {
                                                        handleOpenInformationDialog(
                                                            innervm.model.navigationImage,
                                                            innervm.model.displayName,
                                                            innervm.model.tooltip);
                                                        e.stopPropagation();
                                                    }}
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                    tabIndex={0}>
                                                    {renderIcon("resources/navigation/info_white_24dp.svg", true)}
                                                </button>
                                            )}
                                        </Box>
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                );
            });
        };

        const renderShoothillLogo = () => {
            return (
                <div className={styles.footerRow}>
                    <ButtonBase
                        aria-label="Powered by Shoothill"
                        className={styles.shoothillButton}
                        focusRipple={true}
                        href="https://shoothill.com/"
                        target="_blank"
                        title="Powered by Shoothill">
                        <span className={styles.shoothillLogo} />
                    </ButtonBase>
                </div>
            );
        };

        const renderInformationDialog = () => {
            return (
                <Dialog
                    aria-describedby="id_dialog_content"
                    aria-labelledby="id_dialog_title"
                    className={styles.informationDialog}
                    onClose={handleCloseInformationDialog}
                    open={!isNullOrUndefined(openInformationDialog)}>
                    {!isNullOrUndefined(openInformationDialog) && (
                        <React.Fragment>
                            <DialogTitle
                                className={styles.informationDialogTitle}
                                disableTypography>
                                {renderIcon(openInformationDialog!.icon, true)}
                                <Typography
                                    aria-hidden="true"
                                    id="id_dialog_title"
                                    variant="h6">
                                    {openInformationDialog!.title}
                                </Typography>
                                <IconButton
                                    aria-label="Close dialog."
                                    onClick={handleCloseInformationDialog}
                                    size="small">
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent
                                aria-hidden="true"
                                className={styles.informationDialogContent}>
                                <Typography id="id_dialog_content">{openInformationDialog!.tooltip}</Typography>
                            </DialogContent>
                        </React.Fragment>
                    )}
                </Dialog>
            );
        };

        return (
            <React.Fragment>
                <Box
                    className={getNavigationClassName()}>
                    {(() => {
                        switch (props.viewModel.canDisplayApiData) {
                            case true:
                                return (
                                    <React.Fragment>
                                        {renderAverageResponseTime()}
                                        <Box
                                            className="vertical-flex-stack"
                                            aria-label="Map Navigation."
                                            role="menubar">
                                            {renderPrevention()}
                                            {renderProtection()}
                                            {renderIncidents()}
                                            {renderStaticData()}
                                            {renderShoothillLogo()}
                                        </Box>
                                    </React.Fragment>
                                );

                            case false:
                                return (
                                    <React.Fragment>
                                        <Box
                                            className="vertical-flex-stack"
                                            aria-label="Map Navigation."
                                            role="menubar">
                                            {renderStaticData()}
                                            {renderShoothillLogo()}
                                        </Box>
                                    </React.Fragment>
                                );
                        }
                    })()}
                </Box>
                {renderInformationDialog()}
            </React.Fragment>
        );
    });
};
