// Core
import { ModelBase } from "Core/Models/ModelBase";
import { IncidentClassification, IncidentGroupClassification } from "Custom/Utils/Enums";

export class AttendedIncidentDataPointModel extends ModelBase<AttendedIncidentDataPointModel, IncidentDataPointRequestDto> {
    constructor() {
        super();
    }

    public KEY = "";

    public latitiude = 0;

    public longitude = 0;

    public incidentClassification: IncidentClassification = IncidentClassification.Unknown;

    public callTime: Date | null = null;

    public firstOnSceneReactionSeconds = 0;

    public firstOnSceneTravelSeconds = 0;

    public firstOnSceneBrigadeLocation = "";

    public fromDto(dto: IncidentDataPointRequestDto): void {
        this.KEY = dto.vis_inc_num;
        this.latitiude = parseFloat(dto.location_latitude);
        this.longitude = parseFloat(dto.location_longitude);
        this.incidentClassification = dto.incident_classification as IncidentClassification;
        this.callTime = new Date(dto.call_time);
        this.firstOnSceneReactionSeconds = dto.first_on_scene_reaction_seconds;
        this.firstOnSceneTravelSeconds = dto.first_on_scene_travel_seconds;
        this.firstOnSceneBrigadeLocation = dto.first_on_scene_brigade_location;
    }

    public toDto(): IncidentDataPointRequestDto {
        throw new Error("Method not implemented.");
    }

    // #region Response Time Properties

    public get averageResponseTimeSeconds() {
        return this.firstOnSceneReactionSeconds + this.firstOnSceneTravelSeconds;
    }

    // #endregion Response Time Properties

    // #region Incident Group Properties

    public get isFalseAlarmIncidentGroup() {
        return this.incidentClassification.startsWith(IncidentGroupClassification.FalseAlarm);
    }

    public get isFireIncidentGroup() {
        return this.incidentClassification.startsWith(IncidentGroupClassification.Fire);
    }

    public get isRoadTrafficCollisionIncidentGroup() {
        return this.incidentClassification.startsWith(IncidentGroupClassification.RoadTrafficCollision);
    }

    public get isSpecialServiceCallIncidentGroup() {
        return this.incidentClassification.startsWith(IncidentGroupClassification.SpecialServiceCall);
    }

    // #endregion Incident Group Properties

    // #region Fire Incident Group Properties

    public get isHouseFire() {
        switch (this.incidentClassification) {
            case IncidentClassification.FireDwellingAccidental:
            case IncidentClassification.FireDwellingArson:
                return true;

            default:
                return false;
        }
    }

    public get isOtherPropertyFire() {
        switch (this.incidentClassification) {
            case IncidentClassification.FireNonDomesticAccidental:
            case IncidentClassification.FireNonDomesticArson:
            case IncidentClassification.FireOtherAccidental:
            case IncidentClassification.FireOtherArson:
                return true;

            default:
                return false;
        }
    }

    public get isVehicleFire() {
        switch (this.incidentClassification) {
            case IncidentClassification.FireVehicle:
            case IncidentClassification.FireVehicleAccidental:
            case IncidentClassification.FireVehicleArson:
                return true;

            default:
                return false;
        }
    }

    public get isOutdoorFire() {
        switch (this.incidentClassification) {
            case IncidentClassification.FireDerelictBuildingAccidental:
            case IncidentClassification.FireDerelictBuildingArson:
            case IncidentClassification.FireDerelictVehicleAccidental:
            case IncidentClassification.FireDerelictVehicleArson:
            case IncidentClassification.FireRubbishAccidental:
            case IncidentClassification.FireRubbishArson:
            case IncidentClassification.FireSec:
            case IncidentClassification.FireSecondaryAccidental:
            case IncidentClassification.FireSecondaryArson:
                return true;

            default:
                return false;
        }
    }

    public get isOutlierFire() {
        switch (this.incidentClassification) {
            case IncidentClassification.FireChimney:
                return true;

            default:
                return false;
        }
    }

    // #endregion Fire Incident Group Properties
}

export interface IncidentDataPointRequestDto {
    vis_inc_num: string;
    location_latitude: string;
    location_longitude: string;
    incident_classification: string;
    call_time: string;
    first_on_scene_reaction_seconds: number;
    first_on_scene_travel_seconds: number;
    first_on_scene_brigade_location: string;
}
