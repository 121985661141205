// Libraries
import L, { LatLngExpression } from "leaflet";
import React from "react";
import { Marker, Popup } from "react-leaflet";
import { useTranslation } from "react-i18next";

import { AttendedIncidentDataPointModel } from "Custom/Models/Map/Data/AttendedIncidentDataPointModel";
import { customColours } from "Custom/StylesAppSpecific/AppStyling";
import { formatDateV2, formatTime, formatVerboseSeconds } from "Custom/Utils/Utils";
import { AttendedIncidentMarkerControlStyles } from "./AttendedIncidentMarkerControlStyles";

interface IProps {
    model: AttendedIncidentDataPointModel;
    zoom: number;
}

export const AttendedIncidentMarkerControl: React.FC<IProps> = (props: IProps) => {
    // #region Code Behind

    const { t } = useTranslation();
    const popupStyles = AttendedIncidentMarkerControlStyles();

    const iconType = () => {
        switch (true) {
            case props.model.isHouseFire:
                return "resources/navigation/home_white_24dp.svg";

            case props.model.isOtherPropertyFire:
                return "resources/navigation/factory_white_24dp.svg";

            case props.model.isOutdoorFire:
                return "resources/navigation/park_white_24dp.svg";

            case props.model.isVehicleFire:
                return "resources/navigation/directions_car_white_24dp.svg";

            case props.model.isOutlierFire:
                return "resources/navigation/other_houses_white_24dp.svg";

            case props.model.isRoadTrafficCollisionIncidentGroup:
                return "resources/navigation/car_crash_white_24dp.svg";

            case props.model.isSpecialServiceCallIncidentGroup:
                return "resources/navigation/fire_truck_white_24dp.svg";

            case props.model.isFalseAlarmIncidentGroup:
                return "resources/navigation/notifications_off_white_24dp.svg";

            default:
                return "";
        }
    };

    const icon = () => {
        switch (true) {
            case props.zoom < 12: // greater than 5 miles
                return L.divIcon({
                    className: popupStyles.marker,
                    html: "",
                    iconSize: [17, 17],
                    iconAnchor: [9, 9],
                    popupAnchor: [-1, -7],
                });

            default: // 5 miles or less
                return L.divIcon({
                    className: popupStyles.marker,
                    html: `<div style="mask-image: url(${iconType()}); -webkit-mask-image: url(${iconType()})"/>`,
                    iconSize: [33, 33],
                    iconAnchor: [17, 17],
                    popupAnchor: [-2, -15],
                });
        }
    };

    const incidentType = () => {
        switch (true) {
            case props.model.isHouseFire:
                return t("map.popup.incidents.houseFire");

            case props.model.isOtherPropertyFire:
                return t("map.popup.incidents.otherPropertyFire");

            case props.model.isOutdoorFire:
                return t("map.popup.incidents.outdoorFire");

            case props.model.isVehicleFire:
                return t("map.popup.incidents.vehicleFire");

            case props.model.isOutlierFire:
                return t("map.popup.incidents.otherFire");

            case props.model.isRoadTrafficCollisionIncidentGroup:
                return t("map.popup.incidents.roadTrafficCollision");

            case props.model.isSpecialServiceCallIncidentGroup:
                return t("map.popup.incidents.specialServiceCall");

            case props.model.isFalseAlarmIncidentGroup:
                return t("map.popup.incidents.falseAlarm");

            default:
                return t("map.popup.other.other");
        }
    };

    const center: LatLngExpression = {
        lat: props.model.latitiude,
        lng: props.model.longitude,
    };

    const renderIcon = (image: string) => {
        return (
            <div
                style={{
                    backgroundColor: `${customColours.wmfsDarkGrey}`,
                    maskImage: `url(${image})`,
                    maskPosition: "center",
                    maskRepeat: "no-repeat",
                    maskSize: "contain",
                    WebkitMaskImage: `url(${image})`,
                    WebkitMaskPosition: "center",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskSize: "contain",
                    height: "1.75rem",
                    width: "1.75rem",
                    marginRight: "0.5rem",
                }}
            />
        );
    };

    // #endregion Code Behind

    /**
     * TODO: Replace with localisation strings.
     */
    const renderPopup = () => {
        return (
            <Popup className={popupStyles.root}>
                <div className={popupStyles.header}>
                    <div
                        className={popupStyles.headerImage}
                        style={{ backgroundImage: `url(${iconType()})` }}
                    />
                    {incidentType()}
                </div>
                <div className={popupStyles.body}>
                    <div>
                        <div className={popupStyles.bodyItemContainer}>
                            <div>
                                <div>{renderIcon("resources/navigation/event_white_24dp.svg")}</div>
                            </div>
                            <div>
                                <div className={popupStyles.bodyItemContainerTitle}>Call time</div>
                                <div>
                                    {formatTime(props.model.callTime!)} on {formatDateV2(props.model.callTime!)}
                                </div>
                            </div>
                        </div>
                        <div className={popupStyles.bodyItemContainer}>
                            <div>
                                <div>{renderIcon("resources/navigation/schedule_white_24dp.svg")}</div>
                            </div>
                            <div>
                                <div className={popupStyles.bodyItemContainerTitle}>Response time</div>
                                <div>{formatVerboseSeconds(props.model.averageResponseTimeSeconds)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    };

    return (
        <Marker
            keyboard={false}
            position={center}
            icon={icon()}
            interactive={true}>
            {renderPopup()}
        </Marker>
    );
};
