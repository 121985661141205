import i18next from "i18next";
import { action, observable } from "mobx";

// Custom
import { DataModel } from "../Data/DataModel";

export class NavigationModel {
    public apiData: NavigationApiData = new NavigationApiData();
    public staticData: NavigationStaticData[] = [];

    @observable
    public haveData = false;

    constructor() {
        // Nothing to do to location.

        // Average response time.
        this.apiData.averageResponseTime.displayName = i18next.t("map.navigation.apiData.averageResponseTime");
        this.apiData.averageResponseTime.navigationImage = "resources/navigation/schedule_white_24dp.svg";

        // Prevention.
        this.apiData.prevention.id = "id_prevention";
        this.apiData.prevention.displayName = i18next.t("map.navigation.apiData.prevention.displayName");
        this.apiData.prevention.canExpand = true;
        this.apiData.prevention.navigationImage = "resources/navigation/front_hand_white_24dp.svg";

        this.apiData.prevention.types = [
            {
                id: "id_safeandwell",
                displayName: i18next.t("map.navigation.apiData.prevention.safeAndWell.displayName"),
                subDisplayName: "",
                tooltip: "",
                canExpand: false,
                canSelect: false,
                navigationImage: "resources/navigation/family_restroom_white_24dp.svg",
                count: 0,
                types: [],
            },
        ];

        // Protection.
        this.apiData.protection.id = "id_protection";
        this.apiData.protection.displayName = i18next.t("map.navigation.apiData.protection.displayName");
        this.apiData.protection.canExpand = true;
        this.apiData.protection.navigationImage = "resources/navigation/shield_white_24dp.svg";

        this.apiData.protection.types = [
            {
                id: "id_safeandstrong",
                displayName: i18next.t("map.navigation.apiData.protection.safeAndStrong.displayName"),
                subDisplayName: "",
                tooltip: "",
                canExpand: false,
                canSelect: false,
                navigationImage: "resources/navigation/store_white_24dp.svg",
                count: 0,
                types: [],
            },
            /* APM - This is disabled for now as the WMFS API does not support this.
               The feature has also been disabled in the DataModel file.
            {
                id: "id_firesafetyaudits",
                displayName: i18next.t("map.navigation.apiData.protection.fireSafetyAudits.displayName"),
                subDisplayName: "",
                tooltip: "",
                canExpand: false,
                canSelect: false,
                navigationImage: "resources/navigation/fact_check_white_24dp.svg",
                count: 0,
                types: [],
            },
            */
        ];

        // Incidents.
        this.apiData.incidents.id = "id_incidents";
        this.apiData.incidents.displayName = i18next.t("map.navigation.apiData.incidents.displayName");
        this.apiData.incidents.canExpand = true;
        this.apiData.incidents.canSelect = true;
        this.apiData.incidents.navigationImage = "resources/navigation/fire_truck_white_24dp.svg";

        this.apiData.incidents.types = [
            {
                id: "id_fires",
                displayName: i18next.t("map.navigation.apiData.incidents.fires.displayName"),
                subDisplayName: "",
                tooltip: "",
                canExpand: true,
                canSelect: true,
                navigationImage: "resources/navigation/local_fire_department_white_24dp.svg",
                count: 0,
                types: [
                    {
                        id: "id_housefires",
                        displayName: i18next.t("map.navigation.apiData.incidents.houseFires.displayName"),
                        subDisplayName: "",
                        tooltip: "",
                        canExpand: false,
                        canSelect: true,
                        navigationImage: "resources/navigation/home_white_24dp.svg",
                        count: 0,
                        types: [],
                    },
                    {
                        id: "id_otherpropertyfires",
                        displayName: i18next.t("map.navigation.apiData.incidents.otherPropertyFires.displayName"),
                        subDisplayName: "",
                        tooltip: "",
                        canExpand: false,
                        canSelect: true,
                        navigationImage: "resources/navigation/factory_white_24dp.svg",
                        count: 0,
                        types: [],
                    },
                    {
                        id: "id_outdoorfires",
                        displayName: i18next.t("map.navigation.apiData.incidents.outdoorFires.displayName"),
                        subDisplayName: "",
                        tooltip: "",
                        canExpand: false,
                        canSelect: true,
                        navigationImage: "resources/navigation/park_white_24dp.svg",
                        count: 0,
                        types: [],
                    },
                    {
                        id: "id_vehiclefires",
                        displayName: i18next.t("map.navigation.apiData.incidents.vehicleFires.displayName"),
                        subDisplayName: "",
                        tooltip: "",
                        canExpand: false,
                        canSelect: true,
                        navigationImage: "resources/navigation/directions_car_white_24dp.svg",
                        count: 0,
                        types: [],
                    },
                    {
                        id: "id_otherfires",
                        displayName: i18next.t("map.navigation.apiData.incidents.otherFires.displayName"),
                        subDisplayName: "",
                        tooltip: "",
                        canExpand: false,
                        canSelect: true,
                        navigationImage: "resources/navigation/other_houses_white_24dp.svg",
                        count: 0,
                        types: [],
                    },
                ],
            },
            {
                id: "id_roadtrafficcollisions",
                displayName: i18next.t("map.navigation.apiData.incidents.roadTrafficCollisions.displayName"),
                subDisplayName: "",
                tooltip: "",
                canExpand: false,
                canSelect: true,
                navigationImage: "resources/navigation/car_crash_white_24dp.svg",
                count: 0,
                types: [],
            },
            {
                id: "id_specialservicecalls",
                displayName: i18next.t("map.navigation.apiData.incidents.specialServiceCalls.displayName"),
                subDisplayName: "",
                tooltip: i18next.t("map.navigation.apiData.incidents.specialServiceCalls.tooltip"),
                canExpand: false,
                canSelect: true,
                navigationImage: "resources/navigation/fire_truck_white_24dp.svg",
                count: 0,
                types: [],
            },
            {
                id: "id_falsealarms",
                displayName: i18next.t("map.navigation.apiData.incidents.falseAlarms.displayName"),
                subDisplayName: "",
                tooltip: "",
                canExpand: false,
                canSelect: true,
                navigationImage: "resources/navigation/notifications_off_white_24dp.svg",
                count: 0,
                types: [],
            },
        ];

        // Static Data.
        this.staticData.push(
            {
                id: "id_demographics",
                displayName: i18next.t("map.navigation.staticData.demographics.displayName"),
                subDisplayName: "",
                tooltip: i18next.t("map.navigation.staticData.demographics.tooltip"),
                canExpand: true,
                canSelect: true,
                navigationImage: "resources/navigation/face_white_24dp.svg",
                types: [
                    {
                        id: "id_population65andover",
                        displayName: i18next.t("map.navigation.staticData.demographics.populationAged65AndOver.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.demographics.populationAged65AndOver.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/elderly_woman_white_24dp.svg",
                        min: {
                            displayName: i18next.t("map.legend.demographics.populationAged65AndOver.less"),
                            count: 6,
                        },
                        max: {
                            displayName: i18next.t("map.legend.demographics.populationAged65AndOver.more"),
                            count: 625,
                        },
                        types: [],
                    },
                    {
                        id: "id_households1person",
                        displayName: i18next.t("map.navigation.staticData.demographics.householdsWithOnePerson.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.demographics.householdsWithOnePerson.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/person_white_24dp.svg",
                        min: {
                            displayName: i18next.t("map.legend.demographics.householdsWithOnePerson.less"),
                            count: 43,
                        },
                        max: {
                            displayName: i18next.t("map.legend.demographics.householdsWithOnePerson.more"),
                            count: 701,
                        },
                        types: [],
                    },
                    {
                        id: "id_householdsinsocialhousing",
                        displayName: i18next.t("map.navigation.staticData.demographics.householdsSociallyRenting.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.demographics.householdsSociallyRenting.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/other_houses_white_24dp.svg",
                        min: {
                            displayName: i18next.t("map.legend.demographics.householdsSociallyRenting.less"),
                            count: 1,
                        },
                        max: {
                            displayName: i18next.t("map.legend.demographics.householdsSociallyRenting.more"),
                            count: 1023,
                        },
                        types: [],
                    },
                    {
                        id: "id_largestethnicgroup",
                        displayName: i18next.t("map.navigation.staticData.demographics.ethnicity.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.demographics.ethnicity.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/diversity_3_white_24dp.svg",
                        min: {
                            displayName: "",
                            count: 1012,
                        },
                        max: {
                            displayName: "",
                            count: 4965,
                        },
                        types: [],
                    },
                    {
                        id: "id_imdranking",
                        displayName: i18next.t("map.navigation.staticData.demographics.imdRanking.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.demographics.imdRanking.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/bar_chart_white_24dp.svg",
                        min: {
                            displayName: i18next.t("map.legend.demographics.imdRanking.more"),
                            count: 1,
                        },
                        max: {
                            displayName: i18next.t("map.legend.demographics.imdRanking.less"),
                            count: 1680,
                        },
                        types: [],
                    },
                    {
                        id: "id_crimeranking",
                        displayName: i18next.t("map.navigation.staticData.demographics.imdCrimeRanking.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.demographics.imdCrimeRanking.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/bar_chart_white_24dp.svg",
                        min: {
                            displayName: i18next.t("map.legend.demographics.imdCrimeRanking.more"),
                            count: 1,
                        },
                        max: {
                            displayName: i18next.t("map.legend.demographics.imdCrimeRanking.less"),
                            count: 1680,
                        },
                        types: [],
                    },
                    {
                        id: "id_antisocialbehaviour",
                        displayName: i18next.t("map.navigation.staticData.demographics.antiSocialBehaviourRanking.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.demographics.antiSocialBehaviourRanking.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/bar_chart_white_24dp.svg",
                        min: {
                            displayName: i18next.t("map.legend.demographics.antiSocialBehaviourRanking.more"),
                            count: 1,
                        },
                        max: {
                            displayName: i18next.t("map.legend.demographics.antiSocialBehaviourRanking.less"),
                            count: 1658,
                        },
                        types: [],
                    },
                    {
                        id: "id_unemploymentranking",
                        displayName: i18next.t("map.navigation.staticData.demographics.imdUnemploymentRanking.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.demographics.imdUnemploymentRanking.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/bar_chart_white_24dp.svg",
                        min: {
                            displayName: i18next.t("map.legend.demographics.imdUnemploymentRanking.more"),
                            count: 1,
                        },
                        max: {
                            displayName: i18next.t("map.legend.demographics.imdUnemploymentRanking.less"),
                            count: 1680,
                        },
                        types: [],
                    },
                ],
            },
            {
                id: "id_risks",
                displayName: i18next.t("map.navigation.staticData.risks.displayName"),
                subDisplayName: "",
                tooltip: i18next.t("map.navigation.staticData.risks.tooltip"),
                canExpand: true,
                canSelect: true,
                navigationImage: "resources/navigation/query_stats_white_24dp.svg",
                types: [
                    {
                        id: "id_floodrisk",
                        displayName: i18next.t("map.navigation.staticData.risks.floodRisk.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.risks.floodRisk.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/water_white_24dp.svg",
                        min: {
                            displayName: "",
                            count: 1,
                        },
                        max: {
                            displayName: "",
                            count: 5,
                        },
                        types: [],
                    },
                    {
                        id: "id_housefires",
                        displayName: i18next.t("map.navigation.staticData.risks.houseFires.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.risks.houseFires.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/home_white_24dp.svg",
                        min: {
                            displayName: "",
                            count: 1,
                        },
                        max: {
                            displayName: "",
                            count: 5,
                        },
                        types: [],
                    },
                    {
                        id: "id_otherpropertyfires",
                        displayName: i18next.t("map.navigation.staticData.risks.otherPropertyFires.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.risks.otherPropertyFires.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/factory_white_24dp.svg",
                        min: {
                            displayName: "",
                            count: 1,
                        },
                        max: {
                            displayName: "",
                            count: 5,
                        },
                        types: [],
                    },
                    {
                        id: "id_outdoorfires",
                        displayName: i18next.t("map.navigation.staticData.risks.outdoorFires.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.risks.outdoorFires.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/park_white_24dp.svg",
                        min: {
                            displayName: "",
                            count: 1,
                        },
                        max: {
                            displayName: "",
                            count: 5,
                        },
                        types: [],
                    },
                    {
                        id: "id_vehiclefires",
                        displayName: i18next.t("map.navigation.staticData.risks.vehicleFires.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.risks.vehicleFires.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/directions_car_white_24dp.svg",
                        min: {
                            displayName: "",
                            count: 1,
                        },
                        max: {
                            displayName: "",
                            count: 5,
                        },
                        types: [],
                    },
                    {
                        id: "id_roadtrafficcollisions",
                        displayName: i18next.t("map.navigation.staticData.risks.roadTrafficCollisions.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.risks.roadTrafficCollisions.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/car_crash_white_24dp.svg",
                        min: {
                            displayName: "",
                            count: 1,
                        },
                        max: {
                            displayName: "",
                            count: 5,
                        },
                        types: [],
                    },
                    {
                        id: "id_specialservicedomestic",
                        displayName: i18next.t("map.navigation.staticData.risks.specialservicedomestic.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.risks.specialservicedomestic.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/fire_truck_white_24dp.svg",
                        min: {
                            displayName: "",
                            count: 1,
                        },
                        max: {
                            displayName: "",
                            count: 5,
                        },
                        types: [],
                    },
                ],
            },
            {
                id: "id_other",
                displayName: i18next.t("map.navigation.staticData.other.displayName"),
                subDisplayName: "",
                tooltip: i18next.t("map.navigation.staticData.other.tooltip"),
                canExpand: true,
                canSelect: true,
                navigationImage: "resources/navigation/location_city_white_24dp.svg",
                types: [
                    {
                        id: "id_listedbuildings",
                        displayName: i18next.t("map.navigation.staticData.other.listedBuildings.displayName"),
                        subDisplayName: "",
                        tooltip: i18next.t("map.navigation.staticData.other.listedBuildings.tooltip"),
                        canSelect: true,
                        navigationImage: "resources/navigation/castle_white_24dp.svg",
                        min: {
                            displayName: "",
                            count: 0,
                        },
                        max: {
                            displayName: "",
                            count: 0,
                        },
                        types: [],
                    },
                ],
            },
        );
    }

    @action
    public fromDataModel(dataModel: DataModel) {
        // Have Data
        this.haveData = true;

        // Average Response Time
        this.apiData.averageResponseTime.count = dataModel.averageResponseTime;

        // Prevention
        this.apiData.prevention.count = dataModel.preventionCount;

        const safeAndWellType = this.apiData.prevention.types.find((t) => t.id === "id_safeandwell");

        if (safeAndWellType) {
            safeAndWellType.count = dataModel.preventionSafeAndWell;
        }

        // Protection
        const safeAndStrongeType = this.apiData.protection.types.find((t) => t.id === "id_safeandstrong");

        if (safeAndStrongeType) {
            safeAndStrongeType.count = dataModel.protectionSafeAndStrong;
        }

        this.apiData.protection.count = dataModel.protectionCount;

        const fireSafeyAuditsType = this.apiData.protection.types.find((t) => t.id === "id_firesafetyaudits");

        if (fireSafeyAuditsType) {
            fireSafeyAuditsType.count = dataModel.fireSafetyAudits;
        }

        // Incidents
        this.apiData.incidents.count = dataModel.attendedIncidentsCount;

        // Incidents: False Alarms
        const falseAlarmsType = this.apiData.incidents.types.find((t) => t.id === "id_falsealarms");

        if (falseAlarmsType) {
            falseAlarmsType.count = dataModel.falseAlarmIncidentGroupCount;
        }

        // Incidents: Fires
        const firesType = this.apiData.incidents.types.find((t) => t.id === "id_fires");

        if (firesType) {
            firesType.count = dataModel.fireIncidentGroupCount;

            // Fires: Houses
            const houseFiresType = firesType.types.find((t) => t.id === "id_housefires");

            if (houseFiresType) {
                houseFiresType.count = dataModel.houseFiresCount;
            }

            // Fires: Other Properties
            const otherPropertyFiresType = firesType.types.find((t) => t.id === "id_otherpropertyfires");

            if (otherPropertyFiresType) {
                otherPropertyFiresType.count = dataModel.otherPropertyFiresCount;
            }

            // Fires: Outdoor
            const outdoorFiresType = firesType.types.find((t) => t.id === "id_outdoorfires");

            if (outdoorFiresType) {
                outdoorFiresType.count = dataModel.outdoorFiresCount;
            }

            // Fires: Vehicles
            const vehicleFiresType = firesType.types.find((t) => t.id === "id_vehiclefires");

            if (vehicleFiresType) {
                vehicleFiresType.count = dataModel.vehicleFiresCount;
            }

            // Fires: Other/Outlier (that don't fit the above categories)
            const otherFiresType = firesType.types.find((t) => t.id === "id_otherfires");

            if (otherFiresType) {
                otherFiresType.count = dataModel.otherFiresCount;
            }
        }

        // Incidents: Road Traffic Collisions
        const roadTrafficCollisionsType = this.apiData.incidents.types.find((t) => t.id === "id_roadtrafficcollisions");

        if (roadTrafficCollisionsType) {
            roadTrafficCollisionsType.count = dataModel.roadTrafficCollisionGroupCount;
        }

        // // Incidents: Special Service Calls
        const specialServiceCallsType = this.apiData.incidents.types.find((t) => t.id === "id_specialservicecalls");

        if (specialServiceCallsType) {
            specialServiceCallsType.count = dataModel.specialServiceCallIncidentGroupCount;
        }
    }
}

// #region API Data Classes

export class NavigationApiData {
    public averageResponseTime: AverageResponseTime = new AverageResponseTime();
    public prevention: NavigationPrevention = new NavigationPrevention();
    public protection: NavigationProtection = new NavigationProtection();
    public incidents: NavigationIncidents = new NavigationIncidents();
}

export class NavigationPrevention {
    public id: string = "";
    public displayName: string = "";
    public subDisplayName: string = "";
    public canExpand: boolean = false;
    public types: NavigationCount[] = [];
    public navigationImage: string = "";

    /**
     * The number of prevention items. Will change with each API call.
     */
    @observable
    public count: number = 0;
}

export class NavigationProtection {
    public id: string = "";
    public displayName: string = "";
    public subDisplayName: string = "";
    public canExpand: boolean = false;
    public types: NavigationCount[] = [];
    public navigationImage: string = "";

    /**
     * The number of protection items. Will change with each API call.
     */
    @observable
    public count: number = 0;
}

export class NavigationIncidents {
    public id: string = "";
    public displayName: string = "";
    public subDisplayName: string = "";
    public canExpand: boolean = false;
    public canSelect: boolean = false;
    public types: NavigationCount[] = [];
    public navigationImage: string = "";

    /**
     * The number of incidents. Will change with each API call.
     */
    @observable
    public count: number = 0;
}

export class NavigationCount {
    public id: string = "";
    public displayName: string = "";
    public subDisplayName: string = "";
    public tooltip: string = "";
    public canExpand: boolean = false;
    public canSelect: boolean = false;
    public navigationImage: string = "";
    public types: NavigationCount[] = [];

    /**
     * The number of checks. A subset of parent. Will change with each API call.
     */
    @observable
    public count: number = 0;
}

export class AverageResponseTime {
    public displayName: string = "";
    public subDisplayName: string = "";
    public navigationImage: string = "";

    /**
     * The number of seconds. Will change with each API call.
     */
    @observable
    public count: number = 0;
}

// #endregion API Data Classes

// #region Static Data Classes

export class NavigationStaticData {
    public id: string = "";
    public displayName: string = "";
    public subDisplayName: string = "";
    public tooltip: string = "";
    public canExpand: boolean = false;
    public canSelect: boolean = false;
    public navigationImage: string = "";
    public types: NavigationMinMax[] = [];
}

export class NavigationMinMax {
    public id: string = "";
    public displayName: string = "";
    public subDisplayName: string = "";
    public tooltip: string = "";
    public canSelect: boolean = false;
    public navigationImage: string = "";
    public min: MinMax = new MinMax();
    public max: MinMax = new MinMax();
    public types: NavigationMinMax[] = [];
}

export class MinMax {
    public displayName: string = "";
    public count: number = 0;
}

// #endregion Static Data Classes
