import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const ZoomControlStyles = makeStyles((theme: Theme) => ({
    root: {
        alignItems: "center",
        display: "flex",
        padding: "0.5rem 0",
    },
    lhslabel: {
        fontSize: "0.875rem",
        marginRight: "1.5rem",
    },
    rhslabel: {
        fontSize: "0.875rem",
        marginLeft: "1.5rem",
    },
}));
