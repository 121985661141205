import i18next from "i18next";
import { debounce } from "lodash";
import { action, computed, observable, runInAction } from "mobx";

// Core
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { Server } from "Custom/Globals/AppUrls";

// Custom
import { MapModel } from "Custom/Models/Map/MapModel";
import DataViewModel from "./DataViewModel";
import FireStationsViewModel from "./FireStations/FireStationsViewModel";
import NavigationViewModel, { NavigationAreaViewModel } from "./Navigation/NavigationViewModel";

export class MapViewModel extends ViewModelBase<MapModel> {
    constructor() {
        super(new MapModel());

        // Search Period Options.
        this.model.searchPeriods.push({
            displayName: i18next.t("map.searchPeriod.last7Days"),
            value: 7,
        });
        this.model.searchPeriods.push({
            displayName: i18next.t("map.searchPeriod.last30Days"),
            value: 30,
        });
        this.model.searchPeriods.push({
            displayName: i18next.t("map.searchPeriod.last90Days"),
            value: 90,
        });
        this.model.searchPeriods.push({
            displayName: i18next.t("map.searchPeriod.last180Days"),
            value: 180,
        });

        // Search Period Option Selection.
        this.model.searchPeriod = this.model.searchPeriods[0];

        // Get Fire Station Data.
        this.getAllFireStations();
    }

    // #region Properties

    @observable
    public canDisplaySidebar: boolean = true;

    @observable
    public navigationViewModel: NavigationViewModel = new NavigationViewModel();

    @observable
    public dataViewModel: DataViewModel = new DataViewModel();

    @observable
    public fireStationsViewModel: FireStationsViewModel = new FireStationsViewModel();

    public get searchPeriods() {
        return this.model.searchPeriods.slice();
    }

    // #endregion Properties

    // #region Actions

    @action
    public setCanDisplaySidebar = () => {
        this.canDisplaySidebar = !this.canDisplaySidebar;

        // We need to do this to force the map to re-render. Otherwise
        // you will have missing tiles on the RHS.
        setTimeout(function () {
            window.dispatchEvent(new Event("resize"));
        }, 0);
    };

    @action
    public setMapCenter = (latitude: number, longitude: number) => {
        this.model.latitude = latitude;
        this.model.longitude = longitude;
    };

    @action
    public setMapZoom = (zoom: number) => {
        this.model.zoom = zoom;
    };

    @action setMapRangeMiles = (mapRangeMiles: number) => {
        this.model.searchRangeMiles = mapRangeMiles;

        // Side effect. Call API.
        // A change to the to the search radius, so
        // call the API for fresh data.
        this.onMapChange();
    };

    @action
    public setSearchAddress = (address: string, isValid: boolean, latitude: number = 0.0, longitude: number = 0.0, postcode = MapModel.DEFAULT_POSTCODEADDRESS) => {
        this.model.searchAddress = address;
        this.model.isAddressValid = isValid;
        this.model.addressPostcode = postcode;

        if (isValid) {
            this.model.address = address;

            // Reset the map zoom to a more neighbourhood-friendly level.
            this.model.zoom = MapModel.DEFAULT_LOCATIONZOOM;

            // Side effect. Call API.
            // A change to the address/postcode is a change to the outcode, so
            // call the API for fresh data.
            this.onMapChange();
        }
    };

    @action
    public setSearchPeriod = (value: any) => {
        this.model.searchPeriod = value;

        // Side effect. Call API.
        // A change to the search period, so call the API for fresh data.
        this.onMapChange();
    };

    // #endregion Actions

    // #region Computeds

    @computed
    public get isValidAddress(): boolean {
        return this.model.isAddressValid;
    }

    @computed
    public get canDisplayIncidents(): boolean {
        return !isEmptyOrWhitespace(this.navigationViewModel.selectedIncidentDataViewModelId);
    }

    @computed
    public get canDisplayListedBuildings(): boolean {
        return (this.navigationViewModel.selectedStaticDataViewModelId === "id_other")
            && (this.navigationViewModel.staticDataViewModels.find((vm) => vm.model.id === "id_other")?.selectedTypeViewModelId === "id_listedbuildings");
    }

    @computed
    public get canDisplayFloodRisk(): boolean {
        return (this.navigationViewModel.selectedStaticDataViewModelId === "id_risks")
            && (this.navigationViewModel.staticDataViewModels.find((vm) => vm.model.id === "id_risks")?.selectedTypeViewModelId === "id_floodrisk");
    }

    @computed
    public get canDisplayDemographics(): boolean {
        return (this.navigationViewModel.selectedStaticDataViewModelId === "id_demographics")
            && (!isEmptyOrWhitespace(this.navigationViewModel.staticDataViewModels.find((vm) => vm.model.id === "id_demographics")?.selectedTypeViewModelId));
    }

    @computed
    public get canDisplayWMSFRisks(): boolean {
        return (this.navigationViewModel.selectedStaticDataViewModelId === "id_risks")
            && (this.navigationViewModel.staticDataViewModels.find((vm) => vm.model.id === "id_risks")?.selectedTypeViewModelId !== "id_floodrisk");
    }

    @computed
    public get demographicViewModels(): NavigationAreaViewModel[] | undefined {
        const viewModel = this.navigationViewModel.staticDataViewModels.find((vm) => vm.model.id === "id_demographics");
        const typeViewModels = viewModel?.typeViewModels;

        return typeViewModels;
    }

    @computed
    public get selectedDemographicViewModel(): NavigationAreaViewModel | undefined {
        const demographicsViewModel = this.navigationViewModel.staticDataViewModels.find((vm) => vm.model.id === "id_demographics");
        const selectionId = demographicsViewModel?.selectedTypeViewModelId;
        const typeViewModels = demographicsViewModel?.typeViewModels;

        return typeViewModels?.find((vm) => vm.model.id === selectionId);
    }

    @computed
    public get selectedWMFSRisksViewModel(): NavigationAreaViewModel | undefined {
        const risksViewModel = this.navigationViewModel.staticDataViewModels.find((vm) => vm.model.id === "id_risks");
        const selectionId = risksViewModel?.selectedTypeViewModelId;
        const typeViewModels = risksViewModel?.typeViewModels;

        return typeViewModels?.find((vm) => vm.model.id === selectionId);
    }

    @computed
    public get mapLatitude() {
        return this.model.latitude;
    }

    @computed
    public get mapLongitude() {
        return this.model.longitude;
    }

    @computed
    public get mapZoom() {
        return this.model.zoom;
    }

    @computed
    public get searchAddress() {
        return this.model.searchAddress;
    }

    @computed
    public get mapRangeMetres(): number {
        return this.model.searchRangeMiles * 1600;
    }

    @computed
    public get searchPeriod(): string {
        return this.model.searchPeriod;
    }

    @computed
    public get attendedIncidents() {
        let incidents = this.dataViewModel.model.attendedIncidents.slice();

        // Try and take a reductionist approach, filtering out what is not selected
        // in the Navigation ViewModel.
        for (const typeViewModel of this.navigationViewModel.apiDataViewModel.incidentsViewModel.typeViewModels) {
            switch (typeViewModel.model.id) {
                case "id_falsealarms":
                    if (!typeViewModel.isSelected) {
                        incidents = incidents.filter((ai) => !ai.isFalseAlarmIncidentGroup);
                    }
                    break;

                case "id_fires":
                    if (!typeViewModel.isSelected) {
                        incidents = incidents.filter((ai) => !ai.isFireIncidentGroup);
                    } else {
                        for (const innerTypeViewModel of typeViewModel.typeViewModels) {
                            switch (innerTypeViewModel.model.id) {
                                case "id_housefires":
                                    if (!innerTypeViewModel.isSelected) {
                                        incidents = incidents.filter((ai) => !ai.isHouseFire);
                                    }
                                    break;

                                case "id_otherpropertyfires":
                                    if (!innerTypeViewModel.isSelected) {
                                        incidents = incidents.filter((ai) => !ai.isOtherPropertyFire);
                                    }
                                    break;

                                case "id_outdoorfires":
                                    if (!innerTypeViewModel.isSelected) {
                                        incidents = incidents.filter((ai) => !ai.isOutdoorFire);
                                    }
                                    break;

                                case "id_vehiclefires":
                                    if (!innerTypeViewModel.isSelected) {
                                        incidents = incidents.filter((ai) => !ai.isVehicleFire);
                                    }
                                    break;

                                case "id_otherfires":
                                    if (!innerTypeViewModel.isSelected) {
                                        incidents = incidents.filter((ai) => !ai.isOutlierFire);
                                    }
                                    break;
                            }
                        }
                    }
                    break;

                case "id_roadtrafficcollisions":
                    if (!typeViewModel.isSelected) {
                        incidents = incidents.filter((ai) => !ai.isRoadTrafficCollisionIncidentGroup);
                    }
                    break;

                case "id_specialservicecalls":
                    if (!typeViewModel.isSelected) {
                        incidents = incidents.filter((ai) => !ai.isSpecialServiceCallIncidentGroup);
                    }
                    break;
            }
        }

        return incidents;
    }

    @computed
    public get attendedIncidentCountByFireStation() {
        return this.dataViewModel.model.attendedIncidentCountByFireStation;
    }

    @computed
    public get safeAndWellActivityByFireStation() {
        return this.dataViewModel.model.safeAndWellActivityByFireStation;
    }

    // #endregion Computeds

    // #region Constants

    public get canMapZoom() {
        return MapModel.CAN_USEMAPZOOM;
    }

    public get canMapPan() {
        return MapModel.CAN_USEMAPPANNING;
    }

    public get mapMinZoom() {
        return MapModel.DEFAULT_MINZOOM;
    }

    public get mapMaxZoom() {
        return MapModel.DEFAULT_MAXZOOM;
    }

    public get mapBoundsSouthWest() {
        return MapModel.DEFAULT_MAPBOUNDSSOUTHWEST;
    }

    public get mapBoundsNorthEast() {
        return MapModel.DEFAULT_MAPBOUNDSSNORTHEAST;
    }

    public get mapRanges() {
        return MapModel.SEARCHRANGESMILES.map((r) => {
            return { value: r, label: "" };
        });
    }
    // #endregion Constants

    // #region Api Calls

    private onMapChange = debounce(
        action(() => {
            this.getApiData();
        }),
        500,
    );

    private getApiData = async (): Promise<void> => {
        try {
            const apiResult = await this.Post<any>(Server.Api.MapData.GetEvents, this.model.toDto());

            if (apiResult.wasSuccessful) {
                // Update the data viewmodel.
                this.dataViewModel.model.fromDto(apiResult.payload);

                // Update the navigation viewmodel from the data model.
                this.navigationViewModel.model.fromDataModel(this.dataViewModel.model);

                // Update the map center
                runInAction(() => {
                    this.model.latitude = this.dataViewModel.model.latitude;
                    this.model.longitude = this.dataViewModel.model.longitude;
                });
            }
        } catch (exception) {
            // Exception
        } finally {
            // Finally
        }
    };

    private getAllFireStations = async (): Promise<void> => {
        try {
            const apiResult = await this.Get<any>(Server.Api.MapData.GetAllFireStations);

            if (apiResult.wasSuccessful) {
                this.fireStationsViewModel.model.fromDto(apiResult.payload);
            }
        } catch (exception) {
            // Exception
        } finally {
            // Finally
        }
    };

    // #endregion Api Calls

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
