import { BlankModel } from "Core/Models/BlankModel";
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels";

export default class HomeViewModel extends ViewModelBase<BlankModel> {
    constructor() {
        super(new BlankModel());

        this.setDecorators(BlankModel);
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public navigateToMap(): void {
        ViewModelBase.history.push("/map");
    }
}
