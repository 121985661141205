import { computed, observable } from "mobx";

// Core
import { ModelBase } from "Core/Models/ModelBase";

export class MapModel extends ModelBase<MapModel, MapModelRequestDto> {
    // #region Constants

    // Related to API Postcode Search
    public static readonly DEFAULT_SEARCHADDRESS = "";
    public static readonly DEFAULT_ADDRESS = "";
    public static readonly DEFAULT_POSTCODEADDRESS = "";
    public static readonly DEFAULT_ISVALIDADDRESS = false;

    // Related to API Search Range
    public static readonly DEFAULT_SEARCHRANGEMILES = 1;
    public static readonly SEARCHRANGESMILES = [1, 2, 5, 10, 20];

    // Related to map.
    public static readonly CAN_USEMAPZOOM = true;
    public static readonly CAN_USEMAPPANNING = true;

    public static readonly DEFAULT_MAPLATITUDE = 52.4839708424868;
    public static readonly DEFAULT_MAPLONGITUDE = -1.8768074855863;

    public static readonly DEFAULT_MAPBOUNDSSOUTHWEST = {
        latitude: 52.347739844,
        longitude: -2.206877122,
    };
    public static readonly DEFAULT_MAPBOUNDSSNORTHEAST = {
        latitude: 52.662733325,
        longitude: -1.423929978,
    };

    public static readonly DEFAULT_REGIONZOOM = 14;
    public static readonly DEFAULT_LOCATIONZOOM = 14;
    public static readonly DEFAULT_MINZOOM = 10;
    public static readonly DEFAULT_MAXZOOM = 14;

    // #endregion Constants

    // #region Properties

    @observable
    public latitude = MapModel.DEFAULT_MAPLATITUDE;

    @observable
    public longitude = MapModel.DEFAULT_MAPLONGITUDE;

    @observable
    public zoom = MapModel.DEFAULT_REGIONZOOM;

    @observable
    public address = MapModel.DEFAULT_ADDRESS;

    @observable
    public addressPostcode = MapModel.DEFAULT_POSTCODEADDRESS;

    @observable
    public isAddressValid = MapModel.DEFAULT_ISVALIDADDRESS;

    @observable
    public searchAddress = MapModel.DEFAULT_SEARCHADDRESS;

    @observable
    public searchPeriods = observable<any>([]);

    @observable
    public searchPeriod: any = null;

    @observable
    public searchRangeMiles: number = MapModel.DEFAULT_SEARCHRANGEMILES;

    // #endregion Properties

    // #region Business Logic

    /**
     * Returns the Postcode District Code for a UK Postcode.
     */
    @computed
    public get addressPostcodeDistrictCode(): string {
        if (this.isAddressValid) {
            return this.addressPostcode.split(" ")[0];
        }

        return "";
    }

    // #endregion Business Logic

    constructor() {
        super();
    }

    public fromDto(model: MapModelRequestDto): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): MapModelRequestDto {
        return {
            area: this.addressPostcodeDistrictCode,
            radiusMiles: this.searchRangeMiles,
            intervalDays: this.searchPeriod ? this.searchPeriod.value : null,
        };
    }
}

interface MapModelRequestDto {
    area: string;
    radiusMiles: number;
    intervalDays: number;
}

interface MapModelResponseDto {}
