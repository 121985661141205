import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { customColours } from "Custom/StylesAppSpecific/AppStyling";

export const NavigationStyles = makeStyles((theme: Theme) => ({
    "@keyframes infoIconHoverEffect": {
        "0%": {
            transform: "scale(1)",
        },
        "50%": {
            transform: "scale(1.2)",
        },
        "100%": {
            transform: "scale(1)",
        },
    },
    root: {
        userSelect: "none",
    },
    navigationItemSelectable: {
        cursor: "pointer",
    },
    navigationItemFirst: {
        "&&": {
            borderLeft: `5px solid ${theme.palette.mapNavigationFirstLevel.main}`,
            backgroundColor: theme.palette.mapNavigationFirstLevel.light,
        },
    },
    navigationItemFirstLevelSelected: {
        "&&": {
            backgroundColor: theme.palette.mapNavigationFirstLevel.main,
            color: theme.palette.mapNavigationFirstLevel.contrastText,
        },
    },
    countContainerApiTitle: {
        alignItems: "center",
        display: "flex",
        fontSize: "1rem",
        minHeight: "3.125rem",
        paddingLeft: "1.875rem",
        paddingRight: "0.75rem",
        marginBottom: "-1.125rem",
    },
    iconContainer: {
        marginRight: "0.75rem",
    },
    infoIcon: {
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        marginLeft: "auto",
        padding: "0",

        "&:focus": {
            animation: "$infoIconHoverEffect 0.75s ease-in-out infinite",
            outline: "none",
        },
        "&:hover": {
            animation: "$infoIconHoverEffect 0.75s ease-in-out infinite",
        },
    },
    countContainerApi: {
        alignItems: "center",
        display: "flex",
        minHeight: "3.125rem",
        borderBottom: `2px solid ${customColours.wmfsGrey}`,
    },
    navigationItemSecond: {
        "&&": {
            backgroundColor: theme.palette.mapNavigationSecondLevel.light,
            borderLeft: `5px solid ${theme.palette.mapNavigationSecondLevel.main}`,
        },
    },
    navigationItemSecondSelected: {
        "&&": {
            backgroundColor: theme.palette.mapNavigationSecondLevel.main,
            color: theme.palette.mapNavigationSecondLevel.contrastText,
        },
    },
    navigationItemThird: {
        "&&": {
            backgroundColor: theme.palette.mapNavigationThirdLevel.light,
            color: customColours.wmfsDarkGrey,
            borderLeft: `5px solid ${theme.palette.mapNavigationThirdLevel.main}`,
        },
    },
    navigationItemThirdSelected: {
        "&&": {
            backgroundColor: theme.palette.mapNavigationThirdLevel.main,
            color: customColours.wmfsDarkGrey,
        },
    },
    averageReponseTimeContainer: {
        backgroundColor: "white",
        borderBottom: `2px solid ${customColours.wmfsGrey}`,
        borderLeft: "5px solid transparent",
        minHeight: "5.125rem",
        display: "flex",
        alignItems: "center",
        paddingLeft: "1.875rem",
    },
    averageReponseIcon: {},
    averageReponseTime: {
        fontSize: "0.85rem",
    },
    averageReponseTimeDisplayName: {
        fontSize: "1rem",
    },
    countContainer: {
        borderBottom: `2px solid ${customColours.wmfsGrey}`,
        borderLeft: "5px solid transparent",
        alignItems: "center",
        display: "flex",
        minHeight: "3.125rem",
        paddingLeft: "1.875rem",
        paddingRight: "0.75rem",
    },
    count: {
        flex: "1",
        fontSize: "1.5rem",
        maxWidth: "7.5rem",
        textAlign: "right",
        paddingRight: "0.75rem",
    },
    countDisplayName: {
        alignItems: "center",
        display: "flex",
        flex: "2.25",
        fontSize: "0.85rem",
    },
    staticDataContainer: {
        borderBottom: `2px solid ${customColours.wmfsGrey}`,
        borderLeft: "5px solid transparent",
        alignItems: "center",
        display: "flex",
        minHeight: "3.125rem",
        paddingLeft: "1.875rem",
        paddingRight: "0.75rem",
    },
    staticDisplayName: {
        alignItems: "center",
        display: "flex",
        flex: "1",
        fontSize: "0.85rem",
    },
    informationDialog: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.mapInformationDialog.main,
            border: `2px solid ${theme.palette.mapInformationDialog.dark}`,
            color: theme.palette.mapInformationDialog.contrastText,
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "30rem",
        },
    },
    informationDialogTitle: {
        alignItems: "center",
        display: "flex",
        fontSize: "0.75rem",
        lineHeight: "1.4",
        padding: "0.75rem 1rem",
        "& > div": {
            marginRight: "0.75rem",
        },
        "& button": {
            marginLeft: "auto",
        },
    },
    informationDialogContent: {
        padding: "0rem 1rem 0.75rem 1rem",
        "& .MuiTypography-root": {
            fontSize: "0.75rem",
            lineHeight: "1.4",
            whiteSpace: "pre-line",
        },
    },
    footerRow: {
        display: "flex",
        flex: "1",
        marginTop: "0.25rem",
    },
    shoothillButton: {
        alignSelf: "end",
        margin: "0 0 0 auto",
        padding: "0",
    },
    shoothillLogo: {
        background: "url(resources/ShoothillLogo.png) no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "contain",
        height: "72px",
        width: "151px",
    },
}));
