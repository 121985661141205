import { action, computed, observable } from "mobx";

import {
    NavigationModel,
    AverageResponseTime,
    MinMax,
    NavigationApiData,
    NavigationCount,
    NavigationIncidents,
    NavigationPrevention,
    NavigationProtection,
    NavigationMinMax as NavigationArea,
    NavigationStaticData,
} from "Custom/Models/Map/Navigation/NavigationModel";
import { formatVerboseSeconds } from "Custom/Utils/Utils";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

export default class NavigationViewModel {
    public model: NavigationModel = new NavigationModel();
    public apiDataViewModel: NavigationApiDataViewModel;
    public staticDataViewModels: NavigationStaticDataViewModel[] = [];

    public constructor() {
        this.apiDataViewModel = new NavigationApiDataViewModel(this.model.apiData);

        for (const m of this.model.staticData) {
            this.staticDataViewModels.push(new NavigationStaticDataViewModel(m));
        }

        // Defaults.
        this.selectIncidentDataViewModelId("id_incidents");
    }

    // #region Api Data

    @computed
    public get canDisplayApiData() {
        return this.model.haveData;
    }

    @observable
    public selectedIncidentDataViewModelId: string = "";

    @action
    public selectIncidentDataViewModelId = (id: string) => {
        // Set current selection.
        this.selectedIncidentDataViewModelId = this.selectedIncidentDataViewModelId === id ? "" : id;

        // Set selection state on viewmodel.
        if (this.apiDataViewModel.incidentsViewModel.canSelect) {
            this.apiDataViewModel.incidentsViewModel.isSelected = !isEmptyOrWhitespace(this.selectedIncidentDataViewModelId);
        }

        if (this.apiDataViewModel.incidentsViewModel.canExpand) {
            this.apiDataViewModel.incidentsViewModel.isExpanded = !isEmptyOrWhitespace(this.selectedIncidentDataViewModelId);
        }
    };

    // #endregion Api Data

    // #region Static Data

    @observable
    public selectedStaticDataViewModelId: string = "";

    @action
    public selectStaticDataViewModelId = (id: string) => {
        // Set current selection.
        this.selectedStaticDataViewModelId = this.selectedStaticDataViewModelId === id ? "" : id;

        // Set selection state on each viewmodel.
        for (const vm of this.staticDataViewModels) {
            if (vm.canSelect) {
                vm.isSelected = vm.model.id === this.selectedStaticDataViewModelId;
            }

            if (vm.canExpand) {
                vm.isExpanded = vm.model.id === this.selectedStaticDataViewModelId;
            }
        }
    };

    // #endregion Static Data
}

// #region API Data

/**
 * Viewmodel to manage presentation and behaviour of WMFS API data.
 */
export class NavigationApiDataViewModel {
    public model: NavigationApiData;

    public averageResponseTimeViewModel: NavigationAverageResponseTimeViewModel;
    public preventionViewModel: NavigationPreventionViewModel;
    public protectionViewModel: NavigationProtectionViewModel;
    public incidentsViewModel: NavigationIncidentsViewModel;

    public constructor(model: NavigationApiData) {
        this.model = model;
        this.averageResponseTimeViewModel = new NavigationAverageResponseTimeViewModel(this.model.averageResponseTime);
        this.preventionViewModel = new NavigationPreventionViewModel(this.model.prevention);
        this.protectionViewModel = new NavigationProtectionViewModel(this.model.protection);
        this.incidentsViewModel = new NavigationIncidentsViewModel(this.model.incidents);
    }
}

/**
 * Viewmodel to manage presentation and behaviour of prevention items.
 */
export class NavigationPreventionViewModel {
    public model: NavigationPrevention;

    public typeViewModels: NavigationCountViewModel[] = [];

    public constructor(model: NavigationPrevention) {
        this.model = model;

        for (const m of model.types) {
            this.typeViewModels.push(new NavigationCountViewModel(m));
        }
    }

    // #region Expanded

    public get canExpand() {
        return this.model.canExpand;
    }

    @observable
    public isExpanded: boolean = false;

    @action
    public toggleIsExpanded = () => {
        if (this.canExpand) {
            this.isExpanded = !this.isExpanded;
        }
    };

    // #endregion Expanded

    // #region Selected

    public get canSelect() {
        return false;
    }

    @observable
    public isSelected: boolean = false;

    // #endregion Selected
}

/**
 * Viewmodel to manage presentation and behaviour of protection items.
 */
export class NavigationProtectionViewModel {
    public model: NavigationProtection;

    public typeViewModels: NavigationCountViewModel[] = [];

    public constructor(model: NavigationProtection) {
        this.model = model;

        for (const m of model.types) {
            this.typeViewModels.push(new NavigationCountViewModel(m));
        }
    }

    // #region Expanded

    public get canExpand() {
        return this.model.canExpand;
    }

    @observable
    public isExpanded: boolean = false;

    @action
    public toggleIsExpanded = () => {
        if (this.canExpand) {
            this.isExpanded = !this.isExpanded;
        }
    };

    // #endregion Expanded

    // #region Selected

    public get canSelect() {
        return false;
    }

    @observable
    public isSelected: boolean = false;

    // #endregion Selected
}

/**
 * Viewmodel to manage the presentation and behaviour of incidents.
 */
export class NavigationIncidentsViewModel {
    public model: NavigationIncidents;

    public typeViewModels: NavigationCountViewModel[] = [];

    public constructor(model: NavigationIncidents) {
        this.model = model;

        for (let typeIndex = 0; typeIndex < model.types.length; typeIndex++) {
            const viewModel = new NavigationCountViewModel(model.types[typeIndex]);

            this.typeViewModels.push(viewModel);

            // Defaults.
            if (viewModel.canSelect) {
                viewModel.isSelected = true;
            }

            if (viewModel.canExpand) {
                viewModel.isExpanded = true;
            }
        }
    }

    // #region Expanded

    public get canExpand() {
        return this.model.canExpand;
    }

    @observable
    public isExpanded: boolean = false;

    @action
    public toggleIsExpanded = () => {
        if (this.canExpand) {
            this.isExpanded = !this.isExpanded;
        }
    };

    // #endregion Expanded

    // #region Selected

    public get canSelect() {
        return this.model.canSelect;
    }

    @observable
    public isSelected: boolean = false;

    // #endregion Selected
}

/**
 * Viewmodel to manage the presentation and behaviour of child prevention items,
 * protection items or incidents.
 */
export class NavigationCountViewModel {
    public model: NavigationCount;

    public typeViewModels: NavigationCountViewModel[] = [];

    public constructor(model: NavigationCount) {
        this.model = model;

        for (let typeIndex = 0; typeIndex < model.types.length; typeIndex++) {
            const viewModel = new NavigationCountViewModel(model.types[typeIndex]);

            this.typeViewModels.push(viewModel);

            // Defaults.
            if (viewModel.canSelect) {
                viewModel.isSelected = true;
            }

            if (viewModel.canExpand) {
                viewModel.isExpanded = true;
            }
        }
    }

    // #region Expanded

    public get canExpand() {
        return this.model.canExpand;
    }

    @observable
    public isExpanded: boolean = false;

    @action
    public toggleIsExpanded = () => {
        if (this.canExpand) {
            this.isExpanded = !this.isExpanded;
        }
    };

    // #endregion Expanded

    // #region Selected

    public get canSelect() {
        return this.model.canSelect;
    }

    @observable
    public isSelected: boolean = false;

    @action
    public toggleIsSelected = () => {
        if (this.canSelect) {
            this.isSelected = !this.isSelected;
        }

        if (this.canExpand) {
            this.isExpanded = !this.isExpanded;
        }
    };

    // #endregion Selected
}

/**
 * Viewmodel to manage the presentation and behaviour of the average
 * response time.
 */
export class NavigationAverageResponseTimeViewModel {
    public model: AverageResponseTime;

    public constructor(model: AverageResponseTime) {
        this.model = model;
    }

    // #region Properties

    @computed
    public get averageResponseTime() {
        return formatVerboseSeconds(this.model.count);
    }

    // #endregion Properties
}

// #endregion API Data

// #region Static Data

/**
 * Viewmodel to manage the presentation and behaviour of static data.
 */
export class NavigationStaticDataViewModel {
    public model: NavigationStaticData;

    public typeViewModels: NavigationAreaViewModel[] = [];

    public constructor(model: NavigationStaticData) {
        this.model = model;

        for (let typeIndex = 0; typeIndex < model.types.length; typeIndex++) {
            const viewModel = new NavigationAreaViewModel(model.types[typeIndex]);

            this.typeViewModels.push(viewModel);

            // Defaults.
            if (typeIndex === 0) {
                if (viewModel.canSelect) {
                    this.selectTypeViewModelId(viewModel.model.id);
                    viewModel.isSelected = true;
                }
            }
        }
    }

    // #region Expanded

    public get canExpand() {
        return this.model.canExpand;
    }

    @observable
    public isExpanded: boolean = false;

    @action
    public toggleIsExpanded = () => {
        if (this.canExpand) {
            this.isExpanded = !this.isExpanded;
        }
    };

    // #endregion Expanded

    // #region Selected

    public get canSelect() {
        return this.model.canSelect;
    }

    @observable
    public isSelected: boolean = false;

    // #endregion Selected

    // #region Type Selected

    @observable
    public selectedTypeViewModelId: string = "";

    @action
    public selectTypeViewModelId = (id: string) => {
        // Set current selection.
        this.selectedTypeViewModelId = id;

        // Set selection state on each viewmodel.
        for (const vm of this.typeViewModels) {
            if (vm.canSelect) {
                vm.isSelected = vm.model.id === this.selectedTypeViewModelId;
            }
        }
    };

    // #endregion Type Selected
}

/**
 * Viewmodel to manage the presentaion and behaviour of area layer data.
 */
export class NavigationAreaViewModel {
    public model: NavigationArea;

    public minViewModel: MinMaxViewModel;
    public maxViewModel: MinMaxViewModel;
    public typeViewModels: NavigationAreaViewModel[] = [];

    public constructor(model: NavigationArea) {
        this.model = model;

        this.minViewModel = new MinMaxViewModel(this.model.min);
        this.maxViewModel = new MinMaxViewModel(this.model.max);

        for (const m of model.types) {
            this.typeViewModels.push(new NavigationAreaViewModel(m));
        }
    }

    // #region Selected

    public get canSelect() {
        return this.model.canSelect;
    }

    @observable
    public isSelected: boolean = false;

    // #endregion Selected
}

/**
 * Viewmodel to manage the presentation of min/max data.
 */
export class MinMaxViewModel {
    public model: MinMax;

    public constructor(model: MinMax) {
        this.model = model;
    }
}

// #region Static Data
