// Libraries
import { Mark, Slider } from "@material-ui/core";
import React, { useRef } from "react";

// Custom
import { ZoomControlStyles } from "./ZoomControlStyles";

interface IProps {
    ariaLabel?: string;
    disabled?: boolean;
    mapRanges: { value: number; label: string }[];
    mapRange: number;
    setMapRange: (value: number) => any;
}

const RangeThumb = (internalProps: any) => {
    return (
        <span
            tabIndex={0}
            {...internalProps}>
        </span>
    );
};

export const ZoomControl: React.FC<IProps> = (props: IProps) => {
    const zoomControlStyles = ZoomControlStyles();

    // #region Range Mapping Adaptor

    // The Material-UI Slider control requires values to be in same step
    // increments and increasing value.
    //
    // However, the provided props may not meet that requirement, so we
    // cannot use the props values directly with the slider. To workaround
    // this we use the range indexes instead as they increment in steps
    // of one and are always increasing value.

    const minIndex = 0;
    const maxIndex = props.mapRanges.length - 1;

    const getIndexFromValue = (value: number) => {
        return props.mapRanges.findIndex((r) => r.value === value);
    };

    const getValueFromIndex = (index: number) => {
        return props.mapRanges[index].value;
    };

    const getMarks = (): Mark[] => {
        return props.mapRanges.map((r, index) => {
            return {
                value: index,
                label: r.label,
            };
        });
    };

    // #endregion Range Mapping Adaptor

    const onMapRangeChange = (event: any, index: any): void => {
        props.setMapRange(getValueFromIndex(index));
    };

    return (
        <div
            className={zoomControlStyles.root}>
            <div className={zoomControlStyles.lhslabel}>Range</div>
            <Slider
                aria-label={props.ariaLabel}
                aria-valuetext={`${props.mapRange} miles`}
                defaultValue={getIndexFromValue(props.mapRange)}
                disabled={props.disabled}
                marks={getMarks()}
                max={maxIndex}
                min={minIndex}
                onChange={onMapRangeChange}
                step={null}
                ThumbComponent={RangeThumb}
                value={getIndexFromValue(props.mapRange)}
                valueLabelDisplay="on"
                valueLabelFormat={`${props.mapRange}`}
            />
            <div className={zoomControlStyles.rhslabel}>miles</div>
        </div>
    );
};
