import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        mapNavigationFirstLevel: PaletteColor;
        mapNavigationSecondLevel: PaletteColor;
        mapNavigationThirdLevel: PaletteColor;
        mapInformationDialog: PaletteColor;
        mapLegend: PaletteColor;
        mapPopup: PaletteColor;
        primaryButton: PaletteColor;
    }

    interface PaletteOptions {
        mapNavigationFirstLevel: PaletteColorOptions;
        mapNavigationSecondLevel: PaletteColorOptions;
        mapNavigationThirdLevel: PaletteColorOptions;
        mapInformationDialog: PaletteColorOptions;
        mapLegend: PaletteColorOptions;
        mapPopup: PaletteColorOptions;
        primaryButton: PaletteColorOptions;
    }
}

export const customColours = {
    wmfsDarkRed: "#8b0f04",
    wmfsBlue: "#051844",
    wmfsBlue2: "#B8CCFA",
    wmfsBlue3: "#E7EEFD",
    wmfsDarkGrey: "#181816",
    wmfsGrey: "#d9d9d9",
    wmfsLightGrey: "#f2f2f2",
    wmfsInputBorder: "#c7c7c7",
    wmfsInputBorderFocus: "#949494",
    wmfsInputBorderHover: "#2a2a2a",
};

export const zIndexHierarchies = {
    layoutContainer: 15000,
    layoutMobileDrawer: 10000,
    mapHeader: 5000,
};

export const customBreakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1024,
        xl: 1920,
    },
});

export const defaultTheme = createMuiTheme({
    breakpoints: customBreakpoints,
    typography: {
        fontFamily: `"ITC Avant Garde", "Helvetica", "Arial", sans-serif`,
        h1: {
            fontFamily: "ITC Avant Garde Bold",
            fontSize: "3.75rem",
            [customBreakpoints.down(customBreakpoints.values.md)]: {
                fontSize: "1.1875rem",
            },
        },
        h2: {
            fontFamily: "ITC Avant Garde Bold",
            fontSize: "3rem",
            [customBreakpoints.down(customBreakpoints.values.md)]: {
                fontSize: "1.1875rem",
            },
        },
        h3: {
            fontFamily: "ITC Avant Garde Bold",
            fontSize: "1.5rem",
            [customBreakpoints.down(customBreakpoints.values.md)]: {
                fontSize: "0.9375rem",
            },
        },
        h4: {
            fontFamily: "ITC Avant Garde Bold",
            fontSize: "1.125rem",
            [customBreakpoints.down(customBreakpoints.values.md)]: {
                fontSize: "0.75rem",
            },
        },
        h5: {
            fontFamily: "ITC Avant Garde Bold",
            fontSize: "0.875rem",
            [customBreakpoints.down(customBreakpoints.values.md)]: {
                fontSize: "0.625rem",
            },
        },
        h6: {
            fontFamily: "ITC Avant Garde",
            fontSize: "0.75rem",
            [customBreakpoints.down(customBreakpoints.values.md)]: {},
        },
        subtitle1: {
            fontSize: "1rem",
            letterSpacing: "-0.4px",
            [customBreakpoints.down(customBreakpoints.values.md)]: {},
        },
        subtitle2: {
            fontSize: "0.875rem",
            [customBreakpoints.down(customBreakpoints.values.md)]: {},
        },
        body1: {
            fontFamily: "Aptos",
            fontSize: "0.875rem",
            lineHeight: "1.275",
            [customBreakpoints.down(customBreakpoints.values.md)]: {
                fontSize: "0.75rem",
                lineHeight: "1.3",
            },
        },
        body2: {
            fontSize: "1.0625rem",
            [customBreakpoints.down(customBreakpoints.values.md)]: {
                fontSize: "0.625rem",
            },
        },
        button: {
            fontSize: "0.875rem",
            [customBreakpoints.down("lg")]: {},
        },
        caption: {
            fontFamily: "Aptos",
            fontSize: "0.85rem",
            lineHeight: "1.275",
            [customBreakpoints.down(customBreakpoints.values.md)]: {},
        },
        overline: {
            fontSize: "0.75rem",
            [customBreakpoints.down(customBreakpoints.values.md)]: {},
        },
    },
    palette: {
        type: "light",
        action: {},
        primary: {
            main: "#051844",
            contrastText: "#000",
        },
        secondary: {
            main: "#F2893E",
            contrastText: "#000",
        },
        mapNavigationFirstLevel: {
            light: customColours.wmfsLightGrey,
            main: customColours.wmfsBlue,
            contrastText: "white",
        },
        mapNavigationSecondLevel: {
            light: `${customColours.wmfsLightGrey}40`,
            main: `${customColours.wmfsBlue2}`,
            contrastText: "#181816",
        },
        mapNavigationThirdLevel: {
            light: "white",
            main: `${customColours.wmfsBlue3}`,
            contrastText: "#181816",
        },
        mapInformationDialog: {
            dark: customColours.wmfsDarkGrey,
            main: "white",
            contrastText: customColours.wmfsDarkGrey,
        },
        mapLegend: {
            dark: customColours.wmfsDarkGrey,
            main: "white",
            contrastText: customColours.wmfsDarkGrey,
        },
        mapPopup: {
            dark: customColours.wmfsDarkGrey,
            main: "white",
            contrastText: customColours.wmfsDarkGrey,
        },
        primaryButton: {
            main: "#d70000",
            contrastText: "white",
        },
    },
    overrides: {
        MuiLink: {
            root: {
                textDecoration: "none",
                color: customColours.wmfsDarkGrey,
            },
        },
        MuiTabs: {
            indicator: {
                display: "none",
            },
        },
        MuiTab: {
            root: {
                backgroundColor: "#F1F1F1",
                border: "1px solid rgba(0, 0, 0, 0.08)",
                marginLeft: "1px",
                textTransform: "capitalize",
                fontWeight: "bold",
                borderRadius: "2px 2px 0 0",
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, .1)",
                    color: customColours.wmfsDarkGrey,
                },
                "@media (min-width: 960px)": {
                    width: "57px",
                    minWidth: "57px",
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: "0",
                marginBottom: "1rem",
                marginTop: "1rem",
                padding: "0.5625rem 1rem",
                textTransform: "capitalize",
                fontSize: "0.875rem",
                fontWeight: "normal",
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: "36px",
            },
        },
        MuiOutlinedInput: {
            input: {
                backgroundColor: "white",
                borderRadius: "0.3125rem",
                fontSize: "0.75rem",
                height: "1.5rem",
                padding: "0.5625rem 1rem",
                "&::placeholder": {
                    color: "#727272",
                    opacity: 1,
                },
                "&:disabled": {
                    color: "#727272",
                    opacity: 1,
                },
                [customBreakpoints.up(customBreakpoints.values.md)]: {
                    //fontSize: "0.875rem",
                },
            },
            inputMultiline: {
                backgroundColor: "white",
                borderRadius: "0.3125rem",
                fontSize: "0.75rem",
                lineHeight: "1.4",
                padding: "0.5625rem 1rem",
                [customBreakpoints.up(customBreakpoints.values.md)]: {
                    //fontSize: "0.875rem",
                },
            },
            multiline: {
                padding: "0",
            },
            root: {
                marginTop: "1.75rem",
                [customBreakpoints.up(customBreakpoints.values.md)]: {
                    marginBottom: "1.5rem",
                    marginTop: "2rem",
                },
                "& fieldset": {
                    borderColor: `${customColours.wmfsInputBorder} !important`,
                },
                "&:hover fieldset": {
                    borderColor: `${customColours.wmfsInputBorderFocus} !important`,
                },
                "&.Mui-focused fieldset": {
                    borderColor: `${customColours.wmfsInputBorderHover} !important`,
                },
            },
            notchedOutline: {
                borderWidth: "2px",
                "& legend": {
                    width: "0",
                },
            },
        },
        MuiInputLabel: {
            shrink: {
                transform: "translate(0, 0) scale(1)",
            },
        },
        MuiSlider: {
            root: {
                color: `${customColours.wmfsDarkGrey}`,
                padding: "1rem 0 !important",
            },
            thumb: {
                backgroundColor: "#fff",
                border: `2px solid ${customColours.wmfsInputBorder} !important`,
                boxShadow: "none !important",
                color: "black",
                fontSize: "12px",
                height: "2rem",
                width: "2rem",
                marginLeft: "-1rem !important",
                marginTop: "-1rem !important",
                paddingTop: "2px",
                "&:focus": {
                    borderColor: `${customColours.wmfsInputBorderHover} !important`,
                },
                "&:hover": {
                    borderColor: `${customColours.wmfsInputBorderFocus} !important`,
                },
                "&.Mui-disabled": {
                    height: "2rem",
                    width: "2rem",
                    "& span": {
                        color: "#727272",
                    },
                },
            },
            mark: {
                backgroundColor: customColours.wmfsDarkGrey,
                borderRadius: "50%",
                marginLeft: "-1px",
                marginTop: "-1px",
                height: "4px",
                width: "4px",
            },
            track: {
                height: "3px",
            },
            valueLabel: {
                fontFamily: "Aptos",
                fontSize: "12px",
                position: "revert",
                transform: "none !important",

                "& > span": {
                    backgroundColor: "transparent",
                    transform: "none !important",

                    "& > span": {
                        color: `${customColours.wmfsDarkGrey}`,
                        transform: "none !important",
                    },
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                whiteSpace: "pre-line",
            },
        },
        MuiTypography: {
            gutterBottom: {
                marginBottom: "0.8rem",
                [customBreakpoints.up(customBreakpoints.values.md)]: {
                    marginBottom: "1.1rem",
                },
            },
        },
    },
});
