//EN: To support IE 11
// Uncomment the two lines below
// Revert MobX to 4.12.0
// Delete node_modules directory and reinstall
//import "react-app-polyfill/ie11";
//import "react-app-polyfill/stable";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import * as MobX from "mobx";
import "index.css";
import App from "App";
import * as serviceWorker from "serviceWorker";
import * as Utils from "./Core/Utils/Utils";

import * as History from "history";
import { AppErrorBoundary } from "./AppErrorBoundary";
import { StoresContext } from "./Custom/Stores";
import { InitialState } from "./Custom/Models";
import { Loader } from "./Core/Components/Loader";
import { Centered } from "./Core/Components/Centered";
import Axios from "axios";
import { InitViewModel } from "./Custom/ViewModels/InitViewModel";
import { useObserver } from "mobx-react-lite";

MobX.configure({ enforceActions: "observed" });

const baseUrl: string | undefined = Utils.getBaseUrl();
const history = History.createBrowserHistory({
	basename: baseUrl,
});

const isDev: boolean = process.env.NODE_ENV === "development";
(window as any).isDev = isDev;
// const devTools: JSX.Element | null = isDev ? <MobXReactDevTools /> : null;

function renderApp() {
	ReactDOM.render(<Init />, document.getElementById("root"));
}
let viewModel = new InitViewModel(history);
let _ = viewModel.getInitialState();

const Init: React.FC = () => {
	const store = useContext(StoresContext);

	const getRender = () => {
		if (viewModel.IsLoading) {
			// EN: Delayed in code behind so we can see the loading text
			return <Loader delayBeforeShow={2000} />;
		} else if (viewModel.IsErrored) {
			return (
				<Centered>
					<span>Error!!!</span>
				</Centered>
			);
		}

		if (viewModel.DataReady) {
			return (
				<AppErrorBoundary>
					<App />
				</AppErrorBoundary>
			);
		}
		return <></>;
	};

	return useObserver(() => {
		return <>{getRender()}</>;
	});
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// if ((module as any).hot) {
//     (module as any).hot.accept("./AppRoutes", () => renderApp());
// }
